import React, {useContext, useEffect} from 'react'
import { Link } from 'react-router-dom'
import Header from '../Header_footer/Header'
import Axios from 'axios'
import usePosition from '../common_section/usePosition'
import './SearchRider.css';
import FloatingButton from '../common/FloatingButton'
import Checkbox from '../common/Checkbox'
import Localisation from '../common_section/Localisation'
import SlidingButton from '../common/SlidingButton'
import BudgetMensuel from '../common_section/BudgetMensuel'
import RangeButton from '../common/RangeButton'
import Competition from '../common_section/Competition'
import Pension from '../common_section/Pension'
import { UserContext } from '../context/UserContext'
import { SearchRiderContext } from '../context/SearchRiderContext'
import Menu from '../Menu'


const SearchRider = () => {

    //adsense array code slots
    const slotValues = ["6716510069", "1759696850", "3539682385"]

    // Chargement des informations de recherche dans le "SearchRiderContext" :
    const { searchRiders, setSearchRiders } = useContext(SearchRiderContext)

    // Chargement des informations de localisation du user dans le "UserContext" :
    const { userPosition, setUserPosition } = useContext(UserContext)

    // Localisation des coordonnées GPS via "usePosition" :
    const {latitude, longitude} = usePosition();

    // Get the location from reverse geocoding
    const getLocation = () => {

        Axios
            .get(`https://nominatim.openstreetmap.org/reverse?format=jsonv2&lat=${latitude}&lon=${longitude}`)
            .then(res => setUserPosition({
                ...userPosition,
                user_postal_code : res.data.address.postcode}))
            .catch(err => console.log(err))
    }


    // Get full and set gps coordinates from postal code within UserContext
    const getCoordinatesfromPostalCode = (postalcode) => {
        Axios
            .get(`https://nominatim.openstreetmap.org/search?country=France&postalcode=${postalcode}&format=json`)
            .then((res) => {
                setUserPosition({
                    ...userPosition,
                    user_longitude: res.data[0].lon,
                    user_latitude: res.data[0].lat,
                    user_localisation: res.data[0].display_name,
                });
            })
            .catch((err) => console.log(err));
    };

    const loadAds = () => {
        try {
          if (typeof window !== "undefined") {
            (window.adsbygoogle = window.adsbygoogle || []).push({});
          }
        } catch (error) {
          console.log("adsense error", error.message);
        }
      };


    useEffect(() => {
        getLocation()
        loadAds()
    }, [latitude]);


    return (
        <>
            <Header className='header' title='Chercher un cavalier' path='home'/>
            <div className="searchRider_page">
                <div className="localisation">
                    <Localisation
                        locTitle='Où ?'
                        value={userPosition.user_postal_code}
                        getLocation={getLocation}
                        onChange={(e) => setUserPosition({...userPosition,
                            user_postal_code : e.target.value
                        })}
                        definePerimeter={(e) => setUserPosition({...userPosition,
                            user_perimeter : e.target.value})}
                        perimeter={userPosition.user_perimeter}
                    />
                    <button id="upload-button" onClick={ () => {
                        getCoordinatesfromPostalCode(userPosition.user_postal_code)}}>
                        Valider ma position
                    </button>
                    <div>
                        <p>{userPosition.user_localisation}</p>
                    </div>
                    <ins
                        className="adsbygoogle"
                        style={{ display: "block", width: "250px", height: "50px"}}
                        data-ad-client="ca-pub-7709898933114568"
                        data-ad-slot={slotValues[0]}
                        data-ad-format="auto"
                        data-full-width-responsive="true">
                    </ins>

                    <h5> Années de pratique minimum : {searchRiders.rider_years_of_practice} </h5>
                    <div className='divRangeSpan'>
                        <span>1</span>
                        <RangeButton
                            min="1"
                            max="50"
                            onChange={(e) => setSearchRiders({
                                ...searchRiders,
                                rider_years_of_practice : e.target.value})
                            }
                        />
                        <span>50 ans</span>
                    </div>

                    <hr />
                    <h5> Niveau de Galop minimum : {searchRiders.rider_gallop_level} </h5>
                    <div className='divRangeSpan'>
                        <span>0</span>
                        <RangeButton
                            min="0"
                            max="7"
                            list='niveau_galop'
                            onChange={(e) => setSearchRiders({
                                ...searchRiders,
                                rider_gallop_level : e.target.value})
                            }
                        />
                        <span>7</span>
                    </div>


                    <hr />
                    <h5> Age du cavalier <span>(+/- 2ans)</span>: {searchRiders.rider_age} </h5>
                    <div className='divRangeSpan'>
                        <span>5</span>
                        <RangeButton
                            min="5"
                            max="99"
                            onChange={(e) => setSearchRiders({
                                ...searchRiders,
                                rider_age : e.target.value})
                            }
                        />
                        <span>99 ans</span>
                    </div>

                    <SlidingButton
                        SlidingButtonText='Personne véhiculée'
                        SlidingButtonID='vehiculed'
                        onClick={() => setSearchRiders({
                            ...searchRiders,
                            rider_vehiculed : !searchRiders.rider_vehiculed
                        })}
                    />
                    <SlidingButton
                        SlidingButtonText='Le cavalier a déjà eu un cheval sous sa responsabilité'
                        SlidingButtonID='experience'
                        onClick={() => setSearchRiders({
                            ...searchRiders,
                            rider_managed_horse : !searchRiders.rider_managed_horse
                        })}
                    />
                </div>
                <hr />
                <div className='structures'>
                    <h4>Disciplines </h4>
                    <div className='disciplineList'>
                        <Checkbox
                            CheckboxText='Obstacle'
                            CheckboxValue='Obstacle'
                            onClick={() => setSearchRiders({
                                ...searchRiders,
                                rider_disciplines :
                                    {...searchRiders.rider_disciplines, Obstacle : !searchRiders.rider_disciplines.Obstacle}
                            })}/>
                        <Checkbox
                            CheckboxText='Dressage'
                            CheckboxValue='Dressage'
                            onClick={() => setSearchRiders({
                                ...searchRiders,
                                rider_disciplines :
                                    {...searchRiders.rider_disciplines, Dressage : !searchRiders.rider_disciplines.Dressage}
                            })}/>
                        <Checkbox
                            CheckboxText='CCE'
                            CheckboxValue='CCE'
                            onClick={() => setSearchRiders({
                                ...searchRiders,
                                rider_disciplines :
                                    {...searchRiders.rider_disciplines, CCE : !searchRiders.rider_disciplines.CCE}
                            })}/>
                        <Checkbox
                            CheckboxText='TREC - Equifun'
                            CheckboxValue='TREC - Equifun'
                            onClick={() => setSearchRiders({
                                ...searchRiders,
                                rider_disciplines :
                                    {...searchRiders.rider_disciplines, TREC_Equifun : !searchRiders.rider_disciplines.TREC_Equifun}
                            })}/>
                        <Checkbox
                            CheckboxText='Balade - Randonnée'
                            CheckboxValue='Balade - Randonnée'
                            onClick={() => setSearchRiders({
                                ...searchRiders,
                                rider_disciplines :
                                    {...searchRiders.rider_disciplines, Balade_Randonnee : !searchRiders.rider_disciplines.Balade_Randonnee}
                            })}/>
                        <Checkbox
                            CheckboxText='Ethologie - Equifeel'
                            CheckboxValue='Ethologie - Equifeel'
                            onClick={() => setSearchRiders({
                                ...searchRiders,
                                rider_disciplines :
                                    {...searchRiders.rider_disciplines, Ethologie_Equifeel : !searchRiders.rider_disciplines.Ethologie_Equifeel}
                            })}/>
                        <Checkbox
                            CheckboxText='Hunter'
                            CheckboxValue='Hunter'
                            onClick={() => setSearchRiders({
                                ...searchRiders,
                                rider_disciplines :
                                    {...searchRiders.rider_disciplines, Hunter : !searchRiders.rider_disciplines.Hunter}
                            })}/>
                        <Checkbox
                            CheckboxText='Horse-Ball'
                            CheckboxValue='Horse-Ball'
                            onClick={() => setSearchRiders({
                                ...searchRiders,
                                rider_disciplines :
                                    {...searchRiders.rider_disciplines, Horse_Ball : !searchRiders.rider_disciplines.Horse_Ball}
                            })}/>
                        <Checkbox
                            CheckboxText='Pony-Games'
                            CheckboxValue='Pony-Games'
                            onClick={() => setSearchRiders({
                                ...searchRiders,
                                rider_disciplines :
                                    {...searchRiders.rider_disciplines, Pony_Games : !searchRiders.rider_disciplines.Pony_Games}
                            })}/>
                        <Checkbox
                            CheckboxText='Reining-Western'
                            CheckboxValue='Reining-Western'
                            onClick={() => setSearchRiders({
                                ...searchRiders,
                                rider_disciplines :
                                    {...searchRiders.rider_disciplines, Reining_Western : !searchRiders.rider_disciplines.Reining_Western}
                            })}/>
                        <Checkbox
                            CheckboxText='Endurance'
                            CheckboxValue='Endurance'
                            onClick={() => setSearchRiders({
                                ...searchRiders,
                                rider_disciplines :
                                    {...searchRiders.rider_disciplines, Endurance : !searchRiders.rider_disciplines.Endurance}
                            })}/>
                        <Checkbox
                            CheckboxText='Attelage'
                            CheckboxValue='Attelage'
                            onClick={() => setSearchRiders({
                                ...searchRiders,
                                rider_disciplines :
                                    {...searchRiders.rider_disciplines, Attelage : !searchRiders.rider_disciplines.Attelage}
                            })}/>
                        <Checkbox
                            CheckboxText='Voltige'
                            CheckboxValue='Voltige'
                            onClick={() => setSearchRiders({
                                ...searchRiders,
                                rider_disciplines :
                                    {...searchRiders.rider_disciplines, Voltige : !searchRiders.rider_disciplines.Voltige}
                            })}/>
                        <Checkbox
                            CheckboxText='Disciplines culturelles'
                            CheckboxValue='Disciplines culturelles'
                            onClick={() => setSearchRiders({
                                ...searchRiders,
                                rider_disciplines :
                                    {...searchRiders.rider_disciplines, Disciplines_culturelles : !searchRiders.rider_disciplines.Disciplines_culturelles}
                            })}/>
                    </div>
                </div>
                <ins
                    className="adsbygoogle"
                    style={{ display: "block", width: "250px", height: "50px"}}
                    data-ad-client="ca-pub-7709898933114568"
                    data-ad-slot={slotValues[1]}
                    data-ad-format="auto"
                    data-full-width-responsive="true">
                </ins>

                <hr />

                <BudgetMensuel
                    budgetTitle='Budget'
                    budget={searchRiders.rider_budget}
                    currency={searchRiders.currency_budget}
                    priceTitle={'Prix maximum par mois :'}
                    onChange={(e) => setSearchRiders({
                        ...searchRiders,
                        rider_budget : e.target.value
                    })}
                    onClick={(e) => setSearchRiders({
                        ...searchRiders,
                        rider_currency_budget : e.target.value
                    })}
                />
                <hr/>

                <div className='frequency_pension'>
                    <Pension
                        onClick={(e) => setSearchRiders({
                            ...searchRiders,
                            rider_riding_frequency : e.target.value
                        })}
                        frequency={searchRiders.rider_riding_frequency}
                        changeFixedFrequency={() => setSearchRiders({
                            ...searchRiders,
                            rider_fixed_day : !searchRiders.rider_fixed_day
                        })}
                        checked={searchRiders.rider_riding_frequency}
                        checked1={searchRiders.rider_fixed_day}
                    />
                </div>
                <hr />
                <div className='materialDiv'>
                    <h4>Materiel</h4>
                    <div className='materiel'>
                        <SlidingButton
                            SlidingButtonText="Le cavalier doit avoir sa selle"
                            SlidingButtonID="materialSwitch"
                            onClick={() => setSearchRiders({
                                ...searchRiders,
                                rider_own_saddle : !searchRiders.rider_own_saddle
                            })}

                        />
                    </div>

                </div>
                <hr />
                <div className='competition'>
                    <Competition
                        onClick={(e) => setSearchRiders({
                            ...searchRiders,
                            rider_do_competition : e.target.value
                        })}
                        checked={searchRiders.rider_competition}
                    />
                </div>
            </div>
            <Link to={{pathname: "/rider/results"}}>
                <FloatingButton
                    btnName={'Lancer la recherche'}
                />
            </Link>

            <Menu />
        </>
    )
}
export default SearchRider
