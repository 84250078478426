import React, { useState } from 'react';
import { HashLink } from 'react-router-hash-link';
import "./Contract.css";
import Header from "../Header_footer/Header";
import Checkbox from "../common/Checkbox";
import FloatingButton from "../common/FloatingButton";

const ContractGoodFather = () => {

    const [button1, setButton1] = useState(false);

    return(
        <>
            <Header className="header" title="Rédiger un contrat" hash='/contract#goodFather' />
            <div className='contract_page'>
                <h4 className='center'>Notions de « bon père de famille »</h4>
                <p>La notion de bon père de famille (ou bonus pater familias selon un latinisme d'usage courant dans les milieux juridiques) est un concept de droit civil. Il s'agit du comportement qu'aurait un individu abstrait dans une situation donnée, servant de norme générale pour mesurer l'adéquation de la conduite d'un individu concret placé dans la même situation afin de déterminer l'existence ou la mesure d'une éventuelle faute.</p>
                <p>Normalement prudent et diligent, attentif, soucieux des biens et/ou des intérêts qui lui sont confiés comme s'il s'agissait des siens propres, le bon père de famille est utilisé par les juristes comme mètre étalon pour déﬁnir, dans un contexte donné, la norme comportementale en se rapportant à un individu de référence.<br/>
                Lorsque le comportement d'une personne s'est écarté de cette norme, il y a faute qui peut être simple ou lourde selon la gravité du comportement ou le degré de déviation par rapport à la norme. Cependant, même si les conséquences peuvent être différentes, la faute la plus légère impliquera la responsabilité civile de son auteur si elle a causé un dommage.
                </p>
                <Checkbox 
                    CheckboxText="Je m’engage à utiliser l’équidé selon les règles de l'art et en « bon père de famille »."
                    onChange={evt => {setButton1(!button1)}}
                    checked={button1}
                />
                {button1 ? (
                    <HashLink to="/contract#goodFather" style={{ textDecoration: "none" }}>
                        <FloatingButton btnName='Valider' />
                    </HashLink>
                ) : (
                    <FloatingButton btnName='Valider' inactive disabled />
                )}
                
            </div>
        </>
    )
}


export default ContractGoodFather;