import React, { useState } from 'react';
import "./event.css";
import WorkIntensity from '../../common/WorkIntensity';

const { REACT_APP_AWS_BUCKET_URL } = process.env;


const DisplayEvent = ({ event, setModifyEventSelected }) => {

    const photos = ['photo1', 'photo2', 'photo3', 'photo4', 'photo5'];
    const audios = ['audio1', 'audio2', 'audio3', 'audio4', 'audio5'];

    const createImgURLs = () => {
        const urlBase = REACT_APP_AWS_BUCKET_URL + "/events/" + event.event_ID + "/imgs/";
        var imgURLs = [];
        for (var photo of photos) {
            if (event[photo]) {
                imgURLs.push({ fileName: event[photo], url: (urlBase+event[photo]) })
            }
        }
        return imgURLs;
    }

    const createAudioURLs = () => {
        const urlBase = REACT_APP_AWS_BUCKET_URL + "/events/" + event.event_ID + "/audios/";
        var audioURLs = [];
        for (var audio of audios) {
            if (event[audio]) {
                audioURLs.push({ fileName: event[audio], url: (urlBase+event[audio]) })
            }
        }
        return audioURLs;
    }

    const HourToString = (date) => {
        var hours = date.getHours() + "";
        var minutes = date.getMinutes() + "";
        if (hours.length === 1) {
            hours = "0" + hours;
        }
        if (minutes.length === 1) {
            minutes = "0" + minutes;
        }
        return hours + "h" + minutes;
    };


    return(
        <>
            <h5>Heure :</h5>
            <div className="hoursLine">
                <p>{"De " + HourToString(event.begin) + " à " + HourToString(event.end)}</p>
            </div>
            <hr/>

            <h5>Catégorie de soin : {event.categorie}</h5>
            <hr />

            <h5>Précisions :</h5>
            <form className="postHorse_msg">
            <label>
                <textarea
                    className="postHorse_input"
                    type="text"
                    value={event.precisions ? event.precisions : ""}
                    readOnly
                />
            </label>
            </form>
            <hr/>

            <h5>Message vocal :</h5>
            <div className="recordSection">
                {createAudioURLs().map(({ fileName, url }) => (
                    <div key={fileName}>
                        <audio src={url} controls="controls" />
                    </div>
                ))}
            </div>
            <hr/>

            <h5>Intensité du travail :</h5>
                <WorkIntensity intensity={event.workIntensity}/>
            <hr/>

            <h5>Photos :</h5>

            <div className="allPhotos">
                {createImgURLs().map(({ fileName, url }) => (
                    <div className="photos" key={fileName}>
                        <img className="newPhotos2" src={url} alt='img'/>
                    </div>
                ))}
            </div>

            <hr/>

            <button className="greenButton" onClick={evt => setModifyEventSelected(true)}>
                Modifier
            </button>
            
        </>
    );
};


export default DisplayEvent;