import React from 'react'
import { useHistory } from 'react-router-dom'
import Header from '../Header_footer/Header'
import './calculate.css'
import Menu from '../Menu'

const CalculatePresentation = () => {

    const history = useHistory();

    const nextPage = (evt) => {
        history.push("/calculate/rider-number");
    };

    return (
        <>
            <Header className="header" title="Mieux partager vos frais" path='home' />
            <div className="calculate_page">
                <h4 className="calculate_title">Bienvenue dans l'assistant de calcul.<br/> Service gratuit.</h4>
                <p>
                CavalCloud a imaginé pour vous cette fonction de calcul. Quelle que soit la durée de votre collaboration avec vos co-cavaliers, elle estime le prix mensuel que chacun devrait payer en fonction de vos exigences. Il vous sera demandé le montant des dépenses liées à votre équidé ainsi que le pourcentage que vous décidez de confier à chacun. Vous pourrez partager les résultats obtenus avec vos co-cavaliers !
                </p>

                <div className="finalButton">
                    <div className="ArrowButton">
                        <svg width="1em" height="1em" viewBox="0 0 16 16" className="bi bi-chevron-right" fillRule="#5d5aa4" xmlns="http://www.w3.org/2000/svg">
                            <path fillRule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"/>
                        </svg>
                        <button onClick={nextPage}>
                            Démarrer la simulation
                        </button>
                    </div>
                </div>
                
            </div>
        <Menu />
        </>     
    )
}
            
export default CalculatePresentation;