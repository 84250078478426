import React, { useEffect, useState } from 'react'
import Header from '../Header_footer/Header'
import DeleteConversation from '../common/ModalDeleteConversation'
import Axios from 'axios'
import { Link } from 'react-router-dom'
import { ListGroup, Image, Container, Button } from 'react-bootstrap'
import './myMessages.css'
import NotificationBadge from 'react-notification-badge';
import {Effect} from 'react-notification-badge';
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Menu from '../Menu'

const { REACT_APP_API_URL } = process.env;

const ListConversations = () => {

    const token = localStorage.token

    // Data from messages on DB
    const [conversation, setConversation] = useState([])

    // Get number of unread messages from other users 
    const [unreadMsg, setUnreadMsg] = useState(null)

    // Want delete or not a conversation
    const [conversationId, setConversationId] = useState(null)
    const [recipientId, setRecipientId] = useState(null)
    // Refresh display 
    const refreshPage = () => {window.location.reload()}
    
    // Get conversations from users in touch with
    const getConversations = () => {
        Axios
        .get(`${REACT_APP_API_URL}/api/users/my-messages/conversations`, { 
            headers : { 'Authorization' : 'Bearer ' + token}
            })
        .then(res => setConversation(res.data))
        .catch(err => console.log(err))    
    }

    // Get number of unread messages from conversations from users in touch with
    const getUnreadMessages = () => {
        Axios
        .get(`${REACT_APP_API_URL}/api/users/my-messages/conversations/unread/count`, { 
            headers : { 'Authorization' : 'Bearer ' + token}
            })
        .then(res => setUnreadMsg(res.data))
        .catch(err => console.log(err))    
    }
    
    // Delete a conversation
    const deleteConversation = () => {
        Axios
        .delete(`${REACT_APP_API_URL}/api/users/my-messages/delete/${conversationId}`, 
            { 
            params : {recipientId : recipientId},
            headers : { 'Authorization' : 'Bearer ' + token}
            })
        .catch(err => console.log(err))    
    }

    useEffect(() => {
        getConversations()
        getUnreadMessages()
    }, [])

    return (
        <>
            <Header className="header" title="Ma messagerie" path='home' />
            {token === undefined ?
                    <div className="postHorse_page">
                <p style={{'text-align' : 'center'}}>Vous devez être connecté(e) pour accéder à cette fonctionnalité.</p>
                <div className='login' >
                    <Link to='/login' style={{ textDecoration: "none" }}>
                        <button type='button' id='loginBtn' > Se connecter </button>
                    </Link>
                </div>
                <p style={{'text-align' : 'center'}}>Pas encore de compte ? Créer un compte gratuitement</p>
                <div className='create' >
                    <Link to='/register' style={{ textDecoration: "none" }}>
                        <button type='button' id='createBtn' > Créer un compte </button>
                    </Link>
                </div>
                </div>

      : <>
            {conversation === null || conversation.length === 0 ? 
                <div> <h3 id='listItem'>Vous n'avez pas encore démarré de discussions</h3> </div>
            : 
                <Container variant="flush">

                   {conversation.map(e => 
                            <ListGroup.Item id='listItem' key={e.id}>
                                <div>
                                    {unreadMsg !== null && unreadMsg.filter(conversation => conversation.id === e.id).length !== 0 ? 
                                        <NotificationBadge count={unreadMsg.filter(conversation => conversation.id === e.id)[0].Messages.length} effect={Effect.SCALE}/>
                                    : null }
                                    <Image src={e.Users[0].user_avatar} roundedCircle className='userImg' /> 
                                </div>
                                <h3> {e.Users[0].user_firstname} </h3>
                                <Container className='discuss'>
                                    <Link to={{ pathname:`/conversation/${e.Users[0].user_ID}`}} style={{ textDecoration: "none" }}>
                                        <Button variant="dark" > Discuter </Button>
                                    </Link>
                                </Container>
                                <FontAwesomeIcon icon={faTrash} style={{'color' : '#EC4F2E'}} onClick={() => {
                                    setConversationId(e.id)
                                    setRecipientId(e.Users[0].user_ID)
                                    }}/>
                            
                            </ListGroup.Item>
                        )
                    }
                        {conversationId ? <DeleteConversation delete={() => deleteConversation()} refresh={() => refreshPage()} /> : null}
                  </Container>
            }
            </>
            }
            <Menu />
        </>     
    )
}
            
export default ListConversations;
