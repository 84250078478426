import React from 'react'
import RadioButton from '../common/RadioButton'
import './common_section.css'
import SlidingButton from "../common/SlidingButton";
//1 à 2 fois / 3 à 4 fois / 5 à 7 fois

const Pension = (props) => {

    return (
      <>
        <div className="frequencyDiv">
            <h4>Rythme de la demi-pension</h4>
          
        </div>
        
          <div className='select_frequency'>
            <RadioButton
              radioButtonText="1 à 2 fois /semaine"
              radioButtonValue="1xsemaine"
              radioButtonName="frequency"
              radioButtonId="frequency1"
              onClick={props.onClick}
              checked={props.checked==='1xsemaine'}
            />

            <RadioButton
              radioButtonText="3 à 4 fois /semaine"
              radioButtonValue="3xsemaine"
              radioButtonName="frequency"
              radioButtonId="frequency2"
              onClick={props.onClick}
              checked={props.checked==='3xsemaine'}
              />

            <RadioButton
              radioButtonText="5 à 7 fois /semaine"
              radioButtonValue="5xsemaine"
              radioButtonName="frequency"
              radioButtonId="frequency3"
              onClick={props.onClick}
              checked={props.checked==='5xsemaine'}
            />

          </div>
          <div className="select_frequency"> 
          <h5>Jours de venue</h5>
          <SlidingButton
            SlidingButtonText="Les mêmes jours d'une semaine sur l'autre"
            SlidingButtonID="fixedDaysSwitch"
            onClick={props.changeFixedFrequency}
            checked1={props.checked===true}
          />
          </div>
        
      </>
    );
}

export default Pension
