import React, { useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import Header from '../Header_footer/Header'
import Axios from "axios";
import './calculate.css';
import ModalMail from "../common/ModalMail";
import Menu from '../Menu'

const { REACT_APP_API_URL } = process.env;

const CalculateMail = () => {

    const history = useHistory();
    const location = useLocation();
    const state = location.state;

    const [modalShow, setModalShow] = useState(false);

    const [myMail, setMyMail] = useState("");
    const [othersMails, setOthersMails] = useState([]);

    const sendMails = (evt) => {
        Axios
            .post(`${REACT_APP_API_URL}/api/calculate/sendMails`, { riders: state, mails: [myMail].concat(othersMails) })
            .then(res => {
                setModalShow(true);
                setTimeout(() => setModalShow(false), 3000);
            })
            .catch((err) => console.log(err));
    };

    const addNewMail = () => {
        var newOthersMails = [...othersMails];
        newOthersMails.push("");
        setOthersMails(newOthersMails);
    };

    const changeOthersMails = (index, value) => {
        var newOthersMails = [...othersMails];
        newOthersMails[index] = value;
        setOthersMails(newOthersMails);
    };


    return (
        <>
            <Header className="header" title="RESULTAT" path='home' />
            <ModalMail show={modalShow} onHide={e => setModalShow(false)} />

            <div className="calculate_page">
                <p>Votre e-mail</p>
                <input
                    className='classicInput2'
                    value={myMail}
                    onChange={evt => setMyMail(evt.currentTarget.value)}
                />

                <p>Autres e-mail :</p>
                {othersMails.map((mail, index) => (
                    <input
                        key={index}
                        className='classicInput2'
                        value={mail}
                        onChange={evt => changeOthersMails(index, evt.currentTarget.value)}
                    />
                ))}
                <div className="addButtonFrame">
                    <svg width="2em" height="2em" viewBox="0 0 16 16" className="bi bi-plus" xmlns="http://www.w3.org/2000/svg">
                        <path fillRule="evenodd" d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" />
                    </svg>
                    <button className="addButton" onClick={addNewMail}>Ajouter une adresse email</button>
                </div>
                <hr />

                <button className="greenButton" onClick={sendMails}>Valider</button>
                <button className="whiteButton" onClick={evt => history.push('/calculate')}>Nouvelle simulation</button>

            </div>
            <Menu />
        </>
    )
}

export default CalculateMail;