import React, { useState, useEffect } from "react"
import "./HorseResult.css";
import ReturnButton from "../common/ReturnButton";
import Axios from "axios";
import Carousel, { Dots } from "@brainhubeu/react-carousel";
import "@brainhubeu/react-carousel/lib/style.css";
import ChatButton from '../common/FloatingButton';
import DeleteAnnonce from '../common/ModalDelete';
import { createBrowserHistory } from "history";
const { REACT_APP_API_URL } = process.env;


const HorseResultAnnonce = (props) => {
  const [count, setCount] = useState(0);
  const [values, setValue] = useState(0);
  let array =[];
  let images;
  const defaultAvatar = 'https://static.wixstatic.com/media/8fa7e6_09cf11c3e4584b259145ecc0b2633997.jpg/v1/fill/w_224,h_224,al_c,lg_1,q_80/8fa7e6_09cf11c3e4584b259145ecc0b2633997.webp'

  const token = localStorage.token;
  // Get the Horse_ID in order to display the specific result
  const horseId = Number(props.match.params.id)
  const [modal, setModal] = useState(false);
  // User Data storage:
  const [dataUser, setDataUser] = useState({
    user_lastname: "",
    user_firstname: "",
    user_email: "",
    user_avatar: '',
    user_phone: "",
    user_ID: ''
  })
  // Get user profil
  const getMyProfile = () => {
    Axios.get(`${REACT_APP_API_URL}/api/users/profile`, {
      headers: { 'Authorization': 'Bearer ' + token }
    })
        .then((res) => setDataUser(res.data))
        .catch((error) => console.log(error))
  }

  // Horse Data information
  const [dataHorse, setDataHorse] = useState(null)
  const history = createBrowserHistory();
  const handleClick = () => {
    setModal(true);

  }
  const refreshPage = () => {
    window.location.reload();
    history.goBack();
   }
  const toggle = () => setModal(!modal);

  // Get information about the selected horse from its ID
  const getHorseInformation = () => {
    Axios
      .get(`${REACT_APP_API_URL}/api/horses/${horseId}`)
      .then(res => {setDataHorse(res.data[0]);
         array.push(res.data[0].horse_photo1); 
        console.log(array);
        images = array.map(image => {
          console.log(image);
          return <img key={image} src={image}  />
       }); 

      })
      .catch(err => console.error(err))


  }

    // delete information about the selected horse from its ID
    const deleteHorseInformation = () => {
      Axios
        .delete(`${REACT_APP_API_URL}/api/horses/${horseId}`)
        .then(res =>{})
        .catch(err => console.error(err))
  
  
    }



  // Transforme les booléens récupérés de l'annonce en oui ou non 
  const changeBool = (bool) => bool === true ? 'oui' : 'non'
  const changeBool2 = (num) => num === 1 ? <span className='greenLight'>oui</span> : 'non'
  // When a result is displayed, the function getHorseInformation starts first in order to query BDD
  useEffect(() => {
        getMyProfile();
    getHorseInformation();
    changeBool(dataHorse);

  }, []
  )

  const handleImageError = e => { 
    e.target.style.display = 'none';
    setCount(count+1);
  }

  const  onChange = values => setValue(values);


 

  return (
    <>

      <div className="headerAnnonce">
        <h3 id="annonceTitle">Annonce Equidé</h3>{" "}
      </div>

      <ReturnButton />

      {dataHorse != null ?

        <>

          <div className="Result_annonce">
            <div className="annonce_header">
              <div>
                <h4>Infos sur l'équidé </h4>
                <h5>
                  {dataHorse.horse_name} <span>{dataHorse.horse_age} ans</span>
                </h5>
                <p>
                  {dataHorse.horse_height}cm, {dataHorse.horse_temper},{" "}
                  {dataHorse.horse_character}, {dataHorse.horse_body_type}
                </p>
              </div>
            </div>
            <h5 className="test">Quelques photos</h5>
           {/*  {images}  */}
            <Carousel  >{/* itemWidth={330} itemHeight={200} centered offset={-9} */}
           
              <img onError={handleImageError} 
                src={dataHorse.horse_photo1}
                alt="horse_photo1"
                className="imagesHorse"
              />
            <img onError={handleImageError} 
                src={dataHorse.horse_photo2}
                alt="horse_photo2"
                className="imagesHorse"
              />
              <img onError={handleImageError} 
                src={dataHorse.horse_photo3}
                alt="horse_photo3"
                className="imagesHorse"
              /> 
            </Carousel>
          <Dots  value={values} onChange={onChange}  number={3-count} />   
            <br />
            <h5>Où se trouve t-il ?</h5>
            <p>{dataHorse.horse_localisation}</p>
            <hr />

            <div>
              <h4>Infos du propriétaire </h4>
              <img
                className="annonce_logo"
                src={dataHorse.User.user_avatar != null ? dataHorse.User.user_avatar : defaultAvatar}
                alt="logo"
              />
              <h5>
                {dataHorse.owner_firstname} <span>{dataHorse.owner_age}ans</span>
              </h5>
              <p>N° de téléphone : {dataHorse.User.user_phone != null ? dataHorse.User.user_phone : null}</p>
              <p>Mail : {dataHorse.User.user_email}</p>
              <p>{dataHorse.owner_caracter} </p>
              <p>
                Fréquence de communication :{" "}
                {dataHorse.owner_communication_frequency}{" "}
              </p>
              <p>Type de travail du cheval : {dataHorse.owner_horse_work}</p>
              <h5>{dataHorse.owner_firstname} en quelques mots :</h5>
              <p>{dataHorse.owner_message}</p>
            </div>
            <hr />
            <div>
              <h4>Budget</h4>
              <p>
                {dataHorse.horse_budget}
                {dataHorse.horse_currency_budget} / mois
            </p>
              <p>
                Dépenses supplémentaires à prévoir :<br />
                {changeBool(dataHorse.horse_other_fees)}
              </p>
            </div>
            <hr />
            <div>
              <h4>Hebergement de l'équidé : </h4>
              <p>« {dataHorse.horse_location_type.replace(/\_/g, ' ')} »</p>
              <p>{dataHorse.horse_accomodation.replace(/\_/g, ' ')}</p>
              <h5>Structure(s) disponible(s) :</h5>
              <div>
                <p>
                  Carrière : {changeBool2(dataHorse.PracticeStructure.carriere)} <br />
                Champs : {changeBool2(dataHorse.PracticeStructure.champs)}<br />
                Chemins de balade : {changeBool2(dataHorse.PracticeStructure.cheminDeBalade)}<br />
                Cross : {changeBool2(dataHorse.PracticeStructure.cross)}<br />
                Manège Couvert : {changeBool2(dataHorse.PracticeStructure.manegeCouvert)}<br />
                Piste de Trotting : {changeBool2(dataHorse.PracticeStructure.pistedeTrotting)}<br />
                Rond de Longe : {changeBool2(dataHorse.PracticeStructure.rondDeLonge)}<br />
                </p>
              </div>
            </div>
            <hr />
            <div>
              <h4>Disciplines & travail de l'équidé</h4>
              <div>
                <p>
                  Attelage : {changeBool2(dataHorse.Discipline.attelage)} <br />
          Balade ou Randonnée : {changeBool2(dataHorse.Discipline.baladeRandonnee)}<br />
          CCE : {changeBool2(dataHorse.Discipline.cce)}<br />
          Disciplines Culturelles : {changeBool2(dataHorse.Discipline.disciplinesCulturelles)}<br />
          Dressage : {changeBool2(dataHorse.Discipline.dressage)}<br />
          Endurance : {changeBool2(dataHorse.Discipline.endurance)}<br />
          Ethologie/Equifeel : {changeBool2(dataHorse.Discipline.ethologieEquifeel)}<br />
          Horse Ball : {changeBool2(dataHorse.Discipline.horseBall)}<br />
          Hunter : {changeBool2(dataHorse.Discipline.hunter)}<br />
          Obstacle : {changeBool2(dataHorse.Discipline.obstacle)}<br />
          Pony Games : {changeBool2(dataHorse.Discipline.ponyGames)}<br />
          Reining Western : {changeBool2(dataHorse.Discipline.reiningWestern)}<br />
          TREC Equifun : {changeBool2(dataHorse.Discipline.trecEquifun)}<br />
          Voltige : {changeBool2(dataHorse.Discipline.voltige)}<br />
                </p>
              </div>
              <p>
                Balade seul possible : {changeBool(dataHorse.horse_stroll_along)}
              </p>
              <p>Compétition : {dataHorse.horse_competition_preferences}</p>
            </div>
            <hr />
            <div>
              <h4>Rythme de la demi-pension</h4>
              <p>{dataHorse.horse_riding_frequency}</p>
              <p>
                Jours fixes d'une semaine à l'autre :{" "}
                {changeBool(dataHorse.horse_fixed_day)}
              </p>
            </div>
            <hr />
            <div>
              <h4>Coaching</h4>
              <p>
                Cours sur place disponible :{" "}
                {changeBool(dataHorse.horse_coaching_here)}
              </p>
              <p>
                Intervenant exterieur possible :{" "}
                {changeBool(dataHorse.horse_external_coach)}
              </p>
            </div>
            <hr />
            <div>
              <h4>Materiel</h4>
              <p>
                Le cavalier doit avoir sa selle :{" "}
                {changeBool(dataHorse.horse_material)}
              </p>
            </div>
            <hr />
            <div>
              <h4>Cavalier ideal</h4>
              <p>Age : {dataHorse.ideal_rider_age} ans</p>
              <p>
                Années de pratique :{dataHorse.ideal_rider_years_of_practice} ans
            </p>
              <p>Niveau de Galop : {dataHorse.ideal_rider_gallop_level}</p>
              <p>Est-il véhiculé ? {changeBool(dataHorse.ideal_rider_vehiculed)}</p>
              <p>
                A-t-il déjà eu un cheval sous sa responsabilite ?{" "}
                {changeBool(dataHorse.ideal_rider_managed_horse)}
              </p>
            </div>

    
            {
              dataHorse.user_ID === dataUser.user_ID ?
                  <ChatButton btnName="Supprimer l'annonce" onClick={handleClick} />
                  :
                  <ChatButton btnName='Lancer une conversation' onClick={() => window.location.href=`/conversation/${dataHorse.user_ID}`}/>
            }


           <DeleteAnnonce isOpen={modal}  toggle={() => toggle()}  delete={() => deleteHorseInformation()} refresh={() => refreshPage()
           } />
          </div>
        </>
        :
        <div className="Result_annonce">
          <p>Cette annonce n'est pas accessible ou n'existe plus.</p>
        </div>
      }
    </>
  );
};
export default HorseResultAnnonce;
