import React, { useState } from 'react';
import "../organise.css";
import { useLocation, Link } from 'react-router-dom';
import Header from "../../Header_footer/Header";
import DateTitle from '../../common/DateTitle';
import ListOfEvents from "./ListOfEvents";
import DisplayEvent from "./DisplayEvent";
import NewEvent from "./NewEvent";
import ModifyEvent from "./ModifyEvent";
import Menu from '../../Menu'

const CalendarDate = (props) => {

    const location = useLocation();
    const [events, setEvents] = useState(location.state.events);
    const horse = location.state.horse;

    const calendarCode = props.match.params.code;
    const date = new Date(Number(props.match.params.date));

    const [eventIdToSelect, setEventIdToSelect] = useState(-1);

    const [modifyEventSelectedPage, setModifyEventSelectedPage] = useState(false);
    const [newEventPage, setNewEventPage] = useState(false);

    var eventSelected = false;
    for (var event of events) {
        if (event.event_ID === eventIdToSelect) {
            eventSelected = event;
            break;
        }
    }

    const addEvent = (evt) => {
        var eventsCopy = events.slice();
        eventsCopy.push(evt);
        setEvents(eventsCopy);
    };

    const modifyEvents = (evt) => {
        var eventsCopy = events.slice();
        for (var i in events) {
            if (events[i].event_ID === evt.event_ID) {
                eventsCopy[i] = evt;
                break;
            }
        }
        setEvents(eventsCopy);
    };


    return (
        <>
            <Header className="header" title={horse} link={"/organise/" + calendarCode} />
            <div className='organise_page calendar-date'>
                <DateTitle date={date} />
                <hr />

                {eventSelected ? (
                    <>
                        {modifyEventSelectedPage ? (
                            <ModifyEvent date={date} event={eventSelected} setModifyEventSelected={setModifyEventSelectedPage} setEventIdToSelect={setEventIdToSelect} modifyEvents={modifyEvents} />
                        ) : (
                                <DisplayEvent event={eventSelected} setModifyEventSelected={setModifyEventSelectedPage} />
                            )}
                    </>
                ) : (
                        <>
                            {newEventPage ? (
                                <NewEvent date={date} calendarCode={calendarCode} setNewEventPage={setNewEventPage} addEvent={addEvent} />
                            ) : (
                                    <ListOfEvents events={events} setEventIdToSelect={setEventIdToSelect} setNewEventPage={setNewEventPage} />
                                )}
                        </>
                    )}

            </div>
            <Menu />
        </>
    );
};


export default CalendarDate;