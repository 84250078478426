import React, { useState } from 'react';
import "./organise.css";
import Axios from "axios";


const { REACT_APP_API_URL } = process.env;


const CreateCalendar = ({ setCodeOfCalendar }) => {

    const token = localStorage.token;

    const [horse, setHorse] = useState("");
    const [institution, setInstitution] = useState("");

    const createCalendar = (evt) => {
        const calendar = {
            name: "",
            mail: "",
            phone: 0,
            horse,
            institution,
        };
      Axios
      .post(`${REACT_APP_API_URL}/api/calendar`, calendar, {headers : { 'Authorization' : token}})
      .then(res => setCodeOfCalendar(res.data.code))
      .catch((err) =>console.log(err));
    };

    return(
        <>
        
          {/* <h5>Présentation :</h5>
          <input
            className="customInput"
            type="text"
            placeholder="Votre prénom"
            onChange={(event) => setName(event.target.value) }
          />
          <input
            className="customInput"
            type="text"
            placeholder="Adresse mail"
            onChange={(event) => setMail(event.target.value) }
          />
          <input
            className="customInput"
            type="tel"
            placeholder="Téléphone"
            onChange={(event) => setPhone(event.target.value) }
          />
          <hr/> */}

          <h5>Le cheval :</h5>
          <input
            className="customInput"
            type="text"
            placeholder="Nom du cheval"
            onChange={(event) => setHorse(event.target.value) }
          />
          <input
            className="customInput"
            type="text"
            placeholder="Ecurie ou club"
            onChange={(event) => setInstitution(event.target.value) }
          />
          <hr/>

          <button className="greenButton" id="newButton" onClick={createCalendar}> 
              Créer le calendrier
          </button>
            
        </>
    );
};


export default CreateCalendar;