import React from 'react'
import { Link } from 'react-router-dom'
import Field from '../common/Field'
import './common_section.css'
import chevron from '../SVG-icons/chevron.svg'


const Fields = ({ title, fields, link, state, id }) => (
    <div className="costomField">
        <Link to={{pathname: link, state: state}} style={{ textDecoration: "none" }}>
            <img className="chevron" src={chevron} alt="chevron" />
            <h4 id={id} className="customBlack">{title}</h4>
            {fields.map(({ name, value }) => (
                <Field name={name} value={value || "A remplir"} key={name} />
            ))}
        </Link>
    </div>
)

export default Fields