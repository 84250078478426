import React from 'react';
import { withRouter } from "react-router-dom";

const AuthVerifyComponent = ({ history }) => {
    var decoded;
    var expiration; 
    var getToken;
    var jwtDecode = require('jwt-decode');
  history.listen(() => {  // <--- Here you subscribe to the route change
    if (localStorage.getItem("token")) {

        getToken=localStorage.getItem("token");
        decoded = jwtDecode(getToken);
        expiration= decoded.exp;
        const now =Date.now();
        const expirationTime =(expiration*1000) - now;
        if(expirationTime<0){
            window.location.reload();
            localStorage.removeItem("token");
        }
    }
  });
  return <div></div>;
};

export default withRouter(AuthVerifyComponent);

