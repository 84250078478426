import React from 'react'
import './floatingButton.css'


const FloatingButton = (props) => {

    return (
      <div className='floating-button' >
        <button 
          id={props.inactive ? "floating-button-inactive" : "floating-button"}
          onClick={props.onClick}
          disabled={props.disabled}
        > 
          {props.btnName}
        </button>
      </div>
    );}

export default FloatingButton
