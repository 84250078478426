import React from 'react';
import "../organise.css";
import { Link } from 'react-router-dom';

const ListOfEvents = ({ events, setEventIdToSelect, setNewEventPage }) => {

    const HourToString = (date) => {
        var hours = date.getHours() + "";
        var minutes = date.getMinutes() + "";
        if (hours.length === 1) {
            hours = "0" + hours;
        }
        if (minutes.length === 1) {
            minutes = "0" + minutes;
        }
        return hours + "h" + minutes;
    };

    return(
        <>
            {events.map(event => (
                <div key={event.event_ID}>
                    <div className="oneCalendarButton">
                        <button className="greenFrame2" onClick={evt => setEventIdToSelect(event.event_ID)}>
                            {event.categorie + " de " + HourToString(event.begin) + " à " + HourToString(event.end)}
                        </button>
                        <svg width="2em" height="2em" viewBox="0 0 16 16" className="bi bi-chevron-right" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                            <path fillRule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"/>
                        </svg>
                    </div>
                    <hr/>
                </div>
            ))}

            <button className="greenButton" onClick={evt => setNewEventPage(true)}>
                Ajouter un événement
            </button>
        </>
    );
};


export default ListOfEvents;