import React, { useState, useContext } from 'react';
import { HashLink } from 'react-router-hash-link';
import { ContractContext } from "../context/ContractContext";
import "./Contract.css";
import Header from "../Header_footer/Header";
import FloatingButton from '../common/FloatingButton'

const ContractWarrantyExtension = () => {

    const { contractState, setContractState } = useContext(ContractContext);
    const [date, setDate] = useState(contractState.warrantyExtension);

    const updateContract = () => {
        setContractState({ ...contractState, warrantyExtension: date });
    }

    return(
        <>
        <Header className="header" title="EXTENSION DE GARANTIE" hash='/contract#warrantyExtension' />
        <div className='contract_page'>
            <h4>Extension de ces garanties au bénéfice de l'utilisateur effectuée par le propriétaire le :</h4>
            <div className="dateField">
                <input 
                    type="date"
                    value={date}
                    onChange={evt => setDate(evt.currentTarget.value)}
                />
            </div>
            <HashLink to="/contract#warrantyExtension" style={{ textDecoration: "none" }}>
                <FloatingButton btnName='Valider' onClick={updateContract}/>
            </HashLink>
        </div>
    </>
    )
} 

export default ContractWarrantyExtension;