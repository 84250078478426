import React from 'react'
import './checkbox2.css'

const Checkbox2 = (props) => {
    
    return (
        <div>
        <div className="checkboxBtn-list">
            <input
                className="checkboxBtn"
                type="checkbox"
                id={props.CheckboxText}
                name={props.CheckboxText}
                value={props.CheckboxValue}
                onClick={props.onClick}
                onChange={props.onChange}
                checked={props.checked}
            />
            <label htmlFor={props.CheckboxText}>{props.CheckboxText} </label>
        </div>
        </div>
    
    )}

export default Checkbox2