import React, { useState, useContext } from 'react';
import { HashLink } from 'react-router-hash-link';
import { ContractContext } from "../context/ContractContext";
import "./Contract.css";
import Header from "../Header_footer/Header";
import FloatingButton from '../common/FloatingButton'
import Checkbox from "../common/Checkbox"

const ContractInsurance = () => {

    const { contractState, setContractState } = useContext(ContractContext);
    const insurances = contractState.insurances;

    const [civile, setCivile] = useState(insurances.civile);
    const [mortalite, setMortalite] = useState(insurances.mortalite);
    const [depreciation, setDepreciation] = useState(insurances.depreciation);
    const [immobilisation, setImmobilisation] = useState(insurances.immobilisation);

    const updateContract = () => {
        const newInsurances = {
            civile,
            mortalite,
            depreciation,
            immobilisation,
        };
        setContractState({ ...contractState, insurances: newInsurances });
    }

    return(
        <>
            <Header className="header" title="ASSURANCES" hash='/contract#insurance' />
            <div className='contract_page'>
                <h4>Le cheval est assuré en responsabilité :</h4>

                <Checkbox 
                    CheckboxText='Civile'
                    checked={civile}
                    onChange={(evt) => {setCivile(!civile)}}
                />
                <Checkbox 
                    CheckboxText='Mortalité'
                    checked={mortalite}
                    onChange={(evt) => {setMortalite(!mortalite)}}
                />
                <Checkbox 
                    CheckboxText='Dépréciation'
                    checked={depreciation}
                    onChange={(evt) => {setDepreciation(!depreciation)}}
                />
                <Checkbox 
                    CheckboxText='Immobilisation'
                    checked={immobilisation}
                    onChange={(evt) => {setImmobilisation(!immobilisation)}}
                />

                <HashLink to="/contract#insurance" style={{ textDecoration: "none" }}>
                    <FloatingButton btnName='Valider' onClick={updateContract}/>
                </HashLink>
            </div>
        </>
    )
}


export default ContractInsurance;