import React, { useState, useEffect } from "react";
import "./profile.css";
import Header from "../Header_footer/Header";
import Axios from "axios";
import HorseResultCard from "../Horse_Results/HorseResultCard";
import ResultCard from "../Rider_Results/ResultCard";

const { REACT_APP_API_URL } = process.env;

const UserProfile = (props) => {

  const token = localStorage.token 

  // Identification of the good recipient in order to create private chat conversation
  const userId = Number(props.match.params.userId)

  // User Data storage:
  const [dataUser, setDataUser] = useState(null)
    
  // Get user profil and all infos
  const getUserProfile = () => {
    Axios.get(`${REACT_APP_API_URL}/api/users/${userId}`, { 
      headers : { 'Authorization' : 'Bearer ' + token}
    })
    .then((res) => setDataUser(res.data))
    .catch((error)=> console.log(error))
  }

  useEffect(() => {
    getUserProfile()

  }, []);

  return (
    <>
      <Header className="header" title='Profil' path='listConversations'/>
    {dataUser === null ? 
      <p> Chargement en cours </p>
    :
      <div className="d-flex center flex-column flex-grow-1">
      <div className="Profile-Page">
          <div className="Profile-image">
            <img src={dataUser.user_avatar} className="Profile-photo" alt="Ajouter"/>
          </div>

          <p className="Profile-infos">
            {dataUser.user_firstname} {dataUser.user_lastname}
          </p>
        <hr />
        <h4>Email :</h4>
          <p>{dataUser.user_email}</p>
        <hr />
        <h4>Téléphone :</h4>
          <p>{dataUser.user_phone}</p>
        </div>
      <hr />
      <div className="Profile-annonces">

         <div>
          {dataUser.Horses.length !== 0 ? 
            <div>
              {dataUser.Horses.map(e => (
                <HorseResultCard
                  key={e.horse_ID}
                  fullResult={e}
                  horse_name={e.horse_name}
                  horse_ID={e.horse_ID}
                  photo={e.horse_photos}
                  statusFavorite={false}
              />
              ))}
              </div>
           : (
            <h3>L'utilisateur n'a pas encore publié d'annonce Equidé</h3>
          )}
        </div> 
        <hr />
        <div>
          {dataUser.Rider !== null ? 

            <ResultCard
                key={dataUser.user_ID}
                fullResult={dataUser.Rider}
                firstname={dataUser.Rider.rider_firstname}
                rider_ID={dataUser.Rider.rider_ID}
                photo={dataUser.Rider.rider_photos}
                statusFavorite={false}
              />
           : (
            <h3>L'utilisateur n'a pas publié d'annonce Cavalier</h3>
          )}
        </div> 
        </div>
      </div>
    }
    </>
  )
};

export default UserProfile;