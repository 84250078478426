import React, { useState, useContext } from 'react'
import { useLocation, useHistory, Link } from 'react-router-dom';
import { ContractContext } from "../context/ContractContext";
import "./Contract.css";
import Header from "../Header_footer/Header";
import SlidingButton from '../common/SlidingButton'
import Checkbox from "../common/Checkbox"
import FloatingButton from "../common/FloatingButton"
import Fields from "../common_section/Fields"
import chevron from '../SVG-icons/chevron.svg'


const Contract = () => {

    const { contractState, setContractState } = useContext(ContractContext);

    const stringVisitDays = () => {
        const frenchDays = { monday: 'lundi', tuesday: 'mardi', wednesday: 'mercredi', thursday: 'jeudi', friday: 'vendredi', saturday: 'samedi', sunday: 'dimanche' };
        var result = "";
        for(var day in contractState.visitDays) {
            if (contractState.visitDays[day]) {
                result += (frenchDays[day] + ", ");
            }
        }
        if (result !== "") {
            result = result.slice(0, -2);
        }
        return result;
    };

    
    /* const [useStateReceived, setuseStateReceived] = useState(true);

    const updateState = (stateReceived) => {
        if (stateReceived) {
            if (contractState !== stateReceived) {
                if (useStateReceived) {
                    setContractState(stateReceived);
                    setuseStateReceived(false);
                }
                
            }
        }
    }; */

    const history = useHistory();
    const location = useLocation();
    /* updateState(location.state); */

    return (
        <>
        <Header className="header" title="Rédiger un contrat" />
        <div className='contract_page'>
            <Fields
                title="Coordonnés du propriétaire"
                id="owner"
                link="/contract-owner"
                state={contractState}
                fields={[
                    {name:"Prénom", value: contractState.owner.firstName},
                    {name:"Nom", value: contractState.owner.lastName},
                    {name:"Adresse", value: contractState.owner.address},
                    {name:"Code postal", value: contractState.owner.postalCode},
                    {name:"Ville", value: contractState.owner.city},
                ]}
            />
            <hr/>
            
            <Fields
                title="Coordonnés du cavalier"
                id="rider"
                link="/contract-rider"
                state={contractState}
                fields={[
                    {name:"Prénom", value: contractState.rider.firstName},
                    {name:"Nom", value: contractState.rider.lastName},
                    {name:"Adresse", value: contractState.rider.address},
                    {name:"Code postal", value: contractState.rider.postalCode},
                    {name:"Ville", value: contractState.rider.city},
                ]}
            />
            <hr/>
            
            <Fields
                title="Information sur le cheval"
                id="horse"
                link="/contract-horse"
                state={contractState}
                fields={[
                    {name:"Nom complet", value: contractState.horse.name},
                    {name:"Immatriculation", value: contractState.horse.SIRE},
                ]}
            />
            <hr/>


            <h4 className="customBlack" id="disciplines">Disciplines pratiquées par le cheval</h4>

            <Checkbox 
                CheckboxText='Obstacle'
                checked={contractState.horseFields.obstacle}
                onChange={(evt) => {setContractState({ ...contractState, horseFields: { ...contractState.horseFields, obstacle: !contractState.horseFields.obstacle }})}}
            />
            <Checkbox 
                CheckboxText='Dressage'
                checked={contractState.horseFields.dressage}
                onChange={(evt) => {setContractState({ ...contractState, horseFields: { ...contractState.horseFields, dressage: !contractState.horseFields.dressage }})}}
            />
            <div className="NextArrowBlock">
                <div className="NextArrowButton2">
                    <svg width="1em" height="1em" viewBox="0 0 16 16" className="bi bi-chevron-right" fillRule="#5d5aa4" xmlns="http://www.w3.org/2000/svg">
                        <path fillRule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"/>
                    </svg>
                    <button onClick={e => history.push('/contract-disc')}>
                        Voir plus
                    </button>
                </div>
            </div>
            <hr />
            
            <h4>Balade</h4>
            <div className='balade'>
                <SlidingButton 
                    SlidingButtonText="Pouvoir partir seul en balade"
                    SlidingButtonID="baladSwitch"
                    checked={contractState.balad}
                    onChange={() => {setContractState({ ...contractState, balad: !contractState.balad})}}
                />
            </div>
            <hr/>
            
            <h4>Garantie</h4>
            <div className='balade'>
                <SlidingButton 
                    SlidingButtonText="Ni vicieux, ni dangereux"
                    SlidingButtonID="dangerousSwitch"
                    checked={!contractState.dangerous}
                    onChange={() => {setContractState({ ...contractState, dangerous: !contractState.dangerous})}}
                />
            </div>
            <hr/>
            
            <Fields
                title="Etablissement"
                id="institution"
                link="/contract-institution"
                fields={[
                    {name:"Nom", value: contractState.institution.name},
                    {name:"Adresse", value: contractState.institution.address},
                    {name:"Code postal", value: contractState.institution.postalCode},
                    {name:"Ville", value: contractState.institution.city},
                    {name:"Personne à joindre aux écuries", value: contractState.institution.contactName},
                    {name:"Téléphone", value: contractState.institution.contactNumber},
                ]}
            />
            <hr/>

            <Link to="/contract-visit-frequency" style={{ textDecoration: "none" }}>
                <h4 id="visitFrequency" className="customBlack">Fréquence de venue du cavalier</h4>
                <div className='costomField'>
                    <img className="chevron" src={chevron} alt="chevron" />
                    <div className="inuseText">
                        <p>
                            {contractState.visit_frequency===1 && "1 fois / semaine"}
                            {contractState.visit_frequency===2 && "2 fois / semaine"}
                            {contractState.visit_frequency===3 && "3 à 4 fois / semaine"}
                            {contractState.visit_frequency===5 && "5 à 7 fois / semaine"}
                        </p>
                    </div>
                </div>
                
            </Link>
            <hr/>

            <Fields
                title="Venue du cavalier"
                id="visitDays"
                link="/contract-visit-days"
                fields={[
                    {name:"Jours", value: stringVisitDays()},
                ]}
            />
            <SlidingButton 
                SlidingButtonText="OU je ne connais pas à l'avance mes jours de venue"
                SlidingButtonID="knowledgeVisitDaysButton"
                checked={!contractState.knowledgeOfVisitDays}
                onChange={(evt) => {setContractState({ ...contractState, knowledgeOfVisitDays: !contractState.knowledgeOfVisitDays})}}
            />
            <p><br/></p>
            <Link to="/contract-communication-day" style={{ textDecoration: "none" }}>
                <p id="communicationDay">Les différentes parties s'engagent à communiquer entre eux sur leurs jours de venue auprès du cheval au maximum tous les <strong>{contractState.communicationDay}</strong> d'une semaine pour la suivante.</p>
            </Link>
            <hr/>

            <Fields
                title="Règlement"
                id="payment"
                link="/contract-payment"
                fields={[
                    {name:"Tarif", value: contractState.payment.price ? contractState.payment.price+"€" : "" },
                    {name:"Date de règlement", value: contractState.payment.date ? contractState.payment.date+" du mois" : ""},
                    {name:"Mode de règlement", value: contractState.payment.wayOfPayment },
                ]}
            />
            <hr/>

            <h4 id="goodFather">Notion de "bon père de famille"</h4>
            <Link to="/contract-good-father" style={{ textDecoration: "none" }}>
                <div className='costomField'>
                    <img className="chevron" src={chevron} alt="chevron" />
                    <p>Qu'est-ce que c'est ?</p>
                </div>
            </Link>
            <hr/>

            <h4 id="insurance" className="customBlack">Assurance</h4>
            <Checkbox 
                CheckboxText='Civile'
                checked={contractState.insurances.civile}
                onChange={(evt) => {setContractState({ ...contractState, insurances: { ...contractState.insurances, civile: !contractState.insurances.civile }})}}
            />
            <div className="NextArrowBlock">
                <div className="NextArrowButton2">
                    <svg width="1em" height="1em" viewBox="0 0 16 16" className="bi bi-chevron-right" fillRule="#5d5aa4" xmlns="http://www.w3.org/2000/svg">
                        <path fillRule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"/>
                    </svg>
                    <button onClick={e => history.push('/contract-insurance')}>
                        Voir plus
                    </button>
                </div>
            </div>
            <hr/>

            <Fields
                title="Extension de garantie"
                id="warrantyExtension"
                link="/contract-warranty-extension"
                fields={[
                    {name:"Depuis le", value: contractState.warrantyExtension},
                ]}
            />
            <hr/>

            <h4 id="disuse">En cas d'inutilisation</h4>
            <div className='costomField'>
                <Link to="/contract-disuse" style={{ textDecoration: "none" }}>
                    <img className="chevron" src={chevron} alt="chevron" />
                    <div className="inuseText">
                        <p>Si le cheval est momentanément inutilisable il est convenu que l'utilisateur ne sera tenu de participer aux frais de pension et annexes, que dans la limite de <strong>{contractState.disuse.freesDate ? contractState.disuse.freesDate : "__"}</strong> jours.</p>
                        <p>Passé ce délai, si le cheval n'est pas rétabli, l'utilsateur pourra rompre le présent contrat avec un préavis de <strong>{contractState.disuse.preavisDate ? contractState.disuse.preavisDate : "__"}</strong> jours en :</p>
                        <ul><li>le stipulant oralement au propriétaire ;</li>
                        <li>envoyant une LRAR.</li></ul>
                    </div>
                </Link>
            </div>
            
            <hr/>

            <h4>Sellerie</h4>
            <p>L'utilisateur bénéficiera d'une place dans la sellerie des peropriétaires aux mêmes consitions que les propriétaires.</p>
            <SlidingButton 
                SlidingButtonText="Mise à disposition d'une selle par le propriétaire"
                SlidingButtonID="sellerieButton"
                checked={contractState.sellerie}
                onChange={(evt) => {setContractState({ ...contractState, sellerie: !contractState.sellerie})}}
            />
            <hr/>

            <h4 id="period">Durée de contrat</h4>
            <div className='costomField'>
                <Link to="/contract-period" style={{ textDecoration: "none" }}>
                    <img className="chevron" src={chevron} alt="chevron" />
                    <div className="inuseText">
                        <p>Le contrat est conclu pour une période d'un an et se renouvellera par tacite reconduction, à défaut de résiliation par l'une ou l'autre des parties :</p>
                        <ul><li>par LRAR</li>
                        <li>par courrier écrit et daté remis au propriétaire avec un préavis de <strong>{contractState.period ? contractState.period : "__"}</strong> mois.</li></ul>
                    </div>
                </Link>
            </div>
            
            <hr/>

            <h4>Domicile et compétences</h4>
            <p>Les parties font élection de domicile au lieu de leur résidence et attribuent compétence territoriale au tribunal du siège du centre équestre.</p>
            <hr/>

            <h4>Complément</h4>
            <textarea className="customtextarea" placeholder='Ajoutez quelques mots...' value={contractState.complement} onChange={(evt) => {setContractState({ ...contractState, complement: evt.currentTarget.value})}}>
            </textarea>
            <hr/>

            <FloatingButton 
                btnName={'APERCU'} 
                onClick={e => history.push("/contract-list")}
            />
        </div>
    </>
    )
}

export default Contract;