import React, { useState, useContext, useEffect } from 'react';
import { HashLink } from 'react-router-hash-link';
import { ContractContext } from "../context/ContractContext";
import "./Contract.css";
import Header from "../Header_footer/Header";
import FloatingButton from '../common/FloatingButton'

const ContractDisuse = () => {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const { contractState, setContractState } = useContext(ContractContext);
    const disuse = contractState.disuse;

    const [freesDate, setFreesDate] = useState(disuse.freesDate);
    const [preavisDate, setPreavisDate] = useState(disuse.preavisDate);

    const updateContract = () => {
        const newDisuse = {
            freesDate,
            preavisDate,
        };
        setContractState({ ...contractState, disuse: newDisuse });
    }

    return(
        <>
            <Header className="header" title="EN CAS D'INUTILISATION" hash='/contract#disuse' />
            <div className='contract_page'>
                <h4>Participation :</h4>
                <p>Si le cheval est momentanément inutilisable il est convenu que l'utilisateur ne sera tenu de participer aux frais de pension et annexes, que dans la limite de jours pré-définis :</p>
                <h4>Combien ?</h4>
                <input 
                    min="0" 
                    max="200"
                    className='customInput'
                    type='number'
                    value={freesDate}
                    onChange={(evt) => {setFreesDate(evt.currentTarget.value)}}
                />
                <hr/>

                <h4>Délais de préavis :</h4>
                <p>Passé ce délai, si le cheval n'est pas rétabli, l'utilsateur pourra rompre le présent contrat avec un préavis d'un certain nombre de jours :</p>    
                <h4>Combien ?</h4>
                <input 
                    min="0" 
                    max="200"
                    className='customInput'
                    type='number'
                    value={preavisDate}
                    onChange={(evt) => {setPreavisDate(evt.currentTarget.value)}}
                />
                <hr/>

                <p>Dénonciation du contrat :</p>
                <ul><li>Informer oralement le propriétaire</li>
                <li>Envoyer une LRAR</li></ul>

                <HashLink to="/contract#disuse" style={{ textDecoration: "none" }}>
                    <FloatingButton btnName='Valider' onClick={updateContract}/>
                </HashLink>
            </div>
        </>
    )
}


export default ContractDisuse;