import React, { useState } from 'react';
import "./organise.css";
import Header from "../Header_footer/Header";
import ListCalendars from "./ListCalendars";
import NewCalendar from "./NewCalendar";
import SlidingButton2 from "../common/SlidingButton2";
import Menu from '../Menu'
import { Link } from "react-router-dom";


const Organise = () => {

    const [button, setButton] = useState(false);
    const token = localStorage.token;

    return(
        <>
              <Header className="header" title="Organiser" />
           {token === undefined ?
            <div className="postHorse_page">
          <p style={{'text-align' : 'center'}}>Vous devez être connecté(e) pour accéder à cette fonctionnalité.</p>
          <div className='login' >
            <Link to='/login' style={{ textDecoration: "none" }}>
                <button type='button' id='loginBtn' > Se connecter </button>
            </Link>
          </div>
          <p style={{'text-align' : 'center'}}>Pas encore de compte ? Créer un compte gratuitement</p>
          <div className='create' >
            <Link to='/register' style={{ textDecoration: "none" }}>
                <button type='button' id='createBtn' > Créer un compte </button>
            </Link>
          </div>
        </div>

      :

            <div className='organise_page'>
                <div className="mainCalendarButton">
                    <SlidingButton2
                        SlidingButtonID="newCalendarButton"
                        checked={button}
                        onChange={(evt) => {setButton(!button)}}
                    />
                </div>
                {button ? <NewCalendar/> : <ListCalendars/>}
            </div>

           }
           <Menu />
        </>
    );
};


export default Organise;
