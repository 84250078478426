import React, { useState, useContext } from 'react';
import { HashLink } from 'react-router-hash-link';
import { ContractContext } from "../context/ContractContext";
import "./Contract.css";
import Header from "../Header_footer/Header";
import SelectButton from '../common/SelectButton'
import FloatingButton from '../common/FloatingButton'

const ContractCommunicationDay = () => {

    const { contractState, setContractState } = useContext(ContractContext);
    const communicationDay = contractState.communicationDay;

    const [monday, setMonday] = useState(communicationDay === "lundi");
    const [tuesday, setTuesday] = useState(communicationDay === "mardi");
    const [wednesday, setWednesday] = useState(communicationDay === "mercredi");
    const [thursday, setThursday] = useState(communicationDay === "jeudi");
    const [friday, setFriday] = useState(communicationDay === "vendredi");
    const [saturday, setSaturday] = useState(communicationDay === "samedi");
    const [sunday, setSunday] = useState(communicationDay === "dimanche");

    const updateContract = () => {
        if (tuesday) {
            setContractState({ ...contractState, communicationDay: "mardi" });
        } else if (wednesday) {
            setContractState({ ...contractState, communicationDay: "mercredi" });
        } else if (thursday) {
            setContractState({ ...contractState, communicationDay: "jeudi" });
        } else if (friday) {
            setContractState({ ...contractState, communicationDay: "vendredi" });
        } else if (saturday) {
            setContractState({ ...contractState, communicationDay: "samedi" });
        } else if (sunday) {
            setContractState({ ...contractState, communicationDay: "dimanche" });
        } else {
            setContractState({ ...contractState, communicationDay: "lundi" });;
        }
    };

    const changeDay = (evt) => {
        const day = evt.currentTarget.id;

        if (day === "lundi") {
            setMonday(true);
            setTuesday(false);
            setWednesday(false);
            setThursday(false);
            setFriday(false);
            setSaturday(false);
            setSunday(false);
        } else if (day === "mardi") {
            setMonday(false);
            setTuesday(true);
            setWednesday(false);
            setThursday(false);
            setFriday(false);
            setSaturday(false);
            setSunday(false);
        } else if (day === "mercredi") {
            setMonday(false);
            setTuesday(false);
            setWednesday(true);
            setThursday(false);
            setFriday(false);
            setSaturday(false);
            setSunday(false);
        } else if (day === "jeudi") {
            setMonday(false);
            setTuesday(false);
            setWednesday(false);
            setThursday(true);
            setFriday(false);
            setSaturday(false);
            setSunday(false);
        } else if (day === "vendredi") {
            setMonday(false);
            setTuesday(false);
            setWednesday(false);
            setThursday(false);
            setFriday(true);
            setSaturday(false);
            setSunday(false);
        } else if (day === "samedi") {
            setMonday(false);
            setTuesday(false);
            setWednesday(false);
            setThursday(false);
            setFriday(false);
            setSaturday(true);
            setSunday(false);
        } else if (day === "dimanche") {
            setMonday(false);
            setTuesday(false);
            setWednesday(false);
            setThursday(false);
            setFriday(false);
            setSaturday(false);
            setSunday(true);
        }
    };

    return(
        <>
            <Header className="header" title="ENGAGEMENT DE COMMUNICATION" hash='/contract#communicationDay' />
            <div className='contract_page'>

                <h4>Les différentes parties s'engagent à communiquer entre eux sur leurs jours de venue auprès du cheval au plus tard pour la semaine suivante le :</h4>

                <div className="hebergtList">
                    <SelectButton
                        radioSelBtnId="lundi"
                        radioSelBtnValue="Lundi"
                        radioSelBtnName="communicationDaysButtons"
                        onChange={changeDay}
                        checked={monday}
                    />
                    <SelectButton
                        radioSelBtnId="mardi"
                        radioSelBtnValue="Mardi"
                        radioSelBtnName="communicationDaysButtons"
                        onChange={changeDay}
                        checked={tuesday}
                    />
                    <SelectButton
                        radioSelBtnId="mercredi"
                        radioSelBtnValue="Mercredi"
                        radioSelBtnName="communicationDaysButtons"
                        onChange={changeDay}
                        checked={wednesday}
                    />
                    <SelectButton
                        radioSelBtnId="jeudi"
                        radioSelBtnValue="Jeudi"
                        radioSelBtnName="communicationDaysButtons"
                        onChange={changeDay}
                        checked={thursday}
                    />
                    <SelectButton
                        radioSelBtnId="vendredi"
                        radioSelBtnValue="Vendredi"
                        radioSelBtnName="communicationDaysButtons"
                        onChange={changeDay}
                        checked={friday}
                    />
                    <SelectButton
                        radioSelBtnId="samedi"
                        radioSelBtnValue="Samedi"
                        radioSelBtnName="communicationDaysButtons"
                        onChange={changeDay}
                        checked={saturday}
                    />
                    <SelectButton
                        radioSelBtnId="dimanche"
                        radioSelBtnValue="Dimanche"
                        radioSelBtnName="communicationDaysButtons"
                        onChange={changeDay}
                        checked={sunday}
                    />
                </div>

                <HashLink to="/contract#communicationDay" style={{ textDecoration: "none" }}>
                    <FloatingButton btnName='Valider' onClick={updateContract}/>
                </HashLink>
            </div>
        </>
    )
}

export default ContractCommunicationDay;