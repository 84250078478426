import React from "react";
import "./workIntensity.css";


const WorkIntensity = ({ intensity }) => {

    const cerleClass = (cercleNumber) => {
        if (cercleNumber > intensity) {
            return ("cercle cercle" + cercleNumber);
        } else if (cercleNumber === intensity) {
            return ("cercle cercle" + cercleNumber + " cercleSelected");
        } else {
            return ("cercle cercle" + cercleNumber + " cercleActivate");
        }
    };

    const lineClass = (lineNumber) => {
        if (lineNumber <= intensity) {
            return "line lineActivate";
        } else {
            return "line";
        }
    };

  return (
    <div className="workIntensitySection">
        <div className={cerleClass(1)}>{intensity===1 && 1}</div>
        <div className={lineClass(2)}></div>
        <div className={cerleClass(2)}>{intensity===2 && 2}</div>
        <div className={lineClass(3)}></div>
        <div className={cerleClass(3)}>{intensity===3 && 3}</div>
        <div className={lineClass(4)}></div>
        <div className={cerleClass(4)}>{intensity===4 && 4}</div>
        <div className={lineClass(5)}></div>
        <div className={cerleClass(5)}>{intensity===5 && 5}</div>
        <div className={lineClass(6)}></div>
        <div className={cerleClass(6)}>{intensity===6 && 6}</div>
    </div>
  );
}

export default WorkIntensity;
