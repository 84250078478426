import React from "react";
import "./slidingButton2.css";


const SlidingButton2 = (props) =>{
  return (
      <div className="slidingButton-check3">
        
        <div className="slidingButton-check3">
          <input
            type="checkbox"
            className="custom-control-input3"
            id={props.SlidingButtonID}
            name={props.SlidingButtonID}
            onClick={props.onClick}
            onChange={props.onChange}
            checked={props.checked}
          />
          <label
            className="custom-control-label"
            htmlFor={props.SlidingButtonID}
          ></label>
          <label
            className="test38"
            htmlFor={props.SlidingButtonID}
          >Calendriers</label>
          <label
            className="test39"
            htmlFor={props.SlidingButtonID}
          >Nouveau</label>
        </div>
      </div>
  );
}

export default SlidingButton2;
