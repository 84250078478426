import React, { useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import Header from '../Header_footer/Header'
import './calculate.css'
import Menu from '../Menu'

const CalculateOthers = () => {

    const history = useHistory();
    const location = useLocation();
    const state = location.state;

    const initRiderPrices = () => {
        var result = [];
        for (var rider of state.riders) {
            var value = rider.others;
            if (value === undefined) {
                if (rider.id === 0) {
                    value = "100";
                } else {
                    value = "0";
                }
            } else {
                value = String(value);
            }
            result.push({ id: rider.id, name: rider.name, value });
        }
        return result;
    };

    const initOthers = () => {
        if (state.others === undefined) {
            return [{ name: "", price: "" }];
        } else {
            return state.others.map(({ name, price }) => ({ name, price: String(price) }));
        }
    };

    const [others, setOthers] = useState(initOthers());
    //const [price, setPrice] = useState(state.pension === undefined ? "" : String(state.pension));
    const [riderPrices, setRiderPrices] = useState(initRiderPrices());

    const changeInputValue = (id, value) => {
        var newRiderPrices = [...riderPrices];
        newRiderPrices[id] = { ...riderPrices[id], value };
        setRiderPrices(newRiderPrices);
    };

    const nextPage = (evt) => {
        if (summationIs100()) {
            var newRiders = [];
            for (var rider of state.riders) {
                newRiders.push({ ...rider, others: Number(riderPrices[rider.id].value) });
            }
            const newState = { ...state, others: others.map(({ name, price }) => ({ name, price: Number(price) })), riders: newRiders };
            history.push("/calculate/result", newState);
        }
    };

    const lastPage = (evt) => {
        var newRiders = [];
        for (var rider of state.riders) {
            newRiders.push({ ...rider, others: Number(riderPrices[rider.id].value) });
        }
        const newState = { ...state, others: others.map(({ name, price }) => ({ name, price: Number(price) })), riders: newRiders };
        history.push("/calculate/veterinaire", newState);
    };

    const summationIs100 = () => {
        var summation = 0;
        for (var riderPrice of riderPrices) {
            summation += Number(riderPrice.value);
        }
        return summation === 100;
    };

    const changeLabel = (index, name) => {
        var newOthers = [...others];
        newOthers[index] = { name, price: newOthers[index].price }
        setOthers(newOthers);
    };

    const changePrice = (index, price) => {
        var newOthers = [...others];
        newOthers[index] = { name: newOthers[index].name, price }
        setOthers(newOthers);
    };

    const addOther = () => {
        setOthers([...others, { name: "", price: "" }]);
    };


    return (
        <>
            <Header className="header" title="NOMBRE DE CAVALIERS" path='home' />
            <div className="calculate_page">
                <h5>Autres frais sur l'année</h5>

                {others.map(({ name, price }, index) => (
                    <div key={index}>
                        <p>Label :</p>
                        <input
                            className='classicInput2'
                            placeholder="Descriptif"
                            value={name}
                            onChange={evt => changeLabel(index, evt.currentTarget.value)}
                        />
                        <p>Tarif :</p>
                        <div className="eurosField">
                            <label htmlFor="eurosInput">€</label>
                            <input
                                type="number"
                                id="eurosInput"
                                value={price}
                                onChange={evt => changePrice(index, evt.currentTarget.value)}
                            />
                        </div>
                        <hr />
                    </div>
                ))}

                <div className="addButtonFrame">
                    <svg width="2em" height="2em" viewBox="0 0 16 16" className="bi bi-plus" xmlns="http://www.w3.org/2000/svg">
                        <path fillRule="evenodd" d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" />
                    </svg>
                    <button className="addButton" onClick={addOther}>Ajouter un poste de dépense </button>
                </div>
                <hr />

                <h5>Participation des cavaliers :</h5>
                {riderPrices.map(({ id, name, value }) => (
                    <div className="percentField" key={id}>
                        <label className="percentFieldLabel" htmlFor={"input_" + id}>{name}</label>
                        <div className="bar"></div>
                        <label className="percentFieldLabel2" htmlFor={"input_" + id}>%</label>
                        <input
                            type="number"
                            id={"input_" + id}
                            placeholder="%"
                            value={value}
                            onChange={evt => changeInputValue(id, evt.currentTarget.value)}
                        />
                    </div>
                ))}

                {!summationIs100() && <p className='error'>Attention : la somme des chiffres ne vaut pas 100 !</p>}

                <div className="finalButtons">
                    <div className="PrevArrowButton">
                        <svg width="1em" height="1em" viewBox="0 0 16 16" className="bi bi-chevron-left" fillRule="#5d5aa4" xmlns="http://www.w3.org/2000/svg">
                            <path fillRule="evenodd" d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z" />
                        </svg>
                        <button onClick={lastPage}>
                            Précédent
                        </button>
                    </div>
                    <div className="NextArrowButton">
                        <svg width="1em" height="1em" viewBox="0 0 16 16" className="bi bi-chevron-right" fillRule="#5d5aa4" xmlns="http://www.w3.org/2000/svg">
                            <path fillRule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z" />
                        </svg>
                        <button onClick={nextPage}>
                            Suivant
                        </button>
                    </div>
                </div>
            </div>
            <Menu />
        </>
    )
}

export default CalculateOthers;