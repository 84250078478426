import React from "react";
import SelectButton from "../common/SelectButton";
import './common_section.css'

const Scuring = (props) => {
  return (
      <div className="scuring">

        <div className="scuringList">
          <SelectButton
              radioSelBtnId="Poney Club"
              radioSelBtnValue="Poney club"
              radioSelBtnName="scuringType"
              onClick={props.onClick}
              checked={props.checked==='Poney club'}
          />
          <SelectButton
              radioSelBtnId="Centre équestre"
              radioSelBtnValue="Centre équestre"
              radioSelBtnName="scuringType"
              onClick={props.onClick}
              checked={props.checked==='Centre équestre'}
          />
          <SelectButton
              radioSelBtnId="Ecurie de propiétaire"
              radioSelBtnValue="Ecurie de propiétaire"
              radioSelBtnName="scuringType"
              onClick={props.onClick}
              checked={props.checked==='Ecurie de propiétaire'}
          />
          <SelectButton
              radioSelBtnId="Chez un particulier"
              radioSelBtnValue="Chez un particulier"
              radioSelBtnName="scuringType"
              onClick={props.onClick}
              checked={props.checked==='Chez un particulier'}
          />
          <SelectButton
              radioSelBtnId="Elevage"
              radioSelBtnValue="Elevage"
              radioSelBtnName="scuringType"
              onClick={props.onClick}
              checked={props.checked==='Elevage'}
          />
          <SelectButton
              radioSelBtnId="Autre"
              radioSelBtnValue="Autre"
              radioSelBtnName="scuringType"
              onClick={props.onClick}
              checked={props.checked==='Autre'}
          />
        </div>
      </div>
  );
};

export default Scuring;
