import React, { useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import Header from '../Header_footer/Header'
import './calculate.css'
import Menu from '../Menu'

const CalculateRiderNames = () => {

    const history = useHistory();
    const location = useLocation();
    const state = location.state;

    const initRiderNames = () => {
        var result = [];
        for (var rider of state.riders) {
            var value = rider.name;
            if (value === undefined) {
                value = "";
            }
            if (rider.id === 0) {
                result.push({ id: rider.id, placeholder: "Propriétaire", value });
            } else {
                result.push({ id: rider.id, placeholder: "Cavalier " + (rider.id + 1), value });
            }
        }
        return result;
    };

    const [riderNames, setRiderNames] = useState(initRiderNames());

    const changeInputValue = (id, value) => {
        var newRiderNames = [...riderNames];
        newRiderNames[id] = { ...riderNames[id], value };
        setRiderNames(newRiderNames);
    };

    const nextPage = (evt) => {
        var newRiders = [];
        for (var rider of state.riders) {
            newRiders.push({ ...rider, name: riderNames[rider.id].value });
        }
        const newState = { ...state, riders: newRiders };
        history.push("/calculate/pension", newState);
    };

    const lastPage = (evt) => {
        history.push("/calculate/rider-number");
    };


    return (
        <>
            <Header className="header" title="NOMBRE DE CAVALIERS" path='home' />
            <div className="calculate_page">
                <h4 className="calculate_title">
                    Prénoms des cavaliers :
                </h4>

                {riderNames.map(riderName => (
                    <input
                        key={riderName.id}
                        className='classicInput'
                        placeholder={riderName.placeholder}
                        value={riderName.value}
                        onChange={evt => changeInputValue(riderName.id, evt.currentTarget.value)}
                    />
                ))}

                <div className="finalButtons">
                    <div className="PrevArrowButton">
                        <svg width="1em" height="1em" viewBox="0 0 16 16" className="bi bi-chevron-left" fillRule="#5d5aa4" xmlns="http://www.w3.org/2000/svg">
                            <path fillRule="evenodd" d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z" />
                        </svg>
                        <button onClick={lastPage}>
                            Précédent
                        </button>
                    </div>
                    <div className="NextArrowButton">
                        <svg width="1em" height="1em" viewBox="0 0 16 16" className="bi bi-chevron-right" fillRule="#5d5aa4" xmlns="http://www.w3.org/2000/svg">
                            <path fillRule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z" />
                        </svg>
                        <button onClick={nextPage}>
                            Suivant
                        </button>
                    </div>
                </div>

            </div>
            <Menu />
        </>
    )
}

export default CalculateRiderNames;