import React, { useState } from "react";
import { useHistory } from 'react-router-dom';
import "./landing.css";
import logo from "../SVG-icons/cavalcloud-logo.png";
import Axios from "axios";
import ModalError from "../common/ModalError";

const { REACT_APP_API_URL } = process.env;


const ForgetPassword = () => {

    const history = useHistory();

    const [firstname, setFirstname] = useState("");
    const [lastname, setLastname] = useState("");
    const [email, setEmail] = useState("");

    const [modalMessage, setModalMessage] = useState("");

    const sendMail = () => {
        Axios
        .post(`${REACT_APP_API_URL}/api/users/forget-password`, { firstname, lastname, email })
        .then(res => {
            setModalMessage(res.data.message);
            setTimeout(() => history.push('/'), 4000);
        })
        .catch(res => {
            setModalMessage(res.response.data.error);
            setTimeout(() => setModalMessage(""), 4000);
        });
    }
  

    return (
        <div className="register_page">
            <ModalError show={modalMessage ? true : false} onHide={e => setModalMessage("")} message={modalMessage} />

            <img className="register_logo" src={logo} alt="logo" />
            <div>
                <form className="register_forms">
                    <label>
                        <input
                            required
                            className="register_input_text"
                            type="text"
                            placeholder=" Nom"
                            value={lastname}
                            onChange={e => setLastname(e.target.value)}
                        />
                    </label>
                </form>
                <form className="register_forms">
                    <label>
                        <input
                            required
                            className="register_input_text"
                            type="text"
                            placeholder=" Prénom"
                            value={firstname}
                            onChange={e => setFirstname(e.target.value)}
                        />
                    </label>
                </form>
                <form className="register_forms">
                    <label>
                        <input
                            className="register_input_text"
                            type="email"
                            placeholder=" Adresse mail"
                            required
                            value={email}
                            onChange={e => setEmail(e.target.value)}
                        />
                    </label>
                </form>
            </div>
            <button className="register_button" onClick={sendMail}>
                Envoyer par mail
            </button>
        </div>
    );
};
export default ForgetPassword;
