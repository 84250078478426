import React, { useState, useContext } from 'react';
import { HashLink } from 'react-router-hash-link';
import { ContractContext } from "../context/ContractContext";
import "./Contract.css";
import Header from "../Header_footer/Header";
import FloatingButton from '../common/FloatingButton'
import Checkbox from '../common/Checkbox'

const ContractDisc = (props) => {

    const { contractState, setContractState } = useContext(ContractContext);
    const horseFields = contractState.horseFields;

    const [obstacle, setObstacle] = useState(horseFields.obstacle);
    const [dressage, setDressage] = useState(horseFields.dressage);
    const [CCE, setCCE] = useState(horseFields.CCE);
    const [TREC, setTREC] = useState(horseFields.TREC);
    const [balade, setBalade] = useState(horseFields.balade);
    const [ethologie, setEthologie] = useState(horseFields.ethologie);
    const [hunter, setHunter] = useState(horseFields.hunter);
    const [horseBall, setHorseBall] = useState(horseFields.horseBall);
    const [ponyGames, setPonyGames] = useState(horseFields.ponyGames);
    const [reiningWestern, setReiningWestern] = useState(horseFields.reiningWestern);
    const [endurance, setEndurance] = useState(horseFields.endurance);
    const [attelage, setAttelage] = useState(horseFields.attelage);
    const [voltige, setVoltige] = useState(horseFields.voltige);
    const [disciplinesCulturelles, setDisciplinesCulturelles] = useState(horseFields.disciplinesCulturelles);


    const updateContract = () => {
        const newHorseFields = {
            obstacle,
            dressage,
            CCE,
            TREC,
            balade,
            ethologie,
            hunter,
            horseBall,
            ponyGames,
            reiningWestern,
            endurance,
            attelage,
            voltige,
            disciplinesCulturelles,
        };
        setContractState({ ...contractState, horseFields: newHorseFields });
    }

    return (
        <>
            <Header className="header" title="DISCIPLINES" hash='/contract#disciplines' />
            <div className='contract_page'>
                <h4>Disciplines pratiquées :</h4>

                <Checkbox
                    CheckboxText='Obstacle'
                    checked={obstacle}
                    onChange={(evt) => {setObstacle(!obstacle)}}/>
                <Checkbox 
                    CheckboxText='Dressage'
                    checked={dressage}
                    onChange={(evt) => {setDressage(!dressage)}}/>
                <Checkbox 
                    CheckboxText='CCE'
                    checked={CCE}
                    onChange={(evt) => {setCCE(!CCE)}}/>
                <Checkbox 
                    CheckboxText='TREC - Equifun'
                    checked={TREC}
                    onChange={(evt) => {setTREC(!TREC)}}/>
                <Checkbox 
                    CheckboxText='Balade - Randonnée'
                    checked={balade}
                    onChange={(evt) => {setBalade(!balade)}}/>
                <Checkbox 
                    CheckboxText='Ethologie - Equifeel'
                    checked={ethologie}
                    onChange={(evt) => {setEthologie(!ethologie)}}/>
                <Checkbox 
                    CheckboxText='Hunter'
                    checked={hunter}
                    onChange={(evt) => {setHunter(!hunter)}}/>
                <Checkbox 
                    CheckboxText='Horse-Ball'
                    checked={horseBall}
                    onChange={(evt) => {setHorseBall(!horseBall)}}/>
                <Checkbox 
                    CheckboxText='Pony-Games'
                    checked={ponyGames}
                    onChange={(evt) => {setPonyGames(!ponyGames)}}/>
                <Checkbox 
                    CheckboxText='Reining-Western'
                    checked={reiningWestern}
                    onChange={(evt) => {setReiningWestern(!reiningWestern)}}/>
                <Checkbox 
                    CheckboxText='Endurance'
                    checked={endurance}
                    onChange={(evt) => {setEndurance(!endurance)}}/>
                <Checkbox 
                    CheckboxText='Attelage'
                    checked={attelage}
                    onChange={(evt) => {setAttelage(!attelage)}}/>
                <Checkbox 
                    CheckboxText='Voltige'
                    checked={voltige}
                    onChange={(evt) => {setVoltige(!voltige)}}/>
                <Checkbox 
                    CheckboxText='Disciplines culturelles'
                    checked={disciplinesCulturelles}
                    onChange={(evt) => {setDisciplinesCulturelles(!disciplinesCulturelles)}}/>
                
                <HashLink to="/contract#disciplines" style={{ textDecoration: "none" }}>
                    <FloatingButton btnName='Valider' onClick={updateContract}/>
                </HashLink>
                
            </div>
        </>
    );
}


export default ContractDisc;