import React, { useState } from 'react';
import "./event.css";
import photo from "../../SVG-icons/photo.svg";
import edit from "../../SVG-icons/edit2.svg";
import trash from "../../SVG-icons/trash2.svg";


const PhotosSection = ({ imgFiles, setImgFiles, imgURLs, setImgURLs, imgToDelete, setImgToDelete }) => {
    
    const [renderAgain, setRenderAgain] = useState(false);

    if (renderAgain) {
        setRenderAgain(false);
    }

    const ImgComesFromServer = (fileName) => {
        // Si le fichier est dans imgURLs mais pas dans imgFiles, il vient du serveur
        // Si le fichier est dans les 2 listes, il vient a été chargé en local
        for (var imgUrl of imgURLs) {
            if (imgUrl.fileName === fileName) {
                for (var imgFile of imgFiles) {
                    if (imgFile.name === fileName) {
                        return false;
                    }
                }
                return true;
            }
        }
        return false;
    };

    const filesAreDifferent = (fileNames) => {
        for (var i = 0; i < fileNames.length; i++) {
            for (var j = i+1; j < fileNames.length; j++) {
                if (fileNames[i] === fileNames[j]) {
                    return false;
                }
            }
        }
        return true;
    };

    const filesAreImgs = (files) => {
        for (var file of files) {
            if (file['type'].split('/')[0] !== 'image') {
                return false;
            }
            return true;
        }
    };

    const addImgURLs = (files) => {
        var newImgURLs = [...imgURLs];
        for (var file of files) {
            var reader  = new FileReader();
            //reader.fileName = file.name.slice(0, file.name.indexOf('.') + today.getTime() + file.name.slice(file.name.indexOf('.')));
            reader.fileName = file.name;
            reader.addEventListener("load", function () {
                newImgURLs.push({ fileName: this.fileName, url: this.result })
                setImgURLs(newImgURLs);
                setRenderAgain(true);
            }, false);
            reader.readAsDataURL(file);
        }
    };
    
    // Pas plus de 5 images et toutes les images doivent avoir un nom différent
    const selectImgs = (e) => {
        // Changement de format : files est un array (contrairement à fileList) et le nom du fichier a changé
        var fileList = e.target.files;
        var files = [];
        console.log(fileList);
        for (var i = 0; i < fileList.length; i++) {
            files.push(fileList.item(i));
        }

        var newFileNames = [ ...imgURLs.map(img => img.fileName), ...files.map(file => file.name)];
        if (filesAreImgs(files) && newFileNames.length < 6 && filesAreDifferent(newFileNames)) {
            setImgFiles([ ...imgFiles, ...files]);
            addImgURLs(files);
        } else {
            console.log("Error : there are 2 files with the same name or there are more than 5 files or files are not images !")
        }
    };

    const deleteImg = (fileName) => {
        // Suppression de l'img sur le serveur plus tard grâce à imgToDelete
        if (ImgComesFromServer(fileName)) {
            setImgToDelete([... imgToDelete, fileName]);
        }

        // Suppression de l'img en local
        for (var imgFileIndex in imgFiles) {
            if (imgFiles[imgFileIndex].name === fileName) {
                imgFiles.splice(imgFileIndex, 1);
                break;
            }
        }
        for (var imgURLIndex in imgURLs) {
            if (imgURLs[imgURLIndex].fileName === fileName) {
                imgURLs.splice(imgFileIndex, 1);
                break;
            }
        }
        setImgFiles(imgFiles);
        setImgURLs(imgURLs);
        setRenderAgain(true);
    };

    return(

        <div className="allPhotos">
            <div className="addPhoto">
                <input type="file" multiple onChange={selectImgs} />
                <div className="photoFrame">
                    <img src={photo} alt="photo icon" />
                </div>
                <div className="editFrame">
                    <img className="edit_icon" src={edit} alt="edit icon" />
                </div>
            </div>

            {imgURLs.map(({ fileName, url }) => (
                <div className="photos" key={fileName}>
                    <img className="newPhotos" src={url} alt='img'/>
                    <button className="deleteFrame" onClick={evt => deleteImg(fileName)}>
                        <img className="edit_icon" src={trash} alt="trash icon" />
                    </button>
                </div>
            ))}
        </div>
      
    );
};


export default PhotosSection;