import React, { useState, useContext, useEffect } from "react";
import Carousel from "@brainhubeu/react-carousel";
import "@brainhubeu/react-carousel/lib/style.css";
import Axios from "axios";
import "./postHorse.css";
import Header from "../Header_footer/Header";
import { Link, Redirect } from "react-router-dom";
import RangeButton from "../common/RangeButton";
import SelectButton from "../common/SelectButton";
import SlidingButton from "../common/SlidingButton";
import Scuring from "../common_section/Scuring";
import Localisation2 from "../common_section/Localisation2";
import usePosition from "../common_section/usePosition";
import Checkbox from '../common/Checkbox'
import Pension from "../common_section/Pension";
import BudgetMensuel from "../common_section/BudgetMensuel";
import IdealRider from "../common_section/IdealRider";
import FloatingButton from "../common/FloatingButton";
import Competition from "../common_section/Competition";
import HebergementHorse from "../common_section/HebergementHorse";
import { HorseContext } from "../context/HorseContext";
import ModalPost from "../common/ModalPost";
import Menu from '../Menu';
import { useHistory } from "react-router-dom";

const { REACT_APP_API_URL } = process.env;

const PostHorse = () => {
  let horse_Id;
  const history = useHistory();
  const navigateTo = () => history.push('/horse/result-annonce/'+horse_Id);
  // Authentification -> if not token (undefined), cannot acces to post hose
  const token = localStorage.token


  // Get Localisation
  const { latitude, longitude } = usePosition();

  // User Data storage:
  const [dataUser, setDataUser] = useState({
    user_lastname: "",
    user_firstname: "",
    user_email: "",
    user_avatar: '',
    user_phone: "",
    user_ID: ''
  })

  // Selection on perimeter for localisation :
  const [perimeter, setPerimeter] = useState(null);

  // Get horseProfile Context in order to get and set information about it
  const { horseProfile, setHorseProfile } = useContext(HorseContext);
  const [modalShow, setModalShow] = useState(false);
  const [home, setHome] = useState(false);
  /* const [HorseResultAnnonce,setResultAnnonce]= useState(false); */

  // Carousel
  const [imageCarousel, setImageCarousel] = useState({});
  const [useUrl, setUseUrl] = useState([]);

  const handleChange = (e) => {
    if (e.target.files[0]) {
      setImageCarousel(e.target.files[0]);
    }
  };

  const handleUpload = () => {
    var datas = new FormData();
    datas.append('image', imageCarousel, imageCarousel.name);
    Axios
        .post(`${REACT_APP_API_URL}/api/horses/post-img`, datas, { headers: { 'content-type': 'multipart/form-data', 'Authorization': 'Bearer ' + token } })
        .then(res => {
          const url = res.data.url;
          setUseUrl([...useUrl, url]);
          if (horseProfile.horse_photo1 === "") {
            setHorseProfile({ ...horseProfile, horse_photo1: url });
          } else if (horseProfile.horse_photo2 === "") {
            setHorseProfile({ ...horseProfile, horse_photo2: url });
          }else if (horseProfile.horse_photo3 === "") {
            setHorseProfile({ ...horseProfile, horse_photo3: url });
          }
          setImageCarousel({});
        })
        .catch(err => console.log(err));
  };

  // Get the location from reverse geocoding
  const getLocation = () => {
    Axios.get(
        `https://nominatim.openstreetmap.org/reverse?format=jsonv2&lat=${latitude}&lon=${longitude}`
    )
        .then((res) =>
            setHorseProfile({
              ...horseProfile,
              horse_postal: res.data.address.postcode,
            })
        )
        .catch((err) => console.log(err));
  };

  // Get full and set gps coordinates from postal code within horse Context
  const getCoordinatesfromPostalCode = (postalcode) => {
    Axios.get(
        `https://nominatim.openstreetmap.org/search?country=France&postalcode=${postalcode}&format=json`
    )
        .then((res) => {
          setHorseProfile({
            ...horseProfile,
            horse_long: res.data[0].lon,
            horse_lat: res.data[0].lat,
            horse_localisation: res.data[0].display_name,
            horse_geolocation: [res.data[0].lon, res.data[0].lat]
          });
        })
        .catch((err) => console.log(err));
  };

  const postDataHorse = () => {
    // Post Data
    Axios
        .post(`${REACT_APP_API_URL}/api/horses`, horseProfile, {
          headers: { 'Authorization': 'Bearer ' + token }
        })  .then((res) => {
      horse_Id=res.data.horse_ID;
      localStorage.removeItem("horseProfile");
    })
        .catch((err) => console.log(err));

    // Display modal before going back Home
    setModalShow(true);
    setTimeout(() => {navigateTo()}, 5000);
  };


  // Get user profil
  const getMyProfile = () => {
    Axios.get(`${REACT_APP_API_URL}/api/users/profile`, {
      headers: { 'Authorization': 'Bearer ' + token }
    })
        .then((res) => setDataUser(res.data))
        .catch((error) => console.log(error))
  }

  useEffect(() => {
    getMyProfile();
  }, []);

  useEffect(() => {
    getLocation();
  }, [latitude]);


  useEffect(() => {
    const json = JSON.stringify(horseProfile);
    localStorage.setItem("horseProfile", json);
  }, [horseProfile]);


  useEffect(() => {
    const json =localStorage.getItem("horseProfile");
    const savedhorse = JSON.parse(json);
    setHorseProfile({ ...horseProfile,savedhorse });
    if(savedhorse.horse_photo1!==''){  useUrl.push(savedhorse.horse_photo1)};
    if(savedhorse.horse_photo2!==''){  useUrl.push(savedhorse.horse_photo2)};
    if(savedhorse.horse_photo3!==''){  useUrl.push(savedhorse.horse_photo3)};
  }, []);

  return (
      <>
        {home ? <Redirect to="/home" /> : null}

        <Header className="header" title="Poster une annonce cheval" path='home' />

        {token === undefined ?
            <div className="postHorse_page">
              <p style={{ textAlign: 'center' }}>Vous devez être connecté(e) pour accéder à cette fonctionnalité.</p>
              <div className='login' >
                <Link to='/login' style={{ textDecoration: "none" }}>
                  <button type='button' id='loginBtn' > Se connecter </button>
                </Link>
              </div>
              <p style={{textAlign: 'center' }}>Pas encore de compte ? Créer un compte gratuitement</p>
              <div className='create' >
                <Link to='/register' style={{ textDecoration: "none" }}>
                  <button type='button' id='createBtn' > Créer un compte </button>
                </Link>
              </div>
            </div>

            :

            <div className="postHorse_page">
              <div className="postHorse_pres">
                <h4>Présentation :</h4>
                <form className="postHorse-form">
                  <label>
                    <input
                        className="postHorse_input"
                        type="text"
                        placeholder="Nom du cheval"
                        value={horseProfile.horse_name}
                        onChange={(event) =>
                            setHorseProfile({
                              ...horseProfile,
                              horse_name: event.target.value,

                            })
                        }
                    />
                  </label>
                </form>

                <div className="horse_age">
                  <h5> Age du cheval : {horseProfile.horse_age} ans</h5>
                  <div className="divRangeSpan">
                    <span>1 an</span>
                    <RangeButton
                        id="ageHorse"
                        min="1"
                        max="30"
                        step="0"
                        radioRangeBtnId="ageHorse"
                        value={horseProfile.horse_age}
                        onChange={(e) =>
                            setHorseProfile({
                              ...horseProfile,
                              horse_age: e.target.value,
                            })
                        }
                    />
                    <span>30 ans</span>
                  </div>
                </div>
                <hr />
                <div className="horse_size">
                  <h5> Taille : {horseProfile.horse_height} cm</h5>
                  <div className="divRangeSpan">
                    <span>80 cm</span>
                    <RangeButton
                        min="80"
                        max="210"
                        radioRangeBtnId="horseSize"
                        value={horseProfile.horse_height}
                        onChange={(e) =>
                            setHorseProfile({
                              ...horseProfile,
                              horse_height: e.target.value,
                            })
                        }
                    />
                    <span>210 cm</span>
                  </div>
                </div>
              </div>
              <hr />
              <h4>Photos de votre cheval</h4>
              <div className="postHorse-form">
                <Carousel dots itemWidth={330} itemHeight={200} centered offset={-9}>
                  {useUrl &&
                  useUrl.map((imgUrl, index) => (
                      <img key={index} src={imgUrl} alt="" />
                  ))}
                </Carousel>
              </div>
              <br />
              <input type="file" onChange={handleChange} />
              {
        imageCarousel && imageCarousel.name ?
              <button onClick={handleUpload} id="upload-button" >
                Valider la photo
              </button>
              : ''

}
              <hr />
              <div className="localisation_horse">
                <h5>Où se trouve le cheval ? </h5>
                <Localisation2
                    value={horseProfile.horse_postal}
                    getLocation={getLocation}
                    onChange={(e) => setHorseProfile({ ...horseProfile, horse_postal: e.target.value })}
                    definePerimeter={(e) => setPerimeter(e.target.value)}
                    perimeter={perimeter}
                />
                <button id="upload-button" onClick={() => {
                  getCoordinatesfromPostalCode(Number(horseProfile.horse_postal))
                }}>
                  Valider ma position
                </button>
                <div>
                  <p>{horseProfile.horse_localisation}</p>
                </div>
              </div>
              <hr />
              <div className="horse_temper">
                <h5> Tempérament :</h5>
                <div className="select_temper">
                  <SelectButton
                      radioSelBtnId="Calme"
                      radioSelBtnValue="Calme"
                      radioSelBtnName="temperHorse"
                      value={horseProfile.horse_temper}
                      onClick={(e) =>
                          setHorseProfile({
                            ...horseProfile,
                            horse_temper: e.target.value,

                          })
                      }
                      checked={horseProfile.horse_temper==="Calme"}
                  />

                  <SelectButton
                      radioSelBtnId="Dynamique"
                      radioSelBtnValue="Dynamique"
                      radioSelBtnName="temperHorse"
                      value={horseProfile.horse_temper}
                      onClick={(e) =>
                          setHorseProfile({
                            ...horseProfile,
                            horse_temper: e.target.value,
                          })
                      }
                      checked={horseProfile.horse_temper==="Dynamique"}
                  />

                  <SelectButton
                      radioSelBtnId="Speed"
                      radioSelBtnValue="Speed"
                      radioSelBtnName="temperHorse"
                      value={horseProfile.horse_temper}
                      onClick={(e) =>
                          setHorseProfile({
                            ...horseProfile,
                            horse_temper: e.target.value,
                          })
                      }
                      checked={horseProfile.horse_temper==="Speed"}
                  />

                  <SelectButton
                      radioSelBtnId="A canaliser"
                      radioSelBtnValue="A canaliser"
                      radioSelBtnName="temperHorse"
                      value={horseProfile.horse_temper}
                      onClick={(e) =>
                          setHorseProfile({
                            ...horseProfile,
                            horse_temper: e.target.value,
                          })
                      }
                      checked={horseProfile.horse_temper==="A canaliser"}
                  />
                </div>
              </div>
              <hr />
              <div className="horse_caracter">
                <h5> Caractère :</h5>
                <div className="select_caracter">
                  <SelectButton
                      radioSelBtnId="Affectueux"
                      radioSelBtnValue="Affectueux"
                      radioSelBtnName="caracterHorse"
                      onClick={(e) =>
                          setHorseProfile({
                            ...horseProfile,
                            horse_character: e.target.value,
                          })
                      }
                      checked={horseProfile.horse_character==="Affectueux"}
                  />

                  <SelectButton
                      radioSelBtnId="Froid"
                      radioSelBtnValue="Froid"
                      radioSelBtnName="caracterHorse"
                      onClick={(e) =>
                          setHorseProfile({
                            ...horseProfile,
                            horse_character: e.target.value,
                          })
                      }
                      checked={horseProfile.horse_character==="Froid"}
                  />

                  <SelectButton
                      radioSelBtnId="Joueur"
                      radioSelBtnValue="Joueur"
                      radioSelBtnName="caracterHorse"
                      onClick={(e) =>
                          setHorseProfile({
                            ...horseProfile,
                            horse_character: e.target.value,
                          })
                      }
                      checked={horseProfile.horse_character==="Joueur"}
                  />

                  <SelectButton
                      radioSelBtnId="Sensible"
                      radioSelBtnValue="Sensible"
                      radioSelBtnName="caracterHorse"
                      onClick={(e) =>
                          setHorseProfile({
                            ...horseProfile,
                            horse_character: e.target.value,
                          })
                      }
                      checked={horseProfile.horse_character==="Sensible"}
                  />
                </div>
              </div>
              <hr />
              <div className="horse_body">
                <h5> Physique :</h5>
                <div className="select_body">
                  <SelectButton
                      radioSelBtnValue="Fin"
                      radioSelBtnId={"Fin"}
                      radioSelBtnName="bodyHorse"
                      onClick={(e) =>
                          setHorseProfile({
                            ...horseProfile,
                            horse_body_type: e.target.value,
                          })
                      }
                      checked={horseProfile.horse_body_type==="Fin"}
                  />

                  <SelectButton
                      radioSelBtnValue={"Classique"}
                      radioSelBtnId={"Classique"}
                      radioSelBtnName="bodyHorse"
                      onClick={(e) =>
                          setHorseProfile({
                            ...horseProfile,
                            horse_body_type: e.target.value,
                          })
                      }
                      checked={horseProfile.horse_body_type==="Classique"}
                  />

                  <SelectButton
                      radioSelBtnValue={"Porteur"}
                      radioSelBtnId={"Porteur"}
                      radioSelBtnName="bodyHorse"
                      onClick={(e) =>
                          setHorseProfile({
                            ...horseProfile,
                            horse_body_type: e.target.value,
                          })
                      }
                      checked={horseProfile.horse_body_type==="Porteur"}
                  />

                  <SelectButton
                      radioSelBtnValue={"Lourd"}
                      radioSelBtnId={"Lourd"}
                      radioSelBtnName="bodyHorse"
                      onClick={(e) =>
                          setHorseProfile({
                            ...horseProfile,
                            horse_body_type: e.target.value,
                          })
                      }
                      checked={horseProfile.horse_body_type==="Lourd"}
                  />
                </div>
              </div>
              <hr />
              <h4>Type d'écurie </h4>
              <Scuring
                  scuringType={horseProfile.horse_location_type}
                  onClick={(e) =>
                      setHorseProfile({
                        ...horseProfile,
                        horse_location_type: e.target.value,
                      })
                  }
                  checked={horseProfile.horse_location_type}

              />
              <HebergementHorse
                  boxeType={horseProfile.horse_accomodation}
                  onClick={(e) =>
                      setHorseProfile({
                        ...horseProfile,
                        horse_accomodation: e.target.value,
                      })

                  }
                  checked={horseProfile.horse_accomodation}
              />
              <hr />
              <div className='structures'>
                <h4>Structure(s) disponible(s) </h4>
                <div className='structureList'>
                  <Checkbox
                      CheckboxText="Carrière"
                      CheckboxValue='Carriere'
                      onClick={() => setHorseProfile({
                        ...horseProfile,
                        horse_practice_structure:
                            { ...horseProfile.horse_practice_structure, Carriere: !horseProfile.horse_practice_structure.Carriere }
                      })}
                      checked={horseProfile.horse_practice_structure.Carriere===true} />
                  <Checkbox
                      CheckboxText="Manège couvert"
                      CheckboxValue='Manege_couvert'
                      onClick={() => setHorseProfile({
                        ...horseProfile,
                        horse_practice_structure:
                            { ...horseProfile.horse_practice_structure, Manege_couvert: !horseProfile.horse_practice_structure.Manege_couvert }
                      })}
                      checked={horseProfile.horse_practice_structure.Manege_couvert===true}
                  />
                  <Checkbox
                      CheckboxText="Rond de longe"
                      CheckboxValue='Rond_de_longe'
                      onClick={() => setHorseProfile({
                        ...horseProfile,
                        horse_practice_structure:
                            { ...horseProfile.horse_practice_structure, Rond_de_longe: !horseProfile.horse_practice_structure.Rond_de_longe }
                      })}
                      checked={horseProfile.horse_practice_structure.Rond_de_longe===true}
                  />
                  <Checkbox
                      CheckboxText="Piste de trotting"
                      CheckboxValue='Piste_de_trotting'
                      onClick={() => setHorseProfile({
                        ...horseProfile,
                        horse_practice_structure:
                            { ...horseProfile.horse_practice_structure, Piste_de_trotting: !horseProfile.horse_practice_structure.Piste_de_trotting }
                      })}
                      checked={horseProfile.horse_practice_structure.Piste_de_trotting===true}
                  />
                  <Checkbox
                      CheckboxText="Cross"
                      CheckboxValue='Cross'
                      onClick={() => setHorseProfile({
                        ...horseProfile,
                        horse_practice_structure:
                            { ...horseProfile.horse_practice_structure, Cross: !horseProfile.horse_practice_structure.Cross }
                      })}
                      checked={horseProfile.horse_practice_structure.Cross===true}
                  />
                  <Checkbox
                      CheckboxText="Cheminsde balade"
                      CheckboxValue='Chemins_de_balade'
                      onClick={() => setHorseProfile({
                        ...horseProfile,
                        horse_practice_structure:
                            { ...horseProfile.horse_practice_structure, Chemins_de_balade: !horseProfile.horse_practice_structure.Chemins_de_balade }
                      })}
                      checked={horseProfile.horse_practice_structure.Chemins_de_balade===true}
                  />
                  <Checkbox
                      CheckboxText="Champs"
                      CheckboxValue='Champs'
                      onClick={() => setHorseProfile({
                        ...horseProfile,
                        horse_practice_structure:
                            { ...horseProfile.horse_practice_structure, Champs: !horseProfile.horse_practice_structure.Champs }
                      })}
                      checked={horseProfile.horse_practice_structure.Champs===true}
                  />
                </div>
              </div>
              <hr />
              <div className="coaching">
                <h4>Coaching</h4>
                <div className="coaching">
                  <SlidingButton
                      SlidingButtonText="Sur place"
                      SlidingButtonID="coachSwitch"

                      onClick={() =>
                          setHorseProfile({
                            ...horseProfile,
                            horse_coaching_here: !horseProfile.horse_coaching_here,
                          })
                      }
                      checked={horseProfile.horse_coaching_here===true}
                  />
                  <SlidingButton
                      SlidingButtonText="Intervenant exterieur"
                      SlidingButtonID="extCoachSwitch"
                      onClick={() =>
                          setHorseProfile({
                            ...horseProfile,
                            horse_external_coach: !horseProfile.horse_external_coach,
                          })
                      }
                      checked={horseProfile.horse_external_coach===true}
                  />
                </div>
              </div>
              <hr />
              <div className='disciplines'>
                <h4>Disciplines </h4>
                <div className='disciplineList'>
                  <Checkbox
                      CheckboxText='Obstacle'
                      CheckboxValue='Obstacle'
                      onClick={() => setHorseProfile({
                        ...horseProfile,
                        horse_disciplines:
                            { ...horseProfile.horse_disciplines, Obstacle: !horseProfile.horse_disciplines.Obstacle }
                      })}
                      checked={horseProfile.horse_disciplines.Obstacle ===true}  />
                  <Checkbox
                      CheckboxText='Dressage'
                      CheckboxValue='Dressage'
                      onClick={() => setHorseProfile({
                        ...horseProfile,
                        horse_disciplines:
                            { ...horseProfile.horse_disciplines, Dressage: !horseProfile.horse_disciplines.Dressage }
                      })}
                      checked={horseProfile.horse_disciplines.Dressage ===true} />
                  <Checkbox
                      CheckboxText='CCE'
                      CheckboxValue='CCE'
                      onClick={() => setHorseProfile({
                        ...horseProfile,
                        horse_disciplines:
                            { ...horseProfile.horse_disciplines, CCE: !horseProfile.horse_disciplines.CCE }
                      })}
                      checked={horseProfile.horse_disciplines.CCE ===true} />
                  <Checkbox
                      CheckboxText='TREC - Equifun'
                      CheckboxValue='TREC - Equifun'
                      onClick={() => setHorseProfile({
                        ...horseProfile,
                        horse_disciplines:
                            { ...horseProfile.horse_disciplines, TREC_Equifun: !horseProfile.horse_disciplines.TREC_Equifun }
                      })}
                      checked={horseProfile.horse_disciplines.TREC_Equifun ===true} />
                  <Checkbox
                      CheckboxText='Balade - Randonnée'
                      CheckboxValue='Balade - Randonnée'
                      onClick={() => setHorseProfile({
                        ...horseProfile,
                        horse_disciplines:
                            { ...horseProfile.horse_disciplines, Balade_Randonnee: !horseProfile.horse_disciplines.Balade_Randonnee }
                      })}
                      checked={horseProfile.horse_disciplines.Balade_Randonnee===true} />
                  <Checkbox
                      CheckboxText='Ethologie - Equifeel'
                      CheckboxValue='Ethologie - Equifeel'
                      onClick={() => setHorseProfile({
                        ...horseProfile,
                        horse_disciplines:
                            { ...horseProfile.horse_disciplines, Ethologie_Equifeel: !horseProfile.horse_disciplines.Ethologie_Equifeel }
                      })}
                      checked={horseProfile.horse_disciplines.Ethologie_Equifeel===true} />
                  <Checkbox
                      CheckboxText='Hunter'
                      CheckboxValue='Hunter'
                      onClick={() => setHorseProfile({
                        ...horseProfile,
                        horse_disciplines:
                            { ...horseProfile.horse_disciplines, Hunter: !horseProfile.horse_disciplines.Hunter }
                      })}
                      checked={horseProfile.horse_disciplines.Hunter===true}
                  />
                  <Checkbox
                      CheckboxText='Horse-Ball'
                      CheckboxValue='Horse-Ball'
                      onClick={() => setHorseProfile({
                        ...horseProfile,
                        horse_disciplines:
                            { ...horseProfile.horse_disciplines, Horse_Ball: !horseProfile.horse_disciplines.Horse_Ball }
                      })}
                      checked={horseProfile.horse_disciplines.Horse_Ball===true} />
                  <Checkbox
                      CheckboxText='Pony-Games'
                      CheckboxValue='Pony-Games'
                      onClick={() => setHorseProfile({
                        ...horseProfile,
                        horse_disciplines:
                            { ...horseProfile.horse_disciplines, Pony_Games: !horseProfile.horse_disciplines.Pony_Games }
                      })}
                      checked={horseProfile.horse_disciplines.Pony_Games===true} />
                  <Checkbox
                      CheckboxText='Reining-Western'
                      CheckboxValue='Reining-Western'
                      onClick={() => setHorseProfile({
                        ...horseProfile,
                        horse_disciplines:
                            { ...horseProfile.horse_disciplines, Reining_Western: !horseProfile.horse_disciplines.Reining_Western }
                      })}
                      checked={horseProfile.horse_disciplines.Reining_Western ===true}
                  />
                  <Checkbox
                      CheckboxText='Endurance'
                      CheckboxValue='Endurance'
                      onClick={() => setHorseProfile({
                        ...horseProfile,
                        horse_disciplines:
                            { ...horseProfile.horse_disciplines, Endurance: !horseProfile.horse_disciplines.Endurance }
                      })}
                      checked={horseProfile.horse_disciplines.Endurance ===true}
                  />
                  <Checkbox
                      CheckboxText='Attelage'
                      CheckboxValue='Attelage'
                      onClick={() => setHorseProfile({
                        ...horseProfile,
                        horse_disciplines:
                            { ...horseProfile.horse_disciplines, Attelage: !horseProfile.horse_disciplines.Attelage }
                      })}
                      checked={horseProfile.horse_disciplines.Attelage ===true} />
                  <Checkbox
                      CheckboxText='Voltige'
                      CheckboxValue='Voltige'
                      onClick={() => setHorseProfile({
                        ...horseProfile,
                        horse_disciplines:
                            { ...horseProfile.horse_disciplines, Voltige: !horseProfile.horse_disciplines.Voltige }
                      })}
                      checked={horseProfile.horse_disciplines.Voltige ===true}
                  />
                  <Checkbox
                      CheckboxText='Disciplines culturelles'
                      CheckboxValue='Disciplines culturelles'
                      onClick={() => setHorseProfile({
                        ...horseProfile,
                        horse_disciplines:
                            { ...horseProfile.horse_disciplines, Disciplines_culturelles: !horseProfile.horse_disciplines.Disciplines_culturelles }
                      })}
                      checked={horseProfile.horse_disciplines.Disciplines_culturelles ===true} />
                </div>
              </div>
              <hr />
              <div className="frequency_pension">
                <Pension
                    onClick={(e) =>
                        setHorseProfile({
                          ...horseProfile,
                          horse_riding_frequency: e.target.value,
                        })
                    }
                    frequency={horseProfile.horse_riding_frequency}
                    changeFixedFrequency={() =>
                        setHorseProfile({
                          ...horseProfile,
                          horse_fixed_day: !horseProfile.horse_fixed_day,
                        })
                    }
                    checked={horseProfile.horse_riding_frequency}
                    checked1={horseProfile.horse_fixed_day}


                />
              </div>
              <hr />
              <div className="materialDiv">
                <h4>Materiel</h4>
                <div className="materiel">
                  <SlidingButton
                      SlidingButtonText="Le cavalier doit avoir sa selle"
                      SlidingButtonID="materialSwitch"

                      onClick={() =>
                          setHorseProfile({
                            ...horseProfile,
                            horse_own_saddle: !horseProfile.horse_own_saddle,
                          })
                      }
                      checked={horseProfile.horse_own_saddle===true}
                  />
                </div>
              </div>
              <hr />
              <div className="baladeDiv">
                <h4>Balade</h4>
                <div className="balade">
                  <SlidingButton
                      SlidingButtonText="Possibilité de partir seul en balade"
                      SlidingButtonID="baladSwitch"
                      onClick={() =>
                          setHorseProfile({
                            ...horseProfile,
                            horse_stroll_along: !horseProfile.horse_stroll_along,
                          })
                      }
                      checked={horseProfile.horse_stroll_along===true}
                  />
                </div>
              </div>
              <hr />

              <div className="competiton">
                <Competition
                    onClick={(e) =>
                        setHorseProfile({
                          ...horseProfile,
                          horse_competition_preferences: e.target.value,
                        })
                    }
                    checked={horseProfile.horse_competition_preferences}
                />
              </div>

              <BudgetMensuel
                  budgetTitle="Budget"
                  budget={horseProfile.horse_budget}
                  currency={horseProfile.horse_currency_budget}
                  priceTitle={"Prix maximum par mois :"}
                  onChange={(e) =>
                      setHorseProfile({
                        ...horseProfile,
                        horse_budget: e.target.value,
                      })
                  }
                  onClick={(e) =>
                      setHorseProfile({
                        ...horseProfile,
                        horse_currency_budget: e.target.value,
                      })
                  }
              />

              <hr />
              <div className="owner_presentation">
                <h4> A propos de moi </h4>
                <div className="postHorse-form">
                  <Link to="/post-horse-owner" style={{ textDecoration: "none" }}>
                    <button className="postHorse_edit-button">
                      Editer votre présentation
                    </button>
                  </Link>
                </div>
              </div>
              <hr />
              <div className="postHorse_idealRider">
                <h4 id="anchorIdealRider">Cavalier idéal</h4>
                <IdealRider
                    yearPractice={horseProfile.ideal_rider_years_of_practice}
                    changePractice={(e) =>
                        setHorseProfile({
                          ...horseProfile,
                          ideal_rider_years_of_practice: e.target.value,
                        })
                    }
                    gallopLevel={horseProfile.ideal_rider_gallop_level}
                    changeGallop={(e) =>
                        setHorseProfile({
                          ...horseProfile,
                          ideal_rider_gallop_level: e.target.value,
                        })
                    }
                    ageRider={horseProfile.ideal_rider_age}
                    changeAgeRider={(e) =>
                        setHorseProfile({
                          ...horseProfile,
                          ideal_rider_age: e.target.value,
                        })
                    }
                    isVehiculed={() =>
                        setHorseProfile({
                          ...horseProfile,
                          ideal_rider_vehiculed: !horseProfile.ideal_rider_vehiculed,
                        })
                    }
                    hasManaged={() =>
                        setHorseProfile({
                          ...horseProfile,
                          ideal_rider_managed_horse: !horseProfile.ideal_rider_managed_horse,
                        })
                    }
                />
              </div>

              {horseProfile.horse_name !== '' && horseProfile.horse_postal !== '' && horseProfile.horse_photo1 !== '' ?
                  <FloatingButton
                      btnName={"Poster mon annonce"}
                      onClick={() => postDataHorse()}
                  />
                  :
                  <FloatingButton
                      btnName={"Poster mon annonce"}
                      inactive={true}
                  />
              }
            </div>
        }
        <Menu />
        <ModalPost show={modalShow} />
      </>
  );
}

export default PostHorse;
