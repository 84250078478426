import React, { useState, useContext } from 'react';
import { HashLink } from 'react-router-hash-link';
import { ContractContext } from "../context/ContractContext";
import "./Contract.css";
import Header from "../Header_footer/Header";
import Checkbox2 from '../common/Checkbox2'
import FloatingButton from '../common/FloatingButton'

const ContractVisitDays = () => {

    const { contractState, setContractState } = useContext(ContractContext);
    const visitDays = contractState.visitDays;

    const [monday, setMonday] = useState(visitDays.monday);
    const [tuesday, setTuesday] = useState(visitDays.tuesday);
    const [wednesday, setWednesday] = useState(visitDays.wednesday);
    const [thursday, setThursday] = useState(visitDays.thursday);
    const [friday, setFriday] = useState(visitDays.friday);
    const [saturday, setSaturday] = useState(visitDays.saturday);
    const [sunday, setSunday] = useState(visitDays.sunday);

    const updateContract = () => {
        const newvisitDays = {
            monday,
            tuesday,
            wednesday,
            thursday,
            friday,
            saturday,
            sunday,
        };
        setContractState({ ...contractState, visitDays: newvisitDays });
    }


    return(
        <>
            <Header className="header" title="VENUES DU CAVALIER" hash='/contract#visitDays' />
            <div className='contract_page'>
                <h4>Venues du cavalier :</h4>
                <div className="hebergtList">
                    <Checkbox2
                        CheckboxText="Lundi"
                        CheckboxValue="Lundi"
                        onChange={(evt) => {setMonday(!monday)}}
                        checked={monday}
                    />
                    <Checkbox2
                        CheckboxText="Mardi"
                        CheckboxValue="Mardi"
                        onChange={(evt) => {setTuesday(!tuesday)}}
                        checked={tuesday}
                    />
                    <Checkbox2
                        CheckboxText="Mercredi"
                        CheckboxValue="Mercredi"
                        onChange={(evt) => {setWednesday(!wednesday)}}
                        checked={wednesday}
                    />
                    <Checkbox2
                        CheckboxText="Jeudi"
                        CheckboxValue="Jeudi"
                        onChange={(evt) => {setThursday(!thursday)}}
                        checked={thursday}
                    />
                    <Checkbox2
                        CheckboxText="Vendredi"
                        CheckboxValue="Vendredi"
                        onChange={(evt) => {setFriday(!friday)}}
                        checked={friday}
                    />
                    <Checkbox2
                        CheckboxText="Samedi"
                        CheckboxValue="Samedi"
                        onChange={(evt) => {setSaturday(!saturday)}}
                        checked={saturday}
                    />
                    <Checkbox2
                        CheckboxText="Dimanche"
                        CheckboxValue="Dimanche"
                        onChange={(evt) => {setSunday(!sunday)}}
                        checked={sunday}
                    />
                </div>

                <HashLink to="/contract#visitDays" style={{ textDecoration: "none" }}>
                    <FloatingButton btnName='Valider' onClick={updateContract}/>
                </HashLink>

            </div>
        </>
    )
}


export default ContractVisitDays;