import React, { useState, useEffect } from "react";
import { useHistory } from 'react-router-dom';
import "./Contract.css";
import Header from "../Header_footer/Header";
import {loadStripe} from '@stripe/stripe-js';
import Axios from "axios";
import ModalError from "../common/ModalError";
import Menu from '../Menu'

const { REACT_APP_API_URL, REACT_APP_STRIPE_PUBLIC_KEY } = process.env;

const stripePromise = loadStripe(REACT_APP_STRIPE_PUBLIC_KEY);


const ContractPresentation = () => {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const token = localStorage.token;
    const history = useHistory();

    const [error, setError] = useState(null);

    const handleClick = async (event) => {

        try {
            const res = await Axios.get(`${REACT_APP_API_URL}/api/users/hasPayed`, {headers : { 'Authorization' : 'Bearer ' + token}});
            history.push('/contract-engagement');

            /*if (res.data.user_hasPayed) {}
             else {

                // Get Stripe.js instance
                const stripe = await stripePromise;
                // Call your backend to create the Checkout Session
                const response = await Axios.post(`${REACT_APP_API_URL}/api/contract/create-checkout-session`, {}, {headers : { 'Authorization' : 'Bearer ' + token}});

                const session = response.data;
                // When the customer clicks on the button, redirect them to Checkout.
                const result = await stripe.redirectToCheckout({
                sessionId: session.id,
                });
                if (result.error) {
                // If `redirectToCheckout` fails due to a browser or network
                // error, display the localized error message to your customer
                // using `result.error.message`.
                console.log(result.error.message);
                }
            }*/
        } catch (err) {
            setError(err.response.data.error);
            setTimeout(() => setError(null), 3000);
        }

      };

    return(
        <>
            <Header className="header" title="Rédiger un contrat" />
            <ModalError show={error != null} onHide={e => setError(null)} message={error} />
            <div className='contract_page'>
                <h4 className='center'>Rédiger, partager et signer un contrat</h4>
                <p><strong>Important :</strong> CavalCloud vous propose un générateur de contrat de quart, tiers ou demi-pension (ou location complète) sur-mesure. Chaque contrat généré est unique, propre à un cheval, son ou ses cavalier(s) et son propriétaire.</p>
                <p>Vous entrez les coordonnées des différentes parties, choisissez les modalité(s) voulues et complétez chacune selon votre cas. Inédite, cette fonctionnalité est gratuite par contrat. Le contrat ainsi généré est valable pendant toute la durée de votre collaboration avec un cavalier. Il est conseillé que chacune des parties ait à sa disposition un exemplaire papier signé par les deux signataires.</p>
                <p>Une question ? Un renseignement ? N'hésitez pas à contacter CavalCloud via les messageries instantanées de Facebook ou d'Instagram , ou bien écrivez un email à tech@cavalcloud.com</p>
                <p>Vous pourrez choisir entre :</p>
                <ul className="contractPresentationList"><li>Recevoir votre contrat au format pdf par e-mail, à imprimer et signer à la main</li>
                <li>Signer votre contrat sur l’écran de votre téléphone, recevoir et envoyer votre exemplaire en pdf, signé.</li></ul>
                <div className="ArrowButton">
                    <svg width="1em" height="1em" viewBox="0 0 16 16" className="bi bi-chevron-right" fillRule="#5d5aa4" xmlns="http://www.w3.org/2000/svg">
                        <path fillRule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"/>
                    </svg>
                    <button role="link" onClick={handleClick}>
                        Démarrer la rédaction
                    </button>
                </div>
            </div>
        <Menu />
        </>
    );
};

export default ContractPresentation;