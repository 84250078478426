import React from "react";
import "./Home.css";
import Header from "./Header_footer/Header";
import { useHistory } from "react-router-dom";
import { createBrowserHistory } from "history";

const CGV_page = () => {

const history = createBrowserHistory();
      return (
            <>
{/* path='home' */}
                  <Header className="header" title="CGV - CGU"      onClick={() => {
                    history.goBack();
                }} />
                  <div className='cgv_page'>
                        <div>
                              <h5 className='cgvTitle'>Les sites Cavalcloud.com, et app.cavacloud.com sont édité par :</h5>
                              <p>
                                    CAVALCLOUD SARL Unipersonnelle au capital social de 2 000€ - 887 950 822 RCS Aix-en-Provence <br />
            SIRET 88795082200015 – n° de TVA Intracommunautaire FR66 887 950 822 <br />
                                    <br />
                                    <h5>Siège social :</h5>
            350, route des Milles
            13090 Aix en Provence
            FRANCE
          </p>
                              <p>
                                    Adresse e-mail : tech@cavalcloud.com
                                    URL : app.cavalcloud.com
          </p>
                              <p>
                                    <h5>Responsable : </h5>
            Mathilde Hutter
          </p>
                              <p>
                                    <h5>Loi informatique et liberté</h5>
            Conformément à la loi informatique et liberté du 6 janvier 1978, vous disposez des droits d'opposition, d'accès et de rectification des données vous concernant en application de la loi n° 78-17 du 6 janvier 1978 relative à l'informatique,
            aux fichiers et aux libertés, modifiée par la loi 2004-801 du 06 août 2004. Ces données peuvent être communiquées à des tiers et vous pouvez être amené à recevoir des offres commerciales ou non. Si vous ne le souhaitez pas, il vous suffit d'écrire à l'éditeur du site en indiquant vos noms, prénoms, adresse, adresses e-mail afin qu'il puisse faire le nécessaire dans les meilleurs délais.
          </p>
                              <p>
                                    <h5>Droit d'auteur</h5>
            Les données, comprenant leur contenu, les documents téléchargeables, les représentations iconographiques et photographiques, et les marques commerciales présentes sur ce site, sont protégées par un droit de propriété intellectuelle.
            Toute reproduction ou représentation, même partielle des éléments susmentionnés, à d'autres fins qu'une utilisation privative, sur un quelconque support, est interdite et pourrait engager la responsabilité civile et pénale de la personne qui ne respecterait pas cette interdiction.
          </p>
                              <p>
                                    <h5>Cookies</h5>
            Ce site est susceptible d'enregistrer des “cookies” sur votre ordinateur. Il s'agit d'informations sur votre visite et sur votre équipement informatique, consultables par le site si vous revenez le visiter ultérieurement.
            Ces informations sont enregistrées uniquement à des fins d'établissement de statistiques de fréquentation, et ne donnent lieu à aucun traitement nominatif. Il vous est possible de refuser l'enregistrement de telles informations en vous rendant dans les options (ou préférences) de votre navigateur internet, et en désactivant les cookies.
          </p>
                        </div>
                        <div>
                              <h7 style={{ textalign: 'center', fontWeight: '600' }}>CONDITIONS GÉNÉRALES D'UTILISATION ET DE VENTE CAVALCOUD</h7>

                              <p>Les présentes conditions générales d’utilisation et de vente (ci-après les « Conditions Générales ») s’appliquent aux services proposés par CAVALCLOUD </p>
                              <p>En utilisant le site internet (ou mobile), vous acceptez de vous conformer et d’être lié par les stipulations des présentes, que vous ayez ou non recours aux services proposés.
                              Si vous acceptez les présentes Conditions Générales au nom d'une société ou autre personne morale, vous déclarez et garantissez avoir le droit d'engager ladite société ou autre personne morale conformément aux présentes Conditions Générales,
                              auquel cas « vous », « vos » et « votre » feront référence et s'appliqueront à ladite société ou autre personne morale. Dans le cas où vous n’accepteriez pas les présentes Conditions Générales, vous n’avez pas le droit d’obtenir des informations ni d’utiliser notre site.
                L’utilisation de notre site en violation des présentes Conditions Générales est passible de sanctions civiles et pénales.</p>
                              <p>Le service proposé sur notre site est édité par CAVALCLOUD, Entreprise Unipersonnelle à Responsabilité Limitée au capital de 2 000 €, dont le siège social est à Aix-en-Provence (13100), immatriculée au RCS d’Aix en Provence sous le numéro 887 950 822, dont l’hébergement est assuré par Google et Amazon Web Service.</p>

                              <h5>1. DÉFINITIONS</h5>
                              <p>« CAVALCLOUD » désigne la société éditant les services proposés sur le site app.cavalcloud.com <br />
                « Site » désigne le site www.cavalcloud.com ou toute application mobile éditée par CAVALCLOUD et proposant des services similaires.<br />
                « Propriétaire » désigne la personne physique ou morale, agissant à titre professionnel ou non, proposant sur le Site un ou plusieurs équidé(s).<br />
                « Cavalier » désigne la personne ayant accepté la proposition de mise à disposition d’un ou plusieurs équidés.<br />
                « Taxe » ou « Taxes » désigne toute taxe sur les ventes, taxe sur la valeur ajoutée (TVA), taxe sur les produits et services (TPS), taxe de séjour, taxe applicable aux touristes ou autres visiteurs, taxe sur l'hébergement, frais (tels que les frais relatifs aux centres de convention) que les prestataires d'Hébergement peuvent avoir l'obligation légale de collecter
                et de remettre aux autorités administratives, toute autre taxe municipale, d'Etat, fédérale ou nationale indirecte similaire ou autre retenue et impôt sur le revenu ou sur les bénéfices.<br />
                « Cheval » désigne l’équidé, pour lequel le Cavalier recherche une location totale ou partielle.<br />
                « Compte Utilisateur » : compte créé par chaque Membre sur app.cavalcloud.com permettant d’accéder aux services proposés par CAVALCLOUD.<br />
                « Hébergement Cheval » désigne tout endroit permettant l’accueil du Cheval (un box, un pré…).<br />
                « Annonce » désigne une annonce concernant un équidé ou un cavalier disponible pour une demi-pension et publiée par un Membre par le biais du Site.<br />
                « Membre » désigne toute personne ayant accepté les présentes Conditions Générales et utilisant le Site à travers un Compte Utilisateur.<br />
                « Montant de la Transaction » désigne le prix TTC des Services proposés par CAVALCLOUD pour les Cavaliers et/ou propriétaire d’équidé(s) par l’intermédiaire du Site.</p>

                              <h5>2. MODIFICATIONS DES CONDITIONS GÉNÉRALES</h5>
                              <p>CAVALCLOUD se réserve le droit de modifier à tout moment les Conditions Générales, les fonctionnalités offertes sur le Site/l’appli web ou les règles de fonctionnement du Site/de l’appli web.<br />
                  Les modifications prendront effet dès la mise en ligne des Conditions Générales que tout Membre reconnaît avoir préalablement consultées.<br />

                  CAVALCLOUD se réserve le droit de proposer des services nouveaux, gratuits ou payants sur le Site.</p>

                              <h5>SECTION I : CONDITIONS GÉNÉRALES D'UTILISATION DE LA PLATEFORME CAVALCLOUD</h5>
                              <h5>1. SERVICES PROPOSÉS PAR LA SOCIÉTÉ CAVALCLOUD</h5>
                              <p>Bien que nous nous efforcions de fournir un service de qualité, nous ne pouvons ni vérifier, ni garantir l'exactitude, la précision ou l'exhaustivité des informations, et nous ne pouvons être tenus pour responsables de toute erreur,
                  toute interruption de service (due à une défaillance technique, qu’elle soit temporaire et/ou partielle, panne, réparation, mise à jour, amélioration ou maintenance de la Plateforme ou autre), toute information imprécise, trompeuse ou erronée, ou bien tout manque d’information.<br />

                  Chaque Membre demeure entièrement responsable à tout moment du caractère précis, complet et exact des informations qui le concernent et qui sont affichées sur le Site/l’appli web, y compris de ses tarifs et disponibilités.
                  Le Site ne constitue pas et ne doit pas être considéré comme une quelconque forme de recommandation ou d’approbation de la qualité, du niveau de service ou du classement des Annonces.<br />

                  Notre service est uniquement disponible pour les Membres pour une utilisation privée et non-commerciale. Vous ne pouvez donc pas revendre, utiliser, copier, surveiller (par exemple, par le biais d’un robot d’indexation ou de captures de données d’écran),
                  afficher, télécharger, reproduire ou établir des liens profonds vers tout contenu ou toute information, réservation, tout logiciel, billet et/ou tous produits ou services disponibles sur notre Site dans le cadre d’une activité ou d’un objectif commercial ou concurrentiel.<br />

                  En cas d’utilisation de notre Site, vous comprenez et acceptez que votre relation avec CAVALCLOUD est limitée à être un Membre et non un agent, employé, co-entrepreneur, ou partenaire de CAVALCLOUD.<br />

                  Vous agissez en votre nom et pour votre propre bénéfice.<br />

                  En tant que Membre, vous vous obligez à ne rien faire de nature à créer l’impression que vous entretenez un partenariat ou agissez pour le compte de CAVALCLOUD. </p>

                              <h5>2. PORTAIL EN LIGNE DE MISE EN RELATION ENTRE CAVALIERS PROPRIETAIRES ET NON-PROPRIETAIRES ET OFFRE DE SERVICES COMPLÉMENTAIRES</h5>
                              <p>CAVALCLOUD fournit un portail en ligne grâce auquel les propriétaires de chevaux, qu’ils soient particuliers ou professionnels, proposent leur(s) cheval/chevaux à la location totale ou partielle, communément appelée quart, tiers ou demi-pension. CAVALCLOUD fournit un portail grâce auquel des cavaliers proposent de prendre un cheval en location totale ou partielle.
                Qu’il soit propriétaire d’équidé ou non, les utilisateurs du site CAVALCLOUD peuvent : <br />
                  -	Chercher un cheval à louer totalement ou partiellement<br />
                  -	Chercher un cavalier prêt à louer totalement ou partiellement un équidé<br />
                  -	Déposer une annonce en tant que propriétaire pour mettre en location totale ou partielle son cheval<br />
                  -	Déposer une annonce en tant que cavalier prêt à louer totalement ou partiellement un équidé.<br />
                  -	Générer un contrat de demi-pension, appelé Contrat de Prêt à Usage. Chaque contrat est unique, personnalisé et destiné à n’être établi qu’entre deux (2) individus partageant un (1) équidé. Le générateur de contrat est utilisable contre la somme TTC de 15 € (quinze euros) soit 12,5€ Hors Taxe.<br />
                  -	Créer et partager autant de calendrier qu’ils le veulent, un calendrier correspondant à un équidé<br />
                  -	Utiliser l’outil de simulation de partage des frais annexes au prix d’une demi-pension<br />

                En générant un contrat par l’intermédiaire du Site, vous vous engagez dans une relation contractuelle directe avec un cavalier. CAVALCLOUD intervient uniquement en qualité d’intermédiaire. Les signataires s’engagent sur les clauses qu’ils veulent et ne s’engagent pas sur les clauses non-remplies (parfois une clause ne s’applique pas ou ne vous semble pas utile).
                La base du contrat que CAVALCLOUD génère pour vous constitue une pièce importante en cas de conflit entre les parties. Faire un contrat de quart, tiers ou demi-pension, que vous soyez propriétaire ou non, n’est pas une obligation. La société CAVALCLOUD se dégage de toute responsabilité quant aux désaccords qui pourraient interférer entre les différentes parties. <br />

                En application de la réglementation applicable aux plateformes, entrée en vigueur à partir du 1er juillet 2016 (articles 242 bis I et II du Code des impôts), toute plateforme doit fournir une information loyale, claire et transparente sur les obligations fiscales et sociales à la charge des personnes utilisant le Site pour des transactions commerciales.</p>

                              <h5>3. RÉFÉRENCEMENT, GÉOLOCALISATION, SERVICES COMPLÉMENTAIRES</h5>
                              <p>CAVALCLOUD propose aux Membres des services de référencement et de géolocalisation d’équidés et de cavaliers, ainsi que des services complémentaires au service de la location totale ou partielle de chevaux. Afin d’être référencé, l’utilisateur ayant préalablement créé un compte devra élaborer une annonce soit en tant que cavalier s’il recherche un équidé,
                soit pour son équidé s’il recherche pour ce-dernier un ou des cavaliers. S’il veut être déréférencé, l’utilisateur devra supprimer la ou les annonces postées. Chaque Membre est et restera pendant toute la durée et de l’exécution de chaque Service Complémentaire le seul et unique organisateur et responsable des informations communiquées. </p>

                              <h5>4. CRÉATION D'UN COMPTE UTILISATEUR</h5>
                              <p>Afin d’accéder aux services proposés par CAVALCLOUD, chaque Membre doit au préalable créer un Compte Utilisateur, en fournissant des données personnelles le concernant, indispensables au bon fonctionnement du service de mise en relation de personnes (notamment nom, prénom, numéro de téléphone et adresse e-mail valides).<br />

                Les Membres personnes physiques certifie être âgé de plus de 15 ans au moment de l’inscription.<br />
                Les Membres personnes physiques âgés de moins de 18 ans au moment de leur inscription certifient agir sous la responsabilité d’un représentant légal (parents, tuteur…).<br />

                CAVALCLOUD ne pourra en aucun cas être tenue responsable des informations qui pourraient être erronées ou frauduleuses communiquées par les Membres.<br />

                Le Membre s’engage à ne pas créer ou utiliser d’autres comptes que celui initialement créé sauf demande explicite de la part du Membre et autorisation expresse et spécifique de CAVALCLOUD.</p>

                              <h5>5. CRÉATION D'ANNONCES PAR LES MEMBRES SUR CAVALCLOUD</h5>
                              <p>Toute personne souhaitant proposer un équidé ou bien son profil en tant que cavalier disponible pour une location totale ou partielle doit créer une Annonce.<br />

                Les Equidés et Cavaliers font l’objet d’Annonces distinctes et séparées. Les cavaliers et les propriétaires d’équidés, qu’ils soient ou non des professionnels, seront mis en relation à travers la Plateforme. <br />

                Les Membres peuvent créer et publier des Annonces dont le but est de présenter un équidé, sa structure d’hébergement, ou bien se présenter soi-même, son équitation. Les membres peuvent accéder aux Services Complémentaires comme le générateur de contrat, moyennant un coût ttc de 15€, ou encore le calendrier partagé. <br />

                Le Membre a l’obligation de communiquer diverses informations sur l’équidé proposé (la localisation, les caractéristiques, les installations, la disponibilité, le prix…) ou bien sur lui-même en tant que cavalier à la recherche d’un équidé à louer partiellement ou totalement.<br />

                Les Membres comprennent que l'ordre ou le classement des Annonces dans les résultats de recherche puissent dépendre de nombreux facteurs y compris, notamment, des préférences de chaque Membre à l’origine de la recherche.<br />

                Les Membres reconnaissent être seuls responsables de leur Annonces et leur contenu. Il lui convient de veiller à la justesse et à la représentativité de son Annonce.<br />

                Ils sont notamment responsables de toutes images de l’équidé et/ou lui-même, à charge de celui-ci de la modifier si celles-ci n’étaient plus conformes à la réalité de l’Hébergement et autorise CAVALCLOUD à utiliser ces images de sorte que CAVALCLOUD ne soit jamais inquiétée.<br />

                Le Membre convient que CAVALCLOUD dispose définitivement (même en cas de suppression de l’Annonce) du droit d'utiliser et puisse utiliser les images vérifiées pour la publicité, la commercialisation ainsi qu'à d'autres fins commerciales dans tout média ou plateforme, que cela concerne ou non votre Annonce, sans avis supplémentaire ou indemnisation. Le Membre autorise CAVALCLOUD à transmettre à tout partenaire commercial les informations et images utilisées dans chaque Annonce.<br />

                Les Membres déclarent et garantissent que toute Annonce publiée ne viole aucun accord qu’elle aurait passé avec des tiers, sont ou seront conformes à toutes les lois applicables, obligations fiscales, règles et réglementations pouvant s'appliquer à tout équidé faisant l'objet d'une Annonce qu’il a publié (y compris l'obtention de tous permis, licences et enregistrements requis) et enfin que cette annonce ne viole ou ne violera pas les droits de tiers.<br />

                Les Membres acceptent et reconnaissent que tout accord passé entre Membres n’engage qu’eux, CAVALCLOUD n'étant pas partie à celui-ci. Les membres dépositaires d’annonce(s), demeurent responsables de leurs propres actes et omissions.<br />

                CAVALCLOUD autorise les propriétaires d’équidé à accéder aux Services Complémentaires moyennant le paiement d’une prestation de mise à disposition d’une fonctionnalité, dont les modalités financières sont précisées dans les présentes CGU, Section I, Paragraphe 2.</p>

                              <h5>6. FIXATION DES PRIX DES LOCATIONS ET SERVICES COMPLÉMENTAIRES À LA LOCATION</h5>
                              <p>Le prix des demi-pensions sont indiqués TTC et sont définis par le propriétaire de l’équidé de manière libre, sous sa seule responsabilité. Il incombe notamment au propriétaire de prendre en compte tous frais et charges inhérents au type de location d’équidé proposées via le Site et notamment des « Frais annexes ».</p>

                              <h5>7. MISE EN RELATION ET SERVICES COMPLÉMENTAIRES</h5>
                              <p>Le propriétaire d’équidé, qu’il soit un particulier ou un professionnel, propose sur le Site un ou plusieurs équidés à la location totale ou partielle via son Annonce.
                <br />Le Cavalier, qu’il soit amateur ou professionnel, propose sur le Site son profil s’il est candidat à la location totale ou partielle d’un équidé.</p>

                              <h5>SECTION II : ADHESION AUX CONDITIONS GENERALES DES SERVICES TIERS </h5>
                              <h5>1. STRIPE</h5>
                              <p>Les services de paiement pour les utilisateurs du site sont fournis par Stripe et soumis à l’Accord sur les comptes Stripe Connected (Stripe Connected Account Agreement), qui inclut les Modalités de service de Stripe (l’ensemble étant appelé les “Conditions Générales d’Utilisation Stripe” - “Stripe Services Agreement”.)<br />

                En agréant aux présentes Conditions Générales ou en continuant à opérer en tant qu’utilisateur officiant sur le Site, vous acceptez d’être lié aux Conditions Générales d’Utilisation Stripe, celles-ci pouvant occasionnellement faire l’objet de modifications de la part de Stripe. Du fait que le Site permette d’effectuer les paiements via Stripe, vous acceptez de fournir à CAVALCLOUD des informations précises et complètes sur vous et votre activité,
                et autorisez CAVALCLOUD à partager ces informations ainsi que celles concernant les transactions effectuées via la solution de paiement fournie par Stripe si elles lui sont demandées par une administration fiscale. </p>

                              <h5>2. AMAZON WEB SERVICE dit AWS</h5>
                              <p>Les services d’hébergement pour les utilisateurs du site sont fournis par AWS, qui inclut les Modalités de service de AWS (l’ensemble étant appelé les “Conditions Générales d’Utilisation AWS” - “AWS Services Agreement”.)
              <br />En agréant aux présentes Conditions Générales ou en continuant à opérer en tant qu’utilisateur officiant sur le Site, vous acceptez d’être lié aux Conditions Générales d’Utilisation AWS, celles-ci pouvant occasionnellement faire l’objet de modifications de la part de AWS. Du fait que le Site existe via AWS, vous acceptez de fournir à CAVALCLOUD des informations précises et complètes sur vous et votre activité, et autorisez CAVALCLOUD à partager ces informations saisies via la solution d’hébergement fournie par AWS.
              </p>

                              <h5>SECTION III : GENERALITES </h5>
                              <h5>1. MANQUEMENTS AUX CONDITIONS GÉNÉRALES</h5>
                              <p>Tout manquement aux présentes conditions générales d’utilisation peut entraîner des sanctions civiles et/ou pénales ainsi que la suspension, temporaire ou définitive, du Compte Utilisateur.<br />

                Les Membres sont informés par les présentes que toute utilisation de la Plateforme à des fins autres que référencement, mise en relation et souscription des autres services offertes via la Plateforme est considérée comme une utilisation frauduleuse du Site, préjudiciable pour CAVALCLOUD.<br />

                De manière générale, les Membres s’engagent à informer CAVALCLOUD de tels agissements frauduleux dès lors qu’ils en ont connaissance dans les plus brefs délais.</p>

                              <h5>2. LITIGES</h5>
                              <p>CAVALCLOUD n’est en aucun cas partie aux contrats conclus entre les Membres. CAVALCLOUD n’a pas d’obligation quant à trouver une solution aux litiges entre ses Membres.</p>


                              <h5>3. RESPONSABILITÉ</h5>
                              <p>Le service proposé par CAVALCLOUD est un service d’intermédiation.<br />

                Les Membres agissent sous leurs seules et entières responsabilités.<br />

                Le déroulement de leur pratique de l’équitation, ainsi que toute autre exécution de Contrat conclu entre les Membres via la Plateforme ne saurait entrainer la responsabilité de CAVALCLOUD, sur quelque fondement que ce soit.<br />

                La responsabilité de CAVALCLOUD ne peut notamment pas être engagée en raison d’un sinistre qui serait survenu lors d’une location totale ou partielle d’équidé.<br />

                CAVALCLOUD exclut toute responsabilité concernant le respect par les Membres de tous accords ou obligations conclus avec des tiers, ainsi que des lois, règles et réglementations applicables.<br />

                CAVALCLOUD, n’étant ni propriétaire ni gardien du Cheval pour lequel la plateforme est utilisée, elle ne pourra être considérée comme responsable des dommages causés par ou sur le Cheval, en ce compris tout problème sanitaire.<br />

                Les Membres reconnaissent que, CAVALCLOUD n’étant pas partie au contrat de prêt à usage conclu entre les Membres, CAVALCLOUD n’est nullement responsable des dommages subis par le Cheval lors de son prêt.<br />

                En outre, aucune forme de responsabilité ne pourra être retenue à l’égard de CAVALCLOUD en cas d’utilisation frauduleuse d’instruments de paiement par le Cavalier. CAVALCLOUD n’assure donc aucune garantie de paiement aux propriétaires d’équidés comme aux cavaliers non-propriétaire.<br />

                Il peut arriver que l’accès au site ou au service soit interrompu dans le cadre d’opérations de maintenance, de mises à niveau matérielle ou logicielle, de réparations d’urgence du site, ou par suite de circonstances indépendantes de la volonté de CAVALCLOUD (comme par exemple, défaillance des liaisons et équipements de télécommunications). CAVALCLOUD s’engage à prendre toutes les mesures raisonnables pour limiter ces perturbations, pour autant qu’elles lui soient imputables.<br />
                Les Membres reconnaissent et acceptent que CAVALCLOUD n’assume envers eux aucune responsabilité pour toute indisponibilité, suspension ou interruption du site ou du service et ne peut être tenue responsable des préjudices directs et indirects de toute nature résultant de ce fait.<br />

                Dans tous les cas, et sans préjudice de ce qui vient d’être exposé ci-dessus et dans les autres clauses concernant l’absence de responsabilité de CAVALCLOUD, toute cause de responsabilité qui pourrait être retenue à l’encontre de CAVALCLOUD ne pourra donner lieu qu’au versement de dommages et intérêts dont le montant sera limité aux montants encaissés au titre des prestations payantes.</p>

                              <h5>4. INTERRUPTION ET SUSPENSION D'ACCÈS AU SERVICE ET/OU SITE</h5>
                              <p>En cas de non-respect de votre part de tout ou partie des Conditions Générales vous reconnaissez et acceptez que CAVALCLOUD peut à tout moment, sans notification préalable, interrompre ou suspendre, de manière temporaire ou définitive, tout ou partie du Service ou votre accès au site (y compris notamment votre Compte Utilisateur).</p>

                              <h5>5. DONNÉES PERSONNELLES ET CONTENU DU SITE</h5>
                              <p>a. Données personnelles</p>
                              <p>Dans le cadre de votre utilisation de la Plateforme, CAVALCLOUD est amenée à collecter et traiter certaines de vos données personnelles. En utilisant le Site et vous inscrivant en tant que Membre, vous reconnaissez et acceptez le traitement de vos données personnelles par CAVALCLOUD conformément aux stipulations de la Politique de Confidentialité.</p>
                              <p>b. Propriété intellectuelle</p>
                              <p>Les sites www.cavalcloud.com et  app.cavalcloud.com sont la propriété de CAVALCLOUD.<br />

                La reproduction, y compris des présentes Conditions Générales, même partielle est subordonnée à l’autorisation préalable et écrite de CAVALCLOUD.<br />

                Tout lien hypertexte dirigé vers une autre page que la page d’accueil du site est soumise à l’autorisation préalable et écrite de CAVALCLOUD.</p>

                              <p>c. Contenu du site saisi par les membres</p>
                              <p>CAVALCLOUD n’est aucunement responsable du contenu saisi sur le Site par ses Membres.<br />
                CAVALCLOUD fera cependant tout son possible pour supprimer sans délai du Site les contenus qui lui auront été signalé, par le biais des messageries instantanées de ses réseaux sociaux ou par email à l’adresse tech@cavalcloud.com <br />

                Les Membres autorisent CAVALCLOUD à transmettre le contenu du Site saisi par les Membres, notamment le contenu des Annonces, à des partenaires commerciaux dans le cadre du développement des services proposés par CAVALCLOUD. </p>

                              <h5>6. PRESCRIPTION</h5>
                              <p>Toute réclamation en relation contre la Société avec les présentes se prescrit dans un délai d’un an. Les Membres renoncent à tout paiement qui n’aurait pas été demandé dans un délai d’un an. Les sommes non réclamées sont acquises à CAVALCLOUD.<br />

                Les dispositions du présent article ne s’appliquent pas aux Membres agissant en qualité de consommateur au sens de l’article liminaire du Code la consommation.</p>

                              <h5>7. DROIT APPLICABLE ET JURIDICTION COMPÉTENTE</h5>
                              <p>Il est expressément convenu entre CAVALCLOUD et les Membres que la loi française régit les stipulations des présentes.<br />

              La juridiction compétente en cas de litige est considérée comme étant le tribunal compétent du lieu du siège de la société CAVALCLOUD.</p>




                        </div>


                  </div>
                  {/*<Menu />*/}
            </>
      );
};

export default CGV_page;
