import React, { useState } from "react"
import { Link } from "react-router-dom"
import "./Result.css"
import Axios from "axios"
import heart from "../SVG-icons/coeur-hors-selection.svg"
import heartFull from "../SVG-icons/coeur-selection.svg"
import noImage from '../SVG-icons/no-image.png';
import Menu from './../Menu'
import ModalError from "../common/ModalError";
const { REACT_APP_API_URL } = process.env;

const ResultCard = (props) => {


  // get the correct user ID in order to pass it to the ResultAnnonce component
  const token = localStorage.token
  const [showModal, setshowModal] = useState(false);
  const rider_ID = props.fullResult.rider_ID
  const rider_firstname = props.fullResult.rider_firstname
  const rider_photo1 = props.fullResult.rider_photo1
  const rider_photo2 = props.fullResult.rider_photo2
  const statusFavorite = props.statusFavorite
const defaultImage=noImage;
  const dataBody = {
    rider_ID: rider_ID,
    rider_firstname: rider_firstname,
    rider_photo1: rider_photo1,
    rider_photo2:rider_photo2

  }

  const [favoriteIcon, setFavoriteIcon] = useState(statusFavorite === false ? heart : heartFull)

  const [isFavorite, setIsFavorite] = useState(false)

  const handleClick = () => {
    setshowModal(true);

  }

  const addInFavorite = () => {
    if (favoriteIcon === heart) {
      Axios
        .post(`${REACT_APP_API_URL}/api/users/addFavoriteRider`, dataBody, {
          headers: { 'Authorization': 'Bearer ' + token }
        })
        .catch((err) => console.error(err)
        );
    }
    else {
      Axios
        .delete(`${REACT_APP_API_URL}/api/users/deleteFavoriteRider/${rider_ID}`, {
          headers: { 'Authorization': 'Bearer ' + token }
        })
        .catch((err) => console.error(err)
        );
    }
  }

  const handleImageError = e => {
    e.target.src = defaultImage;
  }
    return (
      <>
        <div className="resultCard">
        <ModalError show={showModal} onHide={e => setshowModal(false)}  message={"Vous devez avoir un compte et être connecté pour accéder à cette fonctionnalité"} />
        {token === undefined ?
        <Link onClick={handleClick}>
            <div className="resultCard-containerRider">
              <img className="resultPhotoRider" onError={handleImageError}  src={props.fullResult.rider_photo1}
                alt={props.rider_firstname}   />
            </div>
          </Link>
          :
          <Link
            to={{
              pathname: `/rider/result-annonce/${rider_ID}`,
            }}
          >
            <div className="resultCard-containerRider">
              <img className="resultPhotoRider" onError={handleImageError}  src={props.fullResult.rider_photo1}
                alt={props.rider_firstname}   />
            </div>
          </Link>}
          <div className="resultDetailsRider">
            <p id="resultName">{props.fullResult.rider_firstname}</p>
            <img
              className="resultHeart"
              onClick={() => {
                setFavoriteIcon(favoriteIcon === heart ? heartFull : heart);
                setIsFavorite(!isFavorite);
                addInFavorite()
              }}
              src={favoriteIcon}
              alt={favoriteIcon}
            />
          </div>
        </div>
        <Menu />
      </>
    );


};

export default ResultCard;
