import React, { useState, useContext, useEffect } from 'react';
import { HashLink } from 'react-router-hash-link';
import { ContractContext } from "../context/ContractContext";
import "./Contract.css";
import Header from "../Header_footer/Header";
import FloatingButton from '../common/FloatingButton'

const ContractPeriod = () => {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const { contractState, setContractState } = useContext(ContractContext);
    const period = contractState.period;

    const [localPeriod, setLocalPeriod] = useState(period);

    const updateContract = () => {
        setContractState({ ...contractState, period: localPeriod });
    }

    return(
        <>
            <Header className="header" title="DUREE DU CONTRAT" hash='/contract#period' />
            <div className='contract_page'>
                <h4>Tacite reconduction :</h4>
                <p>Le contrat est conclu pour une période d'un an et se renouvellera par tacite reconduction, à défaut de résiliation par l'une ou l'autre des parties.</p>

                <h4>Résiliation :</h4>
                <p>L'une ou l'autre des parties peuvent mettre fin au présent contrat :</p>
                <ul><li>par LRAR ;</li>
                <li>par courier écrit et daté remis au propriétaire ;</li></ul>
                <p>en respectant un préavis de :</p>    
                <h4>Combien de mois ?</h4>
                <input 
                    min="0" 
                    max="200"
                    className='customInput'
                    type='number'
                    value={localPeriod}
                    onChange={(evt) => {setLocalPeriod(evt.currentTarget.value)}}
                />

                <HashLink to="/contract#period" style={{ textDecoration: "none" }}>
                    <FloatingButton btnName='Valider' onClick={updateContract}/>
                </HashLink>
            </div>
        </>
    )
}

export default ContractPeriod;