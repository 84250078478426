import React, { useState } from 'react';
import "./event.css";
import TrainingCategories from '../../common_section/TrainingCategories';
import WorkIntensityButton from '../../common/WorkIntensityButton';
import Audio from './Audio';
import PhotosSection from './PhotosSection';
import Axios from "axios";


const { REACT_APP_API_URL } = process.env;


const NewEvent = ({ date, calendarCode, setNewEventPage, addEvent }) => {

    const [hour1, setHour1] = useState("");
    const [hour2, setHour2] = useState("");
    const [categorie, setCategorie] = useState("Autres");
    const [precisions, setPrecisions] = useState("");
    const [workIntensity, setWorkIntensity] = useState(3);

    const [audios, setAudios] = useState([]);

    const [imgFiles, setImgFiles] = useState([]);
    const [imgURLs, setImgURLs] = useState([]);

    const sendNewEvent = (evt) => {
        var datas = new FormData();

        const begin = new Date(date.getFullYear(), date.getMonth(), date.getDate(), Number(hour1.substr(0, 2)), Number(hour1.substr(3, 2)));
        const end = new Date(date.getFullYear(), date.getMonth(), date.getDate(), Number(hour2.substr(0, 2)), Number(hour2.substr(3, 2)));
        datas.append('begin', begin);
        datas.append('end', end);
        datas.append('categorie', categorie);
        datas.append('precisions', precisions);
        datas.append('workIntensity', workIntensity);

        for (var imgFile of imgFiles) {
            datas.append('images', imgFile, imgFile.name);
        }

        for (var audio of audios) {
            datas.append('audios', audio.data, audio.fileName);
        }

        Axios
        .post(`${REACT_APP_API_URL}/api/calendar/event/` + calendarCode, datas, { headers: { 'content-type': 'multipart/form-data' }} )
        .then(res => {
            const eventCreated = { ...res.data, begin: new Date(res.data.begin), end: new Date(res.data.end) };
            addEvent(eventCreated);
            setNewEventPage(false);
        })
        .catch(err => console.log(err));
    };


    return(
        <>
            <h5>Heure :</h5>
            <div className="hoursLine">
                <input 
                    className='customInput'
                    type='time'
                    value={hour1}
                    onChange={evt => setHour1(evt.target.value)}
                />
                <p>à</p>
                <input 
                    className='customInput'
                    type='time'
                    value={hour2}
                    onChange={evt => setHour2(evt.target.value)}
                />
            </div>
            <hr/>

            <h5>Catégorie :</h5>

            <div className="categories">
                <TrainingCategories categorie={categorie} setCategorie={setCategorie} />
            </div>
            <hr />

            <h5>Précisions :</h5>
            <form className="postHorse_msg">
            <label>
                <textarea
                    className="postHorse_input"
                    type="text"
                    placeholder="Ajoutez quelques mots"
                    value={precisions}
                    onChange={evt => setPrecisions(evt.target.value)}
                />
            </label>
            </form>
            <hr/>

            <h5>Message vocal :</h5>
            <Audio audios={audios} setAudios={setAudios} />
            <hr/>

            <h5>Intensité du travail :</h5>
            <WorkIntensityButton intensity={workIntensity} setIntensity={setWorkIntensity}/>
            <hr/>

            <h5>Ajouter des photos :</h5>
            <PhotosSection imgFiles={imgFiles} setImgFiles={setImgFiles} imgURLs={imgURLs} setImgURLs={setImgURLs} />
            <hr/>

            <button className="greenButton" id="newButton" onClick={sendNewEvent}> 
                Publier mon événement
            </button>
            
            
        </>
    );
};


export default NewEvent;