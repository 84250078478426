import React, { useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import Header from '../Header_footer/Header'
import './calculate.css'
import Menu from '../Menu'

const CalculateResult = () => {

    const history = useHistory();
    const location = useLocation();
    const state = location.state;


    const createRiders = () => {
        var result = [];
        for (var rider of state.riders) {
            var price = state.pension * rider.pension * 0.01;
            price += (state.marechal.number * state.marechal.price * rider.marechal * 0.01 / 12);
            price += (state.veterinaire * rider.veterinaire * 0.01 / 12);
            for (var other of state.others) {
                price += (other.price * rider.others * 0.01 / 12);
            }
            result.push({ name: rider.name, price: Math.round(price * 100) / 100 });
        }
        return result;
    };


    return (
        <>
            <Header className="header" title="NOMBRE DE CAVALIERS" path='home' />

            <div className="calculate_page">
                <h5>Participation des cavaliers :<br />(par mois)</h5>

                {createRiders().map(({ name, price }, index) => (
                    <div key={index} className="resultField">
                        <p className="resultName">{name}</p>
                        <div className="bar"></div>
                        <p>{price}€</p>
                    </div>
                ))}
                <hr />

                <button className="greenButton" onClick={e => history.push("/calculate/mail", createRiders())}>Envoyer par e-mail</button>
                <button className="whiteButton" onClick={evt => history.push('/calculate')}>Nouvelle simulation</button>

            </div>
            <Menu />
        </>
    )
}

export default CalculateResult;