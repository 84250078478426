import React, { useState, useContext } from 'react';
import { HashLink } from 'react-router-hash-link';
import { ContractContext } from "../context/ContractContext";
import "./Contract.css";
import Header from "../Header_footer/Header";
import CustomInput from '../common/CustomInput'
import FloatingButton from '../common/FloatingButton'

const ContractRider = () => {

    const { contractState, setContractState } = useContext(ContractContext);
    const rider = contractState.rider;

    const [firstName, setFirstName] = useState(rider.firstName);
    const [lastName, setLastName] = useState(rider.lastName);
    const [address, setAddress] = useState(rider.address);
    const [postalCode, setPostalCode] = useState(rider.postalCode);
    const [city, setCity] = useState(rider.city);

    const updateContract = () => {
        const newRider = {
            firstName,
            lastName,
            address,
            postalCode,
            city,
        };
        setContractState({ ...contractState, rider: newRider });
    };

    
    return(
        <>
            <Header className="header" title="COORDONNEES DU CAVALIER" hash='/contract#rider' />
            <div className='contract_page'>
                <h4>Coordonnées du cavalier :</h4>
                <CustomInput placeholder="Prénom" value={firstName} onChange={(evt) => {setFirstName(evt.currentTarget.value)}}/>
                <CustomInput placeholder='Nom' value={lastName} onChange={(evt) => {setLastName(evt.currentTarget.value)}}/>
                <CustomInput placeholder='Adresse' value={address} onChange={(evt) => {setAddress(evt.currentTarget.value)}}/>
                <input 
                    min="0" 
                    max="200"
                    className='customInput'
                    type='number'
                    placeholder='Code postal'
                    value={postalCode}
                    onChange={(evt) => {setPostalCode(evt.currentTarget.value)}}
                />
                <CustomInput placeholder='Ville' value={city} onChange={(evt) => {setCity(evt.currentTarget.value)}}/>

                <HashLink to="/contract#rider" style={{ textDecoration: "none" }}>
                    <FloatingButton btnName='Valider' onClick={updateContract}/>
                </HashLink>
            </div>
        </>
    )
}

export default ContractRider;