import React, { useState } from 'react';
import "./organise.css";
import Header from "../Header_footer/Header";
import Calendar from "./Calendar";
import Axios from "axios";


const { REACT_APP_API_URL } = process.env;


const CalendarPage = (props) => {

    const calendarCode = props.match.params.code;

    const [events, setEvents] = useState([]);
    const [firstLoading, setFirstLoading] = useState(true);
    const [horse, setHorse] = useState(calendarCode);

    if (firstLoading) {
        Axios.get(`${REACT_APP_API_URL}/api/calendar/events/` + calendarCode)
        .then(res => {
            var newEvents = [];
            for (var event of res.data.Events) {
                newEvents.push({ ...event, begin: new Date(event.begin), end: new Date(event.end) });
            }
            setEvents(newEvents);
            setHorse(res.data.horse);
        })
        .catch(err => console.log(err));
        setFirstLoading(false);
    }

    // Return the list of dates where there is at least one event
    const eventDates = () => {
        var eventDates = [];
        for (var event of events) {
            var eventDateAlreadyRegistered = false;
            for (var eventDateIndex in eventDates) {
                var eventDate = eventDates[eventDateIndex];
                if (event.begin.getFullYear() === eventDate.date.getFullYear() && event.begin.getMonth() === eventDate.date.getMonth() && event.begin.getDate() === eventDate.date.getDate()) {
                    eventDateAlreadyRegistered = true;
                    if (eventDate.intensity < event.workIntensity) {
                        eventDates[eventDateIndex].intensity = event.workIntensity;
                    }
                    break;
                }
            }
            if (!eventDateAlreadyRegistered) {
                const newDate = new Date(event.begin.getFullYear(), event.begin.getMonth(), event.begin.getDate());
                eventDates.push({ date: newDate, intensity: event.workIntensity });
            }
        }
        return eventDates;
    };

    // Return the list of events wich are in the day
    const eventsInOneDay = (day) => {
        var eventsList = [];
        for (var event of events) {
            if (event.begin.getFullYear() === day.getFullYear() && event.begin.getMonth() === day.getMonth() && event.begin.getDate() === day.getDate()) {
                eventsList.push(event);
            }
        }
        return eventsList;
    };

    return(
        <>
            <Header className="header" title={horse} />
            <Calendar code={calendarCode} events={eventDates()} eventsInOneDay={eventsInOneDay} horse={horse} />
            <p className='pcenter'>Tapotez un jour pour ajouter un événement</p>
        </>
    );
};


export default CalendarPage;