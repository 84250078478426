import React, { useState, useEffect } from "react"
import "./Result.css"
import Axios from "axios";
import ReturnButton from "../common/ReturnButton"
import Carousel , { Dots } from "@brainhubeu/react-carousel";
import "@brainhubeu/react-carousel/lib/style.css";
import ChatButton from '../common/FloatingButton'
import noImage from '../SVG-icons/no-image.png';
import DeleteAnnonce from '../common/ModalDelete';
import { createBrowserHistory } from "history";
const { REACT_APP_API_URL } = process.env;

const ResultAnnonce = (props) => {
    // get the rider_ID in order to display the specific result
    const riderId = Number(props.match.params.id)
    const [count, setCount] = useState(0);
    const [values, setValue] = useState(0);

    const token = localStorage.token;

    // User Data storage:
    const [dataUser, setDataUser] = useState({
        user_lastname: "",
        user_firstname: "",
        user_email: "",
        user_avatar: '',
        user_phone: "",
        user_ID: ''
    })
    // Get user profil
    const getMyProfile = () => {
        Axios.get(`${REACT_APP_API_URL}/api/users/profile`, {
            headers: { 'Authorization': 'Bearer ' + token }
        })
            .then((res) => setDataUser(res.data))
            .catch((error) => console.log(error))
    }

    let element=0;
    // Rider Data information
    const [dataRider, setDataRider] = useState(null);

    
    // Get information about the selected rider from its ID
    const getRiderInformation = () => {
      Axios.get(`${REACT_APP_API_URL}/api/riders/${riderId}`)
      .then(res => setDataRider(res.data[0]))
      .catch(err=> console.error(err))
    }

       // delete the selected rider annonce from its ID
       const DeleteRiderAnnonce = () => {
        Axios.delete(`${REACT_APP_API_URL}/api/riders/${riderId}`)
        .then(res => {})
        .catch(err=> console.error(err))
      }


    // Transforme les booléens récupérés de l'annonce en oui ou non 
    const changeBool = (bool) => bool === true ? 'oui' : 'non'
    const changeBool2 = (num) => num === 1 ? <span className='greenLight'>oui</span> : 'non'
    // const displayIt = (val) => val === 0 || null ? 
    // When a result is displayed, the function getRiderInformation starts first in order to query BDD
    useEffect(() => {
        getMyProfile()
      getRiderInformation()
      changeBool(dataRider)
      }, [])


    const handleImageError = e => { 
        e.target.style.display = 'none';
        setCount(count+1);
      }

    const  onChange = values => setValue(values);

    const [modal, setModal] = useState(false);
    const history = createBrowserHistory();
  const handleClick = () => {
    setModal(true);

  }
  const refreshPage = () => {
    window.location.reload();
    history.goBack();
   }
  const toggle = () => setModal(!modal);

  return (
    <>
      <div className="headerAnnonce">
        <h3 id="annonceTitle">Annonce Cavalier</h3>{" "}
      </div>

      <ReturnButton />

      {dataRider != null ? 

      <>
      
      <div className="Result_annonce">
        <div className="annonce_header">
        {dataRider.User.user_avatar&& (<img id="annonce_logo" src={dataRider.User.user_avatar} alt="logo" /> )}
        {!dataRider.User.user_avatar && (  <img id="annonce_logo" src={noImage} alt="logo" /> )}
          <div>
            <h5>
              {dataRider.rider_firstname},{" "}
              <span>{dataRider.rider_age} ans</span>
            </h5>
            <p>
              {dataRider.rider_selfWord1}, {dataRider.rider_selfWord2},{" "}
              {dataRider.rider_selfWord3}
            </p>
            <p>N° de téléphone : {dataRider.User.user_phone}</p>
            <p>Mail : {dataRider.User.user_email}</p>
          </div>
          <h4>Localisation</h4>
          <p>{dataRider.rider_postal_code} {" "} {dataRider.rider_localisation}</p>
        </div>
        <h5>Quelques photos</h5>
        <Carousel  itemWidth={330} itemHeight={200} centered offset={-9}>
         <img onError={handleImageError} src={dataRider.rider_photo1} alt="rider_photo1" className='imagesRider'/>
          <img onError={handleImageError} src={dataRider.rider_photo2} alt="rider_photo2" className='imagesRider' />
          <img onError={handleImageError} src={dataRider.rider_photo3} alt="rider_photo3" className='imagesRider'/>
        </Carousel>
         <Dots  value={values} onChange={onChange}  number={3-count} /> 
        <div>
          <h4>Equitation</h4>
          <p>
            {dataRider.rider_ridingWord1}, {dataRider.rider_ridingWord2},{" "}
            {dataRider.rider_ridingWord3}
          </p>
        </div>
        <div>
          <h4>Message :</h4>
          <p>{dataRider.rider_biography}</p>
        </div>
        <hr />
        <div>
          <h4>Budget</h4>
          <p>
            {dataRider.rider_budget}
            {dataRider.rider_currency_budget} / mois
          </p>
        </div>
        <hr />
        <div>
          <h4>Autonomie</h4>
          <h5>Est-il véhiculé ? </h5>
          <p>{changeBool(dataRider.rider_vehiculed)}</p>
          <h5>A déjà eu un cheval sous sa responsabilité ?</h5>
          <p> {changeBool(dataRider.rider_managed_horse)}</p>
        </div>
        <hr />
        <div>
          <h4>Niveau</h4>
          <p>Années de pratique : {dataRider.rider_years_of_practice}</p>
          <p>Galop : {dataRider.rider_gallop_level}</p>
        </div>
        <hr />
        <div>
          <h4>Disciplines</h4>
          <div>
            <p>
          Attelage : {changeBool2(dataRider.Discipline.attelage)} <br />
          Balade ou Randonnée : {changeBool2(dataRider.Discipline.baladeRandonnee)}<br />
          CCE : {changeBool2(dataRider.Discipline.cce)}<br />
          Disciplines Culturelles : {changeBool2(dataRider.Discipline.disciplinesCulturelles)}<br />
          Dressage : {changeBool2(dataRider.Discipline.dressage)}<br />
          Endurance : {changeBool2(dataRider.Discipline.endurance)}<br />
          Ethologie/Equifeel : {changeBool2(dataRider.Discipline.ethologieEquifeel)}<br />
          Horse Ball : {changeBool2(dataRider.Discipline.horseBall)}<br />
          Hunter : {changeBool2(dataRider.Discipline.hunter)}<br />
          Obstacle : {changeBool2(dataRider.Discipline.obstacle)}<br />
          Pony Games : {changeBool2(dataRider.Discipline.ponyGames)}<br />
          Reining Western : {changeBool2(dataRider.Discipline.reiningWestern)}<br />
          TREC Equifun : {changeBool2(dataRider.Discipline.trecEquifun)}<br />
          Voltige : {changeBool2(dataRider.Discipline.voltige)}<br />
          </p>
          </div>
          <p>
            Ouvert à d'autres disciplines :{" "}
            {changeBool(dataRider.rider_agree_other_discipline)}
          </p>
        </div>
        <hr />
        <div>
          <h4>Rythme de venue</h4>
          <p>{dataRider.rider_riding_frequency}</p>
          <p>
            Jours fixes souhaités : {changeBool(dataRider.rider_fixed_day)}{" "}
          </p>
        </div>
        <hr />
        <div>
          <h4>Cheval idéal</h4>
          <p>Taille :{dataRider.ideal_horse_size} cm</p>
          <p>Tempérament : {dataRider.ideal_horse_temper}</p>
          <p>Caractère : {dataRider.ideal_horse_caracter}</p>
          <p>Physique : {dataRider.ideal_horse_body_type}</p>
          <p>Age : {dataRider.ideal_horse_age} ans</p>
        </div>
        <hr />
        <h4>Coaching</h4>
        <p>
          J'aimerais avoir accès à des cours :{" "}
          {changeBool(dataRider.rider_coaching_here)}
        </p>
        <p>
          J'aimerais faire intervenir un coach de l'extérieur :{" "}
          {changeBool(dataRider.rider_external_coach)}
        </p>
        <hr />
        <div>
          <h4>Concours</h4>
          <p>{dataRider.rider_competition}</p>
        </div>

          {
              dataRider.user_ID === dataUser.user_ID ?
                  <ChatButton btnName="Supprimer l'annonce" onClick={handleClick} />
          :
                  <ChatButton btnName='Lancer une conversation' onClick={() => window.location.href=`/conversation/${dataRider.user_ID}`}/>
          }

          <DeleteAnnonce isOpen={modal}  toggle={() => toggle()}  delete={() => DeleteRiderAnnonce()} refresh={() => refreshPage()
           } />
      </div>
      <div className="Result-filterbarBot">
      </div>
      </>

      : 

      <div className="Result_annonce">
        <p>Cette annonce n'est pas accessible ou n'existe plus.</p>
      </div>

    } 
    </>
  );
};
export default ResultAnnonce;
