import React, { useState } from 'react';
import "./event.css";
import WorkIntensityButton from '../../common/WorkIntensityButton';
import TrainingCategories from '../../common_section/TrainingCategories';
import Audio from './Audio';
import PhotosSection from './PhotosSection';
import Axios from "axios";


const { REACT_APP_AWS_BUCKET_URL, REACT_APP_API_URL } = process.env;


const ModifyEvent = ({ date, event, setModifyEventSelected, setEventIdToSelect, modifyEvents }) => {

    const HourToString = (date) => {
        var hours = date.getHours() + "";
        var minutes = date.getMinutes() + "";
        if (hours.length === 1) {
            hours = "0" + hours;
        }
        if (minutes.length === 1) {
            minutes = "0" + minutes;
        }
        return hours + ":" + minutes;
    };

    const photos = ['photo1', 'photo2', 'photo3', 'photo4', 'photo5'];
    const audiosList = ['audio1', 'audio2', 'audio3', 'audio4', 'audio5'];

    const initImgURLs = () => {
        var urls = [];
        const urlBase = REACT_APP_AWS_BUCKET_URL + "/events/" + event.event_ID + "/imgs/";
        for (var id in event) {
            if (photos.includes(id) && event[id] != null) {
                urls.push({ fileName: event[id], url: urlBase + event[id] });
            }
        }
        return urls;
    };

    const initAudios = () => {
        var audios = [];
        const urlBase = REACT_APP_AWS_BUCKET_URL + "/events/" + event.event_ID + "/audios/";
        for (var id in event) {
            if (audiosList.includes(id) && event[id] != null) {
                audios.push({ fileName: event[id], data: null, url: urlBase + event[id] });
            }
        }
        return audios;
    };
 
    const [hour1, setHour1] = useState(HourToString(event.begin));
    const [hour2, setHour2] = useState(HourToString(event.end));
    const [categorie, setCategorie] = useState(event.categorie);
    const [precisions, setPrecisions] = useState(event.precisons);
    const [workIntensity, setWorkIntensity] = useState(event.workIntensity);

    const [audios, setAudios] = useState(initAudios());
    const [deleteAudios, setDeleteAudios] = useState([]);

    const [imgFiles, setImgFiles] = useState([]); // Liste des images chargées par l'utilisateur (en local)
    const [imgURLs, setImgURLs] = useState(initImgURLs()); // Liste des urls des images affichées sur la page (local + venant du serveur)

    const [imgToDelete, setImgToDelete] = useState([]); // Liste des imgs à supprimer lors de l'envoie des modifications. Les imgs sont déjà supprimées en local mais pas sur le serveur

    console.log(imgFiles);
    console.log(imgURLs);

    const deleteAudiosAndImages = () => {
        const deleteAudiosFunction = () => {
            if (deleteAudios.length > 0) {
                Axios
                .put(`${REACT_APP_API_URL}/api/calendar/deleteaudios/` + event.event_ID, deleteAudios)
                .then(res => {
                    setDeleteAudios([]);
                    modifyEvent();
                })
                .catch(err => {
                    console.log(err);
                    modifyEvent();
                });
            } else {
                modifyEvent();
            }
        };
        if (imgToDelete.length > 0) {
            Axios
            .put(`${REACT_APP_API_URL}/api/calendar/deleteimgs/` + event.event_ID, imgToDelete)
            .then(res => {
                setImgToDelete([]);
                deleteAudiosFunction();
            })
            .catch(err => {
                console.log(err);
                deleteAudiosFunction();
            });
        } else {
            deleteAudiosFunction();
        }
    };

    const modifyEvent = () => {
        var datas = new FormData();

        const begin = new Date(date.getFullYear(), date.getMonth(), date.getDate(), Number(hour1.substr(0, 2)), Number(hour1.substr(3, 2)));
        const end = new Date(date.getFullYear(), date.getMonth(), date.getDate(), Number(hour2.substr(0, 2)), Number(hour2.substr(3, 2)));
        datas.append('begin', begin);
        datas.append('end', end);
        datas.append('categorie', categorie);
        datas.append('precisions', precisions);
        datas.append('workIntensity', workIntensity);

        for (var imgFile of imgFiles) {
            datas.append('images', imgFile, imgFile.name);
        }

        for (var audio of audios) {
            if (audio.data) {
                datas.append('audios', audio.data, audio.fileName);
            }
        }

        Axios
        .post(`${REACT_APP_API_URL}/api/calendar/modifyevent/` + event.event_ID, datas, { headers: { 'content-type': 'multipart/form-data' }} )
        .then(res => {
            const eventCreated = { ...res.data, begin: new Date(res.data.begin), end: new Date(res.data.end) };
            modifyEvents(eventCreated);
            setEventIdToSelect(-1);
            setModifyEventSelected(false);
        })
        .catch(err => console.log(err));
    };


    return(
        <>
            <h5>Heure :</h5>
            <div className="hoursLine">
                <input 
                    className='customInput'
                    type='time'
                    value={hour1}
                    onChange={evt => setHour1(evt.target.value)}
                />
                <p>à</p>
                <input 
                    className='customInput'
                    type='time'
                    value={hour2}
                    onChange={evt => setHour2(evt.target.value)}
                />
            </div>
            <hr/>

            <h5>Catégorie :</h5>

            <div className="categories">
                <TrainingCategories categorie={categorie} setCategorie={setCategorie} />
            </div>
            <hr />

            <h5>Précisions :</h5>
            <form className="postHorse_msg">
            <label>
                <textarea
                    className="postHorse_input"
                    type="text"
                    placeholder="Ajoutez quelques mots"
                    value={precisions}
                    onChange={evt => setPrecisions(evt.target.value)}
                />
            </label>
            </form>
            <hr/>

            <h5>Message vocal :</h5>
            <Audio audios={audios} setAudios={setAudios} deleteAudios={deleteAudios} setDeleteAudios={setDeleteAudios} />
            <hr/>

            <h5>Intensité du travail :</h5>
                <WorkIntensityButton intensity={workIntensity} setIntensity={setWorkIntensity}/>
            <hr/>

            <h5>Ajouter des photos :</h5>
            <PhotosSection imgFiles={imgFiles} setImgFiles={setImgFiles} imgURLs={imgURLs} setImgURLs={setImgURLs} imgToDelete={imgToDelete} setImgToDelete={setImgToDelete} />
            <hr/>
    
            <button className="greenButton" id="newButton" onClick={deleteAudiosAndImages}> 
                Enregistrer les modifications
            </button>
    
        </>
    );
};


export default ModifyEvent;