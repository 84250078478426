import React from 'react'
import './Field.css'


const Field = ({ name, value }) => (
    <p>{name} : <span className="fieldValue">{value}</span></p>
)


export default Field
