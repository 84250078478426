import React from 'react'
import RangeButton from '../common/RangeButton'
import './common_section.css'


const IdealHorse = (props) => {

    

    return (

        <div className='idealHorse'>
            <div className='horse_size'>
                <h5> Taille en cm <span>(+ ou - 5cm)</span>: {props.horseSize}</h5>
                <div className='divRangeSpan'>
                    <span>80cm</span>
                    <RangeButton 
                        min='80'
                        max='210'
                        radioRangeBtnId='horseSize'
                        onChange={props.changeSize}

                        />
                    <span>210cm</span>
                </div>
            </div>

                <hr />
            <div className='horse_age'>
            <h5> Age du cheval <span>(+ ou - 2ans)</span>: {props.ageHorse}</h5>
            <div className='divRangeSpan'>
                    <span>1 an</span>
                    <RangeButton 
                        id='ageHorse'
                        min='1'
                        max='30'
                        radioRangeBtnId='ageHorse'
                        onChange={props.changeAge}/>
                    <span>30 ans</span>
                </div>
            </div>
            </div>
        

    )}

export default IdealHorse