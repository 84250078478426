
import React from 'react';
import './index.css';
import Router from "./Router";
import CacheBuster from "./CacheBuster";
import AuthVerifyComponent from "./Authverify";


function App() {
  return (
      <CacheBuster>
          {({ loading, isLatestVersion, refreshCacheAndReload }) => {
              if (loading) return null;
              if (!loading && !isLatestVersion) {
                  refreshCacheAndReload();
              }

              return (
                  <div>
                      <Router/>
                      <AuthVerifyComponent />
                  </div>
              );
          }}
      </CacheBuster>
  );
}

export default App;
