import React, { useState } from "react";
import "./landing.css";
import { Link, Redirect } from "react-router-dom";
import logo from "../SVG-icons/cavalcloud-logo.png";
import Axios from "axios";
const { REACT_APP_API_URL } = process.env;

const Login = () => {
  // Params user for logging
  const [dataUser, setDataUser] = useState({
    user_email: '',
    user_password: ''
  })

  // By default, user is not logged
  const [logged, setLogged] = useState(false)
  // Récupération des erreurs serveur (status 400) lors du login (si erreur existante)
  const [error, setError] = useState(null)

  const login = () => {
    Axios
      .post(`${REACT_APP_API_URL}/api/users/login`, dataUser)
      .then((res) => {
        localStorage.setItem("token", res.data.token);

        setLogged(true);

       /* setTimeout(() => refreshPage(), expirationTime); */
      })
      .catch((err) => setError(err.response.data.error))
  }

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      event.stopPropagation();
      login();
    }
  };


  return (
    <>
      {logged ? <Redirect to="/home" /> : null}
      <div className="login_page">
        <img className="login_logo" src={logo} alt="logo" />
        {error != null ? <div className="alert alert-danger" role="alert">{error}</div> : null}
        <div className="login_forms">
          <form>
            <label>
              <input
                className="login_input_text"
                type="email"
                placeholder=" Adresse mail"
                name="email"
                autoFocus
                value={dataUser.user_email}
                onKeyPress={(event) => { handleKeyPress(event) }}
                onChange={(e) =>
                  setDataUser({ ...dataUser, user_email: e.target.value })
                }
              />
            </label>
          </form>
          <form>
            <label>
              <input
                className="login_input_text"
                type="password"
                placeholder=" Mot de passe"
                name="mot-de-passe"
                value={dataUser.user_password}
                onKeyPress={(event) => { handleKeyPress(event) }}
                onChange={(e) =>
                  setDataUser({ ...dataUser, user_password: e.target.value })
                }
              />
            </label>
          </form>
          <Link to="/forget-password" style={{ textDecoration: "none" }}>
            <p className="forget-password-link">Mot de passe oublié</p>
          </Link>
        </div>
        {/* <Link to="/home" style={{ textDecoration: "none" }}> */}
        <button className="login_button" onClick={() => login()}>
          Se connecter
        </button>
        {/* </Link> */}
        <div>
          <p>Pas encore de compte ?</p>
          <Link to="/register" style={{ textDecoration: "none" }}>
            <p className="login_low_text">S'inscrire</p>
          </Link>
        </div>
      </div>
    </>
  );
};
export default Login;
