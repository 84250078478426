import React, { useState } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

const ModalDelete = (props) => {

  // By default, modal is closed
  const [modal, setModal] = useState(true);

  // Open or close modal
  const toggle = () => setModal(false);


  return (
      <Modal isOpen={props.isOpen}  >
        <ModalHeader >Supprimer l'annonce</ModalHeader>
        <ModalBody>
          <p>Confirmez-vous la suppression de cette annonce ?</p>
        </ModalBody>
        <ModalFooter>
          <Button  color="danger" onClick={() => {
            toggle()
            props.delete()
            props.refresh()
            }}>
            Supprimer 
          </Button>
          <Button color="secondary" onClick={() => props.toggle()}>Annuler</Button>
        </ModalFooter>
      </Modal>
  );
}

export default ModalDelete;