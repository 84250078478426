import React, { useState, useEffect } from "react";
import "./profile.css";
import Header from "../Header_footer/Header";
import ResultCard from "../Rider_Results/ResultCard";
import HorseResultCard from "../Horse_Results/HorseResultCard";
import Axios from "axios";
import heartFull from "../SVG-icons/coeur-selection.svg";
import Menu from '../Menu'
import { Link } from "react-router-dom";

const { REACT_APP_API_URL } = process.env;

const Favorites = () => {

  // Verify is user is registered
  const token = localStorage.token 

  // By default, heartIcon is setted because these are favorites
  const favorite = heartFull
  
  const [favoritesRider, setFavoritesRider] = useState([]);
  const [favoritesHorse, setFavoritesHorse] = useState([]);


  useEffect(() => {
    const getFavoritesHorses = () => {
      Axios
      .get(`${REACT_APP_API_URL}/api/users/myfavorites/horses/`, { 
        headers : { 'Authorization' : 'Bearer ' + token}
      })
      .then((res) => setFavoritesHorse(res.data))
      .catch((err) => console.error(err));   
    }
    getFavoritesHorses();
    const getFavoritesRiders =() => {
      Axios
      .get(`${REACT_APP_API_URL}/api/users/myfavorites/riders/`, {
        headers : { 'Authorization' : 'Bearer ' + token} 
        })
      .then((res) => setFavoritesRider(res.data))
      .catch((err) => console.error(err)); 
    }
    getFavoritesRiders();
  },[]);

  return (
    <>
      <Header className="header" title="Annonces sauvegardées"  path='home'/>
      {token === undefined ?
            <div className="postHorse_page">
          <p style={{'text-align' : 'center'}}>Vous devez être connecté(e) pour accéder à cette fonctionnalité.</p>
          <div className='login' >
            <Link to='/login' style={{ textDecoration: "none" }}>
                <button type='button' id='loginBtn' > Se connecter </button>
            </Link>
          </div>
          <p style={{'text-align' : 'center'}}>Pas encore de compte ? Créer un compte gratuitement</p>
          <div className='create' >
            <Link to='/register' style={{ textDecoration: "none" }}>
                <button type='button' id='createBtn' > Créer un compte </button>
            </Link>
          </div>
        </div>

      :
      <div className="Favorites-Page">

        {favoritesRider.length === 0 && favoritesHorse.length === 0 ? 
            <div> <h3>Aucune annonce sauvegardée dans vos favoris</h3> </div>
          : null 
        }

        {favoritesHorse.length !== 0  ? 
          favoritesHorse.map(e => (
            <HorseResultCard
              fullResult={e}
              horse_name={e.horse_name}
              horse_ID={e.horse_ID}
              photo={e.horse_photo1}
              statusFavorite={true}
              src={favorite}
            />
            ))
          : null
        }
        <hr />
        {favoritesRider.length !== 0 ? 
          favoritesRider.map(e => (
            <ResultCard
              fullResult={e}
              rider_firstname={e.rider_firstname}
              rider_ID={e.rider_ID}
              photo={e.rider_photo1}
              statusFavorite={true}
              src={favorite}
            />))
          : null 
        }
    </div>
      }
    <Menu />
    </>
  )
};


export default Favorites;
