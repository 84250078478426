import React, { useState, useContext } from 'react';
import { HashLink } from 'react-router-hash-link';
import { ContractContext } from "../context/ContractContext";
import "./Contract.css";
import Header from "../Header_footer/Header";
import CustomInput from '../common/CustomInput'
import FloatingButton from '../common/FloatingButton'

const ContractInstitution = () => {

    const { contractState, setContractState } = useContext(ContractContext);
    const institution = contractState.institution;

    const [name, setName] = useState(institution.name);
    const [address, setAddress] = useState(institution.address);
    const [postalCode, setPostalCode] = useState(institution.postalCode);
    const [city, setCity] = useState(institution.city);

    const [contactIsMan, setContactIsMan] = useState(institution.contactIsMan);
    const [contactName, setContactName] = useState(institution.contactName);
    const [contactNumber, setContactNumber] = useState(institution.contactNumber);

    const updateContract = () => {
        const newInstitution = {
            name,
            address,
            postalCode,
            city,
            contactIsMan,
            contactName,
            contactNumber,
        };
        setContractState({ ...contractState, institution: newInstitution });
    };


    return(
        <>
            <Header className="header" title="ETABLISSEMENT" hash='/contract#institution' />
            <div className='contract_page'>

                <h4>Coordonnées de l'établissement :</h4>
                <CustomInput placeholder='Nom' value={name} onChange={(evt) => {setName(evt.currentTarget.value)}}/>
                <CustomInput placeholder='Adresse' value={address} onChange={(evt) => {setAddress(evt.currentTarget.value)}}/>
                <input 
                    min="0" 
                    max="200"
                    className='customInput'
                    type='number'
                    placeholder='Code postal'
                    value={postalCode}
                    onChange={(evt) => {setPostalCode(evt.currentTarget.value)}}
                />
                <CustomInput placeholder='Ville' value={city} onChange={(evt) => {setCity(evt.currentTarget.value)}}/>

                <h4>Contact :</h4>
                <div className="contactNameAndGender">
                    <select className='customList' name="gender" value={contactIsMan ? 'Mr' : 'Miss'} onChange={evt => setContactIsMan(evt.currentTarget.value == 'Mr')}>
                        <option value="Mr">M</option>
                        <option value="Miss">Mme</option>
                    </select>
                    <CustomInput placeholder='Nom' value={contactName} onChange={(evt) => {setContactName(evt.currentTarget.value)}}/>
                </div>
                
                <input 
                    min="0" 
                    max="200"
                    className='customInput'
                    type='tel'
                    placeholder='Phone'
                    value={contactNumber}
                    onChange={(evt) => {setContactNumber(evt.currentTarget.value)}}
                />
               
               <HashLink to="/contract#institution" style={{ textDecoration: "none" }}>
                    <FloatingButton btnName='Valider' onClick={updateContract}/>
                </HashLink>

            </div>
        </>
    )
}


export default ContractInstitution;