import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import Header from '../Header_footer/Header'
import './calculate.css'
import Menu from '../Menu'

const CalculateRiderNbr = () => {

    const history = useHistory();

    const [riderNumber, setRiderNumber] = useState("");

    const createRiders = () => {
        var result = [];
        for (var i = 0; i < Number(riderNumber); i++) {
            result.push({ id: i });
        }
        return result;
    };

    const nextPage = (evt) => {
        history.push("/calculate/rider-names", { riders: createRiders() });
    };

    return (
        <>
            <Header className="header" title="ACCUEIL" path='home' />
            <div className="calculate_page">
                <h4 className="calculate_title">
                    Nombre de cavaliers : <br />(y compris le.s propriétaire.s)
                </h4>
                <input
                    type="number"
                    className='littleInput'
                    placeholder='0'
                    value={riderNumber}
                    onChange={evt => setRiderNumber(evt.currentTarget.value)}
                />
                <div className="finalButton">
                    <div className="NextArrowButton">
                        <svg width="1em" height="1em" viewBox="0 0 16 16" className="bi bi-chevron-right" fillRule="#5d5aa4" xmlns="http://www.w3.org/2000/svg">
                            <path fillRule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z" />
                        </svg>
                        <button onClick={nextPage}>
                            Suivant
                        </button>
                    </div>
                </div>
            </div>
            <Menu />
        </>
    )
}

export default CalculateRiderNbr;