import React, { useState, useContext } from 'react';
import { ContractContext } from "../context/ContractContext";
import { useLocation, useHistory } from 'react-router-dom';
import "./Contract.css";
import Axios from "axios";
import Header from "../Header_footer/Header";
import CustomInput from '../common/CustomInput'
import ModalError from "../common/ModalError";

const { REACT_APP_API_URL } = process.env;

const ContractMail = () => {

    const location = useLocation();
    const history = useHistory();
    const { signature } = location.state;
    const { contractState } = useContext(ContractContext);
    const token = localStorage.token;

    const [mails, setMails] = useState([{id: 0, name:''}]);
    const [modalMessage, setModalMessage] = useState("");

    const changeMails = (evt) => {
        var newMails = mails.slice();
        newMails[evt.currentTarget.id].name = evt.currentTarget.value;
        setMails(newMails);
    };

    const addMail = (evt) => {
        var newMails = mails.slice();
        newMails.push({
            id: mails.length,
            name:''
        });
        setMails(newMails);
    };

    const sendMail = (evt) => {
        var datas = new FormData();
        datas.append('contract', JSON.stringify(contractState));
        datas.append('mails', mails.map(x => x.name));
        Axios
        .post(`${REACT_APP_API_URL}/api/contract/sendMails`, datas, {headers : { 'Authorization' : token, 'content-type': 'multipart/form-data' }})
        .then(res => {
            setModalMessage(res.data.message);
            setTimeout(() => history.push('/home'), 4000);
        })
        .catch(res => {
            setModalMessage(res.response.data.error);
            setTimeout(() => setModalMessage(""), 4000);
        });
    };

    const nextPage = (evt) => {
        history.push({
            pathname: '/contract-signature',
            state: { mails: mails.map(x => x.name) }
        });
    };

    return(
        <>
            <Header className="header" title="VOTRE CONTRAT" link='/contract-list' />
            <ModalError show={modalMessage ? true : false} onHide={e => setModalMessage("")} message={modalMessage} />
            <div className='contract_page'>
                {mails.map(mail => (
                    <div key={mail.id}>
                        <p>E-mail :</p>
                        <CustomInput
                            value={mail.name}
                            key={mail.id}
                            id={mail.id}
                            onChange={changeMails}
                        />
                        <p></p>
                    </div>
                ))}
                <p></p>
                <p></p>

                <button className="start_writing-button" id="addMailButton" onClick={addMail}>
                    Ajouter une adresse email
                </button>

               <button className="greenButton" onClick={signature ? nextPage : sendMail }>{signature ? 'Signer' : 'Envoyer'}</button>

            </div>
        </>
    )
}

export default ContractMail;