import React, { useState } from 'react';
import "./workIntensity.css";


const WorkIntensityButton = ({ intensity, setIntensity }) => {

    const cerleClass = (cercleNumber) => {
        if (cercleNumber > intensity) {
            return ("cercle cercle" + cercleNumber);
        } else if (cercleNumber === intensity) {
            return ("cercle cercle" + cercleNumber + " cercleSelected");
        } else {
            return ("cercle cercle" + cercleNumber + " cercleActivate");
        }
    };

    const lineClass = (lineNumber) => {
        if (lineNumber <= intensity) {
            return "line lineActivate";
        } else {
            return "line";
        }
    };

  return (
    <div className="workIntensitySection">
        <button className={cerleClass(1)} onClick={evt => setIntensity(1)}>{intensity===1 && 1}</button>
        <div className={lineClass(2)}></div>
        <button className={cerleClass(2)} onClick={evt => setIntensity(2)}>{intensity===2 && 2}</button>
        <div className={lineClass(3)}></div>
        <button className={cerleClass(3)} onClick={evt => setIntensity(3)}>{intensity===3 && 3}</button>
        <div className={lineClass(4)}></div>
        <button className={cerleClass(4)} onClick={evt => setIntensity(4)}>{intensity===4 && 4}</button>
        <div className={lineClass(5)}></div>
        <button className={cerleClass(5)} onClick={evt => setIntensity(5)}>{intensity===5 && 5}</button>
        <div className={lineClass(6)}></div>
        <button className={cerleClass(6)} onClick={evt => setIntensity(6)}>{intensity===6 && 6}</button>
    </div>
  );
}

export default WorkIntensityButton;
