import React, { useEffect, useContext } from 'react'
import { Link } from 'react-router-dom'
import './searchHorse.css'
import Header from '../Header_footer/Header';
import Localisation from '../common_section/Localisation'
import Checkbox from '../common/Checkbox'
import Pension from '../common_section/Pension'
import IdealHorse from '../common_section/IdealHorse'
import IdealOwner from '../common_section/IdealOwner'
import BudgetMensuel from '../common_section/BudgetMensuel'
import FloatingButton from '../common/FloatingButton'
import SlidingButton from '../common/SlidingButton'
import RadioCheck from '../common/RadioCheck'
import Axios from 'axios'
import usePosition from '../common_section/usePosition';
import Competition from '../common_section/Competition';
import { UserContext } from '../context/UserContext'
import { SearchHorseContext } from '../context/SearchHorseContext'
import Menu from '../Menu'


const SearchHorse = () => {

    //adsense array code slots
    const slotValues = ["6716510069", "1759696850", "3539682385"]

    // Chargement des informations de localisation du user dans le "UserContext" :
    const { userPosition, setUserPosition } = useContext(UserContext)

    // Chargement des informations de recherche dans le "SearchHorseContext" :
    const { searchHorses, setSearchHorses } = useContext(SearchHorseContext)

    const { latitude, longitude } = usePosition();
    // const [cityLocalisation, setCityLocalisation] = useState('')
    // // Récupération de l'ancienne ville pour le locale storage
    // localStorage.setItem('lastCitySaved',cityLocalisation);

    const loadAds = () => {
        try {
          if (typeof window !== "undefined") {
            (window.adsbygoogle = window.adsbygoogle || []).push({});
          }
        } catch (error) {
          console.log("adsense error", error.message);
        }
      };

    // Get the location from reverse geocoding
    const getLocation = () => {

        Axios
            .get(`https://nominatim.openstreetmap.org/reverse?format=jsonv2&lat=${latitude}&lon=${longitude}`)
            .then(res => setUserPosition({
                ...userPosition,
                user_postal_code: res.data.address.postcode
            }))
            .catch(err => console.log(err))
    }


    // Get full and set gps coordinates from postal code within UserContext
    const getCoordinatesfromPostalCode = (postalcode) => {
        Axios
            .get(`https://nominatim.openstreetmap.org/search?country=France&postalcode=${postalcode}&format=json`)
            .then((res) => {
                setUserPosition({
                    ...userPosition,
                    user_longitude: res.data[0].lon,
                    user_latitude: res.data[0].lat,
                    user_localisation: res.data[0].display_name,
                });
            })
            .catch((err) => console.log(err));
    };

    useEffect(() => {
        getLocation()
        loadAds()
    }, [latitude]);

    return (
        <>
            <Header className='header' title='Chercher un équidé' path='home' />
            <div className='searchHorse_page'>
                <div className='localisation-section'>
                    <Localisation
                        locTitle='Où ?'
                        value={userPosition.user_postal_code}
                        getLocation={getLocation}
                        onChange={(e) => setUserPosition({
                            ...userPosition,
                            user_postal_code: e.target.value
                        })}
                        definePerimeter={(e) => setUserPosition({
                            ...userPosition,
                            user_perimeter: e.target.value
                        })}
                        perimeter={userPosition.user_perimeter}
                    />

                    <button id="upload-button" onClick={() => {
                        getCoordinatesfromPostalCode(userPosition.user_postal_code)
                    }}>
                        Valider ma position
                </button>
                <p>{userPosition.user_localisation}</p>
                <ins
                    className="adsbygoogle"
                    style={{ display: "block", width: "250px", height: "50px"}}
                    data-ad-client="ca-pub-7709898933114568"
                    data-ad-slot={slotValues[0]}
                    data-ad-format="auto"
                    data-full-width-responsive="true">
                </ins>
                </div>


                <hr />
                <BudgetMensuel
                    budgetTitle='Budget'
                    budget={searchHorses.horse_budget}
                    currency={searchHorses.horse_currency_budget}
                    priceTitle={'Prix maximum par mois :'}
                    onChange={(e) => setSearchHorses({
                        ...searchHorses,
                        horse_budget: e.target.value
                    })}
                    onClick={(e) => setSearchHorses({
                        ...searchHorses,
                        horse_currency_budget: e.target.value
                    })} />
                <hr />
                <div className='disciplines'>
                    <h4>Disciplines </h4>
                    <div className='disciplineList'>
                        <Checkbox
                            CheckboxText='Obstacle'
                            CheckboxValue='Obstacle'
                            onClick={() => setSearchHorses({
                                ...searchHorses,
                                horse_disciplines:
                                    { ...searchHorses.horse_disciplines, Obstacle: !searchHorses.horse_disciplines.Obstacle }
                            })} />
                        <Checkbox
                            CheckboxText='Dressage'
                            CheckboxValue='Dressage'
                            onClick={() => setSearchHorses({
                                ...searchHorses,
                                horse_disciplines:
                                    { ...searchHorses.horse_disciplines, Dressage: !searchHorses.horse_disciplines.Dressage }
                            })} />
                        <Checkbox
                            CheckboxText='CCE'
                            CheckboxValue='CCE'
                            onClick={() => setSearchHorses({
                                ...searchHorses,
                                horse_disciplines:
                                    { ...searchHorses.horse_disciplines, CCE: !searchHorses.horse_disciplines.CCE }
                            })} />
                        <Checkbox
                            CheckboxText='TREC - Equifun'
                            CheckboxValue='TREC - Equifun'
                            onClick={() => setSearchHorses({
                                ...searchHorses,
                                horse_disciplines:
                                    { ...searchHorses.horse_disciplines, TREC_Equifun: !searchHorses.horse_disciplines.TREC_Equifun }
                            })} />
                        <Checkbox
                            CheckboxText='Balade - Randonnée'
                            CheckboxValue='Balade - Randonnée'
                            onClick={() => setSearchHorses({
                                ...searchHorses,
                                horse_disciplines:
                                    { ...searchHorses.horse_disciplines, Balade_Randonnee: !searchHorses.horse_disciplines.Balade_Randonnee }
                            })} />
                        <Checkbox
                            CheckboxText='Ethologie - Equifeel'
                            CheckboxValue='Ethologie - Equifeel'
                            onClick={() => setSearchHorses({
                                ...searchHorses,
                                horse_disciplines:
                                    { ...searchHorses.horse_disciplines, Ethologie_Equifeel: !searchHorses.horse_disciplines.Ethologie_Equifeel }
                            })} />
                        <Checkbox
                            CheckboxText='Hunter'
                            CheckboxValue='Hunter'
                            onClick={() => setSearchHorses({
                                ...searchHorses,
                                horse_disciplines:
                                    { ...searchHorses.horse_disciplines, Hunter: !searchHorses.horse_disciplines.Hunter }
                            })} />
                        <Checkbox
                            CheckboxText='Horse-Ball'
                            CheckboxValue='Horse-Ball'
                            onClick={() => setSearchHorses({
                                ...searchHorses,
                                horse_disciplines:
                                    { ...searchHorses.horse_disciplines, Horse_Ball: !searchHorses.horse_disciplines.Horse_Ball }
                            })} />
                        <Checkbox
                            CheckboxText='Pony-Games'
                            CheckboxValue='Pony-Games'
                            onClick={() => setSearchHorses({
                                ...searchHorses,
                                horse_disciplines:
                                    { ...searchHorses.horse_disciplines, Pony_Games: !searchHorses.horse_disciplines.Pony_Games }
                            })} />
                        <Checkbox
                            CheckboxText='Reining-Western'
                            CheckboxValue='Reining-Western'
                            onClick={() => setSearchHorses({
                                ...searchHorses,
                                horse_disciplines:
                                    { ...searchHorses.horse_disciplines, Reining_Western: !searchHorses.horse_disciplines.Reining_Western }
                            })} />
                        <Checkbox
                            CheckboxText='Endurance'
                            CheckboxValue='Endurance'
                            onClick={() => setSearchHorses({
                                ...searchHorses,
                                horse_disciplines:
                                    { ...searchHorses.horse_disciplines, Endurance: !searchHorses.horse_disciplines.Endurance }
                            })} />
                        <Checkbox
                            CheckboxText='Attelage'
                            CheckboxValue='Attelage'
                            onClick={() => setSearchHorses({
                                ...searchHorses,
                                horse_disciplines:
                                    { ...searchHorses.horse_disciplines, Attelage: !searchHorses.horse_disciplines.Attelage }
                            })} />
                        <Checkbox
                            CheckboxText='Voltige'
                            CheckboxValue='Voltige'
                            onClick={() => setSearchHorses({
                                ...searchHorses,
                                horse_disciplines:
                                    { ...searchHorses.horse_disciplines, Voltige: !searchHorses.horse_disciplines.Voltige }
                            })} />
                        <Checkbox
                            CheckboxText='Disciplines culturelles'
                            CheckboxValue='Disciplines culturelles'
                            onClick={() => setSearchHorses({
                                ...searchHorses,
                                horse_disciplines:
                                    { ...searchHorses.horse_disciplines, Disciplines_culturelles: !searchHorses.horse_disciplines.Disciplines_culturelles }
                            })} />
                    </div>
                </div>
                <hr />
                <ins
                    className="adsbygoogle"
                    style={{ display: "block", width: "300px", height: "100px"}}
                    data-ad-client="ca-pub-7709898933114568"
                    data-ad-slot={slotValues[1]}
                    data-ad-format="auto"
                    data-full-width-responsive="true">
                </ins>

                <div className='structures'>
                    <h4>Structure(s) disponible(s) </h4>
                    <div className='structureList'>
                        <Checkbox
                            CheckboxText="Carrière"
                            CheckboxValue='Carrière'
                            onClick={() => setSearchHorses({
                                ...searchHorses,
                                horse_practice_structure:
                                    { ...searchHorses.horse_practice_structure, Carrière: !searchHorses.horse_practice_structure.Carrière }
                            })} />
                        <Checkbox
                            CheckboxText="Manège couvert"
                            CheckboxValue='Manège_couvert'
                            onClick={() => setSearchHorses({
                                ...searchHorses,
                                horse_practice_structure:
                                    { ...searchHorses.horse_practice_structure, Manège_couvert: !searchHorses.horse_practice_structure.Manège_couvert }
                            })} />
                        <Checkbox
                            CheckboxText="Rond de longe"
                            CheckboxValue='Rond_de_longe'
                            onClick={() => setSearchHorses({
                                ...searchHorses,
                                horse_practice_structure:
                                    { ...searchHorses.horse_practice_structure, Rond_de_longe: !searchHorses.horse_practice_structure.Rond_de_longe }
                            })} />
                        <Checkbox
                            CheckboxText="Piste de trotting"
                            CheckboxValue='Piste_de_trotting'
                            onClick={() => setSearchHorses({
                                ...searchHorses,
                                horse_practice_structure:
                                    { ...searchHorses.horse_practice_structure, Piste_de_trotting: !searchHorses.horse_practice_structure.Piste_de_trotting }
                            })} />
                        <Checkbox
                            CheckboxText="Cross"
                            CheckboxValue='Cross'
                            onClick={() => setSearchHorses({
                                ...searchHorses,
                                horse_practice_structure:
                                    { ...searchHorses.horse_practice_structure, Cross: !searchHorses.horse_practice_structure.Cross }
                            })} />
                        <Checkbox
                            CheckboxText="Cheminsde balade"
                            CheckboxValue='Chemins_de_balade'
                            onClick={() => setSearchHorses({
                                ...searchHorses,
                                horse_practice_structure:
                                    { ...searchHorses.horse_practice_structure, Chemins_de_balade: !searchHorses.horse_practice_structure.Chemins_de_balade }
                            })} />
                        <Checkbox
                            CheckboxText="Champs"
                            CheckboxValue='Champs'
                            onClick={() => setSearchHorses({
                                ...searchHorses,
                                horse_practice_structure:
                                    { ...searchHorses.horse_practice_structure, Champs: !searchHorses.horse_practice_structure.Champs }
                            })} />
                    </div>
                </div>
                <hr />
                <div className='frequency_pension'>
                    <Pension
                        onClick={(e) => setSearchHorses({
                            ...searchHorses,
                            horse_riding_frequency: e.target.value
                        })}
                        frequency={searchHorses.horse_riding_frequency}
                        changeFixedFrequency={() => setSearchHorses({
                            ...searchHorses,
                            horse_fixed_day: !searchHorses.horse_fixed_day
                        })}
                        checked={searchHorses.horse_riding_frequency}
                        checked1={searchHorses.horse_fixed_day}
                    />
                </div>
                <hr />
                <div className='searchHorse_bal'>
                    <h4>Balade</h4>
                    <div className='balade'>
                        <SlidingButton
                            SlidingButtonText="Pouvoir partir seul en balade"
                            SlidingButtonID="baladSwitch"
                            onClick={() => setSearchHorses({
                                ...searchHorses,
                                horse_stroll_along: !searchHorses.horse_stroll_along
                            })}
                        />
                    </div>
                </div>
                <hr />
                <h4>Cheval idéal</h4>
                <IdealHorse
                    horseSize={searchHorses.horse_height}
                    changeSize={(e) => setSearchHorses({
                        ...searchHorses,
                        horse_height: e.target.value
                    })}
                    changeAge={(f) => setSearchHorses({
                        ...searchHorses,
                        horse_age: f.target.value
                    })}
                    ageHorse={searchHorses.horse_age}
                />
                <hr />

                <div className='horse_temper'>
                    <h5> Tempérament<span>(plusieurs choix possibles)</span></h5>
                    <div className='radioCheck-list'>
                        <div className="row1">
                            <RadioCheck
                                RadioCheckText="Calme"
                                value="Calme"
                                onClick={() => setSearchHorses({
                                    ...searchHorses,
                                    horse_temper: { ...searchHorses.horse_temper, Calme: !searchHorses.horse_temper.Calme }
                                })}
                            />

                            <RadioCheck
                                RadioCheckText="Dynamique"
                                value="Dynamique"
                                onClick={() => setSearchHorses({
                                    ...searchHorses,
                                    horse_temper: { ...searchHorses.horse_temper, Dynamique: !searchHorses.horse_temper.Dynamique }
                                })}
                            />
                        </div>
                        <div className='row2'>
                            <RadioCheck
                                RadioCheckText='Speed'
                                value='Speed'
                                onClick={() => setSearchHorses({
                                    ...searchHorses,
                                    horse_temper: { ...searchHorses.horse_temper, Speed: !searchHorses.horse_temper.Speed }
                                })}
                            />
                            <RadioCheck
                                RadioCheckText='A canaliser'
                                value='A_canaliser'
                                onClick={() => setSearchHorses({
                                    ...searchHorses,
                                    horse_temper: { ...searchHorses.horse_temper, Calme: !searchHorses.horse_temper.Calme }
                                })}
                            />
                        </div>
                    </div>
                </div>
                <hr />
                <div className='horse_caracter'>
                    <h5> Caractère<span>(plusieurs choix possibles)</span></h5>
                    <div className='radioCheck-list'>
                        <div className="row1">
                            <RadioCheck
                                RadioCheckText='Affectueux'
                                value='Affectueux'
                                onClick={() => setSearchHorses({
                                    ...searchHorses,
                                    horse_character: { ...searchHorses.horse_character, Affectueux: !searchHorses.horse_character.Affectueux }
                                })}
                            />
                            <RadioCheck
                                RadioCheckText='Froid'
                                value='Froid'
                                onClick={() => setSearchHorses({
                                    ...searchHorses,
                                    horse_character: { ...searchHorses.horse_character, Froid: !searchHorses.horse_character.Froid }
                                })}
                            />
                        </div>
                        <div className="row2">
                            <RadioCheck
                                RadioCheckText='Joueur'
                                value='Joueur'
                                onClick={() => setSearchHorses({
                                    ...searchHorses,
                                    horse_character: { ...searchHorses.horse_character, Joueur: !searchHorses.horse_character.Joueur }
                                })}
                            />
                            <RadioCheck
                                RadioCheckText='Sensible'
                                value='Sensible'
                                onClick={() => setSearchHorses({
                                    ...searchHorses,
                                    horse_character: { ...searchHorses.horse_character, Sensible: !searchHorses.horse_character.Sensible }
                                })} />
                        </div>
                    </div>
                </div>
                <hr />
                <div className='horse_body'>
                    <h5> Physique<span>(plusieurs choix possibles)</span></h5>
                    <div className='radioCheck-list'>
                        <div className="row1">
                            <RadioCheck
                                RadioCheckText='Fin'
                                value='Fin'
                                onClick={() => setSearchHorses({
                                    ...searchHorses,
                                    horse_body_type: { ...searchHorses.horse_body_type, Fin: !searchHorses.horse_body_type.Fin }
                                })}
                            />
                            <RadioCheck
                                RadioCheckText='Classique'
                                value='Classique'
                                onClick={() => setSearchHorses({
                                    ...searchHorses,
                                    horse_body_type: { ...searchHorses.horse_body_type, Classique: !searchHorses.horse_body_type.Classique }
                                })}
                            />
                        </div>
                        <div className="row2">
                            <RadioCheck
                                RadioCheckText='Porteur'
                                value='Porteur'
                                onClick={() => setSearchHorses({
                                    ...searchHorses,
                                    horse_body_type: { ...searchHorses.horse_body_type, Porteur: !searchHorses.horse_body_type.Porteur }
                                })}
                            />
                            <RadioCheck
                                RadioCheckText='Lourd'
                                value='Lourd'
                                onClick={() => setSearchHorses({
                                    ...searchHorses,
                                    horse_body_type: { ...searchHorses.horse_body_type, Lourd: !searchHorses.horse_body_type.Lourd }
                                })}
                            />
                        </div>
                    </div>
                </div>

                <hr />
                <ins
                    className="adsbygoogle"
                    style={{ display: "block", width: "300px", height: "100px"}}
                    data-ad-client="ca-pub-7709898933114568"
                    data-ad-slot={slotValues[2]}
                    data-ad-format="auto"
                    data-full-width-responsive="true">
                </ins>

                <h4>Propriétaire idéal</h4>
                <div className="rider_caracter">
                    <h5> Caractère :</h5>
                    <div className="select_caracter">
                        <div className="row1">
                            <RadioCheck
                                RadioCheckText="Introverti"
                                value="Introverti"
                                onClick={() => setSearchHorses({
                                    ...searchHorses,
                                    owner_character: { ...searchHorses.owner_character, Introverti: !searchHorses.owner_character.Introverti }
                                })}
                            />
                            <RadioCheck
                                RadioCheckText="Sociable"
                                value="Sociable"
                                onClick={() => setSearchHorses({
                                    ...searchHorses,
                                    owner_character: { ...searchHorses.owner_character, Sociable: !searchHorses.owner_character.Sociable }
                                })}
                            />
                        </div>
                        <div className="row1">
                            <RadioCheck
                                RadioCheckText="Extraverti"
                                value="Extraverti"
                                onClick={() => setSearchHorses({
                                    ...searchHorses,
                                    owner_character: { ...searchHorses.owner_character, Extraverti: !searchHorses.owner_character.Extraverti }
                                })}
                            />
                            <RadioCheck
                                RadioCheckText="Solitaire"
                                value="Solitaire"
                                onClick={() => setSearchHorses({
                                    ...searchHorses,
                                    owner_character: { ...searchHorses.owner_character, Solitaire: !searchHorses.owner_character.Solitaire }
                                })}
                            />
                        </div>
                    </div>
                </div>
                <hr />
                <IdealOwner
                    ageOwner={searchHorses.owner_age}
                    selectAge={(e) => setSearchHorses({
                        ...searchHorses,
                        owner_age: e.target.value
                    })}
                    frequency={searchHorses.owner_communication_frequency}
                    selectFrequency={(e) => setSearchHorses({
                        ...searchHorses,
                        owner_communication_frequency: e.target.value
                    })}
                    rootComponent='searchHorse'

                    selectHorseWork={(e) => setSearchHorses({
                        ...searchHorses,
                        owner_horse_work: e.target.value
                    })}
                    horseWork={searchHorses.owner}
                />

                <h4>Type d'écurie</h4>
                <div className="scuring">
                    <h5>Plusieurs choix possibles</h5>
                    <div className="radioCheck-list">
                        <div className='row1'>
                            <RadioCheck
                                RadioCheckText="Poney Club"
                                value="Poney club"
                                onClick={() => setSearchHorses({
                                    ...searchHorses,
                                    horse_location_type: { ...searchHorses.horse_location_type, Poney_club: !searchHorses.horse_location_type.Poney_club }
                                })}
                            />
                            <RadioCheck
                                RadioCheckText="Centre équestre"
                                value="Centre équestre"
                                onClick={() => setSearchHorses({
                                    ...searchHorses,
                                    horse_location_type: { ...searchHorses.horse_location_type, Centre_équestre: !searchHorses.horse_location_type.Centre_équestre }
                                })}
                            />
                        </div>
                        <div className='row1'>
                            <RadioCheck
                                RadioCheckText="Ecurie de propiétaire"
                                value="Ecurie de propiétaire"
                                onClick={() => setSearchHorses({
                                    ...searchHorses,
                                    horse_location_type: { ...searchHorses.horse_location_type, Ecurie_du_propriétaire: !searchHorses.horse_location_type.Ecurie_du_propriétaire }
                                })}
                            />

                            <RadioCheck
                                RadioCheckText="Chez un particulier"
                                value="Chez un particulier"
                                onClick={() => setSearchHorses({
                                    ...searchHorses,
                                    horse_location_type: { ...searchHorses.horse_location_type, Chez_un_particulier: !searchHorses.horse_location_type.Chez_un_particulier }
                                })}
                            />
                        </div>
                        <div className='row1'>
                            <RadioCheck
                                RadioCheckText="Elevage"
                                value="Elevage"
                                onClick={() => setSearchHorses({
                                    ...searchHorses,
                                    horse_location_type: { ...searchHorses.horse_location_type, Elevage: !searchHorses.horse_location_type.Elevage }
                                })}
                            />
                            <RadioCheck
                                RadioCheckText="Autre"
                                value="Autre"
                                onClick={() => setSearchHorses({
                                    ...searchHorses,
                                    horse_location_type: { ...searchHorses.horse_location_type, Autre: !searchHorses.horse_location_type.Autre }
                                })}
                            />
                        </div>
                    </div>
                </div>
                <hr />
                <div className="hebergement">
                    <h5>Type d'hébergement </h5>
                    <div className="radioCheck-list">
                        <div className="row1">
                            <RadioCheck
                                RadioCheckText="Boxe"
                                value="Boxe"
                                onClick={() => setSearchHorses({
                                    ...searchHorses,
                                    horse_accomodation: { ...searchHorses.horse_accomodation, Boxe: !searchHorses.horse_accomodation.Boxe }
                                })}
                            />
                            <RadioCheck
                                RadioCheckText="Paddock"
                                value="Paddock"
                                onClick={() => setSearchHorses({
                                    ...searchHorses,
                                    horse_accomodation: { ...searchHorses.horse_accomodation, Paddock: !searchHorses.horse_accomodation.Paddock }
                                })}
                            />
                        </div>
                        <div className="row1">
                            <RadioCheck
                                RadioCheckText="Boxe & Paddock"
                                value="Boxe_paddock"
                                onClick={() => setSearchHorses({
                                    ...searchHorses,
                                    horse_accomodation: { ...searchHorses.horse_accomodation, Boxe_paddock: !searchHorses.horse_accomodation.Boxe_paddock }
                                })}
                            />
                            <RadioCheck
                                RadioCheckText="Stabulation"
                                value="Stabulation"
                                onClick={() => setSearchHorses({
                                    ...searchHorses,
                                    horse_accomodation: { ...searchHorses.horse_accomodation, Stabulation: !searchHorses.horse_accomodation.Stabulation }
                                })}
                            />
                        </div>
                        <div className="row1">
                            <RadioCheck
                                RadioCheckText="Pature"
                                value="Pature"
                                onClick={() => setSearchHorses({
                                    ...searchHorses,
                                    horse_accomodation: { ...searchHorses.horse_accomodation, Pature: !searchHorses.horse_accomodation.Pature }
                                })}
                            />
                            <RadioCheck
                                RadioCheckText="Autre"
                                value="Autre"
                                onClick={() => setSearchHorses({
                                    ...searchHorses,
                                    horse_accomodation: { ...searchHorses.horse_accomodation, Autre: !searchHorses.horse_accomodation.Autre }
                                })}
                            />
                        </div>
                    </div>
                </div>
                <hr />
                <div className='coachingDiv'>
                    <h4>Coaching</h4>
                    <div className='coaching'>
                        <SlidingButton
                            SlidingButtonText="Sur place"
                            SlidingButtonID="coachSwitch"
                            onClick={() => setSearchHorses({
                                ...searchHorses,
                                horse_coaching_here: !setSearchHorses.horse_coaching_here
                            })}
                        />
                    </div>
                    <div className='coaching'>
                        <SlidingButton
                            SlidingButtonText="Intervenant exterieur"
                            SlidingButtonID="coachExtSwitch"
                            onClick={() => setSearchHorses({
                                ...searchHorses,
                                horse_external_coach: !searchHorses.horse_external_coach
                            })}
                        />
                    </div>

                </div>
                <hr />
                <div className='materialDiv'>
                    <h4>Materiel</h4>
                    <div className='materiel'>
                        <SlidingButton
                            SlidingButtonText="J'ai ma selle"
                            SlidingButtonID="materialSwitch"
                            onClick={() => setSearchHorses({
                                ...searchHorses,
                                horse_material: !searchHorses.horse_material
                            })}
                        />
                    </div>

                </div>
                <hr />
                <div className='competition'>
                    <Competition
                        onClick={(e) => setSearchHorses({
                            ...searchHorses,
                            owner_competition_preferences: !searchHorses.owner_competition_preferences
                        })}
                        checked={searchHorses.owner_competition_preferences} />
                </div>
            </div>
            <Link to={{ pathname: "/horse/results" }}>
                <FloatingButton
                    btnName={'Lancer la recherche'}
                />
            </Link>
            <Menu />
        </>
    )
}

export default SearchHorse
