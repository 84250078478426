import React, { useState, useRef } from 'react';
import "./event.css";
import micro from "../../SVG-icons/mic-icon.svg";
import trash from "../../SVG-icons/trash.svg";
import MicRecorder from 'mic-recorder-to-mp3';

const Audio = ({ audios, setAudios, deleteAudios, setDeleteAudios }) => {
    
    const [renderAgain, setrenderAgain] = useState(false);
    const [recording, setRecording] = useState(false);

    const recorder  = useRef(new MicRecorder({ bitRate: 128 }));

    if (renderAgain) {
        setrenderAgain(false);
    }

    const addAudio = (audio_id, blob) => {
        if (audios.map(x => Number(x.fileName.slice(0, x.fileName.indexOf('.')))).includes(audio_id)) { // Si l'audio_id existe déjà
            addAudio(audio_id+1, blob);
        } else {
            setAudios([ ...audios, { fileName: audio_id+".mp3", data: blob, url: URL.createObjectURL(blob) }]);
        }
    };

    const startAudio = () => {
        recorder.current
        .start()
        .then(() => {
            console.log("début de l'enregistrement");
            setRecording(true);
            /* setrenderAgain(true); */
        })
        .catch((err) => console.error(err));
    };

    const stopAudio = () => {
        recorder.current
        .stop()
        .getMp3()
        .then(([buffer, blob]) => {
            setRecording(false);
            console.log("Fin de l'enregistrement !");
            if (audios.length < 6) {
                const today = new Date();
                addAudio(today.getTime(), blob);
            } else {
                console.log("Il ne peut y avoir plus de 5 audios !");
            }
        })
        .catch((err) => console.log(err));
    };

    const deleteAudio = (fileName) => {
        for (var audioIndex in audios) {
            var audio = audios[audioIndex];
            if (audio.fileName === fileName) {
                if (!audio.data) { // Si l'audio n'a pas été enregitré en local et vient donc du serveur
                    console.log('ok pour la suppression');
                    setDeleteAudios([ ...deleteAudios, fileName]);
                }
                audios.splice(audioIndex, 1);
                setAudios(audios);
                break;
            }
        }
        setrenderAgain(true);
    };

    
    return(
        <div className="recordSection">
            <button 
                className={recording ? "recordFrame recordingButton" : "recordFrame"}
                onClick={recording ? stopAudio : startAudio}
               /*  onMouseDown={startAudio}
                onMouseUp={stopAudio}
                onTouchStart={startAudio}
                onTouchEnd={stopAudio} */
            >
                <img className="mic_icon" src={micro} alt="micro icon" />
                {recording ? "Cliquez pour stoper l'enregistrement" : "Cliquez pour lancer l'enregistrement"}
            </button>

            {audios.map(({ fileName, url }) => (
                <div key ={fileName} className="audioSections" >
                    <audio src={url} controls="controls" />
                    <button onClick={e => deleteAudio(fileName)}>
                        <img src={trash} alt="trash" />
                    </button>
                </div>
            ))}
        </div>
    );
};


export default Audio;