import { createContext } from 'react'

export const contractStateContext = {
    owner: {
        firstName: '',
        lastName: '',
        address: '',
        postalCode: '',
        city: '',
    },

    rider: {
        firstName: '',
        lastName: '',
        address: '',
        postalCode: '',
        city: '',
    },

    horse: {
        name: '',
        SIRE: '',
    },

    horseFields: {
        obstacle: false,
        dressage: false,
        CCE: false,
        TREC: false,
        balade: false,
        ethologie: false,
        hunter: false,
        horseBall: false,
        ponyGames: false,
        reiningWestern: false,
        endurance: false,
        attelage: false,
        voltige: false,
        disciplinesCulturelles: false,
    },

    balad: false,
    dangerous: true,

    institution: {
        name: '',
        address: '',
        postalCode: '',
        city: '',
        contactIsMan: true,
        contactName: '',
        contactNumber: '',
    },

    knowledgeOfVisitDays: true,

    visit_frequency: 5,

    visitDays: {
        monday: false,
        tuesday: false,
        wednesday: false,
        thursday: false,
        friday: false,
        saturday: false,
        sunday: false,
    },

    communicationDay: "lundi",

    insurances: {
        civile: false,
        mortalite: false,
        depreciation: false,
        immobilisation: false,
    },

    sellerie: false,

    payment: {
        price: 0,
        date: "",
        wayOfPayment: "virement",
        conserveCharge: false,
        veterinaireCost: 0,
        marechalCost: 0,
        osteopathieCost: 0,
        dentisteCost: 0,
    },

    warrantyExtension: "",

    disuse: {
        freesDate: "",
        preavisDate: "",
    },

    period: "",
    
    complement: "",
}

export const ContractContext = createContext(null)
