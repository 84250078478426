import React, { useState } from 'react';
import "./organise.css";
import { useHistory } from 'react-router-dom';
import CustomInput from '../common/CustomInput';
import Axios from "axios";


const { REACT_APP_API_URL } = process.env;


const ListCalendars = () => {

    const token = localStorage.token;
    const history = useHistory();

    const [calendarCode, setCalendarCode] = useState("");
    const [calendars, setCalendars] = useState([]);

    const [firstLoading, setFirstLoading] = useState(true);

    if (firstLoading) {
        Axios.get(`${REACT_APP_API_URL}/api/calendar/all`, {
            headers : { 'Authorization' : 'Bearer ' + token}})
        .then((res) => {
            setCalendars(res.data);
        })
        .catch((err) => console.log(err));
        setFirstLoading(false);
    }

    const addCalendar = (evt) => {
        Axios.post(`${REACT_APP_API_URL}/api/calendar/usercalendar`, {code: calendarCode}, {
            headers : { 'Authorization' : 'Bearer ' + token}})
        .then(res => setFirstLoading(true))
        .catch((err) => console.log(err));
    };

    return(
        <>

            <h5>Ajouter un calendrier :</h5>
            <CustomInput placeholder="Code du calendrier" value={calendarCode} onChange={(evt) => {setCalendarCode(evt.currentTarget.value)}}/>
            <p></p>
            <button className="greenButton" id="button1" onClick={addCalendar}>
                Ajouter
            </button>
            <hr/>

            {calendars.length !== 0 ? (
                <>
                    <h4>Mes calendriers :</h4>
                    <hr/>
                </>
            ) : (
                <h4>Vous n'avez pas encore de calendrier partagé.</h4>
            )}

            {calendars.map(({ calendar_ID, code, horse }) => (
                <div key={calendar_ID}>
                    <div className="oneCalendarButton">
                        <button className="greenFrame3" onClick={evt => history.push("/organise/"+code)}>{horse + " " + code}</button>
                        <svg width="2em" height="2em" viewBox="0 0 16 16" className="bi bi-chevron-right" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                            <path fillRule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"/>
                        </svg>
                    </div>
                    <hr/>
                </div>
            ))}
            
        </>
    );
};


export default ListCalendars;