import React, { useState, useContext } from 'react';
import { HashLink } from 'react-router-hash-link';
import { ContractContext } from "../context/ContractContext";
import "./Contract.css";
import Header from "../Header_footer/Header";
import RadioButton from '../common/RadioButton'
import FloatingButton from '../common/FloatingButton'

const ContractVisitFrequency = () => {

    const { contractState, setContractState } = useContext(ContractContext);
    const visit_frequency = contractState.visit_frequency;

    const [freq5, setFreq5] = useState(visit_frequency===5);
    const [freq3, setFreq3] = useState(visit_frequency===3);
    const [freq2, setFreq2] = useState(visit_frequency===2);
    const [freq1, setFreq1] = useState(visit_frequency===1);

    const updateContract = () => {
        if (freq1) {
            setContractState({ ...contractState, visit_frequency: 1 });
        } else if (freq2) {
            setContractState({ ...contractState, visit_frequency: 2 });
        } else if (freq3) {
            setContractState({ ...contractState, visit_frequency: 3 });
        } else {
            setContractState({ ...contractState, visit_frequency: 4 });
        }
    };

    const changeFreq = (evt) => {
        const buttonId = evt.currentTarget.id;

        if (buttonId === "freq1") {
            setFreq1(true);
            setFreq2(false);
            setFreq3(false);
            setFreq5(false);
        } else if (buttonId === "freq2") {
            setFreq1(false);
            setFreq2(true);
            setFreq3(false);
            setFreq5(false);
        } else if (buttonId === "freq3") {
            setFreq1(false);
            setFreq2(false);
            setFreq3(true);
            setFreq5(false);
        } else if (buttonId === "freq5") {
            setFreq1(false);
            setFreq2(false);
            setFreq3(false);
            setFreq5(true);
        }
    };

    return(
        <>
            <Header className="header" title="FREQUENCE DE VENUE" hash='/contract#visitFrequency' />
            <div className='contract_page'>
                
                <h4>Fréquence de venue du cavalier :</h4>

                <div className='select_compet'>
                    <RadioButton
                        radioButtonText="5 à 7 fois / semaine"
                        radioButtonName="visit_frequency"
                        radioButtonId="freq5"
                        onClick={changeFreq}
                        checked={freq5}
                    />
                    <RadioButton
                        radioButtonText="3 à 4 fois / semaine"
                        radioButtonName="visit_frequency"
                        radioButtonId="freq3"
                        onClick={changeFreq}
                        checked={freq3}
                    />
                    <RadioButton
                        radioButtonText="2 fois / semaine"
                        radioButtonName="visit_frequency"
                        radioButtonId="freq2"
                        onClick={changeFreq}
                        checked={freq2}
                    />
                    <RadioButton
                        radioButtonText="1 fois / semaine"
                        radioButtonName="visit_frequency"
                        radioButtonId="freq1"
                        onClick={changeFreq}
                        checked={freq1}
                    />
                </div>

                <HashLink to="/contract#visitFrequency" style={{ textDecoration: "none" }}>
                    <FloatingButton btnName='Valider' onClick={updateContract}/>
                </HashLink>
            
            </div>
        </>
    )
}


export default ContractVisitFrequency;