import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import "./calendar.css";
import Menu from '../Menu'

const Calendar = ({ code, events, eventsInOneDay, horse }) => {
  const history = useHistory();
  const today = new Date();

  const [month, setMonth] = useState(today.getMonth());
  const [year, setYear] = useState(today.getFullYear());

  const nextMonth = (evt) => {
    if (month === 11) {
      setMonth(0);
      setYear(year + 1);
    } else {
      setMonth(month + 1);
    }
  };

  const prevMonth = (evt) => {
    if (month === 0) {
      setMonth(11);
      setYear(year - 1);
    } else {
      setMonth(month - 1);
    }
  };

  const firstDate = () => {
    // Find the firstDate of the calendar
    let date = new Date(year, month, -5);
    while (true) {
      if (date.getDay() === 1) {
        return date;
      } else {
        date.setDate(date.getDate() + 1);
      }
    }
  };

  const lastDate = () => {
    // Find the lastDate of the calendar
    let date = new Date(year, month + 1, 0);
    while (true) {
      if (date.getDay() === 0) {
        return date;
      } else {
        date.setDate(date.getDate() + 1);
      }
    }
  };

  const customDates = () => {
    let date = firstDate();
    let dates = [];
    while (date.getTime() != (lastDate().getTime() + 24 * 3600 * 1000)) {
      let className = "";
      if (date.getFullYear() !== year || date.getMonth() !== month) {
        className = "other-month";
      } else if (date.getFullYear() === today.getFullYear() && date.getMonth() === today.getMonth() && date.getDate() === today.getDate()) {
        className = "today";
      }

      for (let eventDate of events) {
        if (date.getTime() === eventDate.date.getTime()) {
          className += " event" + eventDate.intensity;
          break;
        }
      }

      if (!className) {
        className = null;
      }

      dates.push({
        className,
        date: new Date(date.getTime()),
      });
      date.setDate(date.getDate() + 1);
    }
    return dates;
  };

  const months = [
    "Janvier",
    "Février",
    "Mars",
    "Avril",
    "Mai",
    "Juin",
    "Juillet",
    "Août",
    "Septembre",
    "Octobre",
    "Novembre",
    "Décembre",
  ];


  return (

    <div className="calendar">
      <div className="month">

        <button onClick={prevMonth}>
          <svg width="2em" height="2em" viewBox="0 0 16 16" className="bi bi-chevron-left" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z" />
          </svg>
        </button>

        <div className="date">
          <h1>{months[month] + " " + year}</h1>
        </div>

        <button onClick={nextMonth}>
          <svg width="2em" height="2em" viewBox="0 0 16 16" className="bi bi-chevron-right" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z" />
          </svg>
        </button>

      </div>

      <div className="weekdays">
        <div>L</div>
        <div>M</div>
        <div>M</div>
        <div>J</div>
        <div>V</div>
        <div>S</div>
        <div>D</div>
      </div>

      <div className="days">
        {customDates().map(({ className, date }) => (
          <button className={className} key={date.getTime()} onClick={evt => history.push("/organise/" + code + "/" + date.getTime(), { events: eventsInOneDay(date), horse })}>
            <p>{date.getDate()}</p>
            <div className="cercle"></div>
          </button>
        ))}
      </div>

      <Menu />
    </div>

  );
};

export default Calendar;