import React from "react";
import "./CustomInput.css";

const CustomInput = ({ value, placeholder, onChange, id }) => (
    <input 
        min="0" 
        max="200"
        className='customInput'
        type='text'
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        id={id}
    />
)
  
export default CustomInput;
  