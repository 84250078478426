import { createContext } from 'react'

export const horseProfileContext = {

    horse_name : '',
    horse_age : null,
    horse_biography : '',
    horse_height : null,
    horse_temper : '',
    horse_character : '',
    horse_body_type : '',
    horse_postal : '',
    horse_localisation : '',
    horse_long : null,
    horse_lat : null,
    horse_geolocation : null,
    horse_photo1 : '',
    horse_photo2 : '',
    horse_photo3 : '',
    horse_budget : null,
    horse_currency_budget : '',
    horse_other_fees : null,
    horse_location_type : '',
    horse_accomodation : '',
    horse_practice_structure : {
        Carriere : false,
        Manege_couvert : false, 
        Rond_de_longe : false, 
        Piste_de_trotting : false, 
        Cross : false, 
        Chemins_de_balade: false, 
        Champs : false, 
    },
    horse_disciplines : {
        Obstacle : false,
        Dressage : false,
        CCE : false,
        TREC_Equifun : false,
        Balade_Randonnee : false,
        Ethologie_Equifeel : false,
        Hunter : false,
        Horse_Ball : false,
        Pony_Games : false,
        Reining_Western : false,
        Endurance : false,
        Attelage : false,
        Voltige : false,
        Disciplines_culturelles : false
    },
    horse_stroll_along : 0,
    horse_competition_preferences : '',
    horse_riding_frequency : '',
    horse_fixed_day : 0,
    horse_coaching_here : 0,
    horse_external_coach : 0,
    horse_rider_saddle : false,
    horse_material : false,
    ideal_rider_years_of_practice: null,
    ideal_rider_gallop_level : null,
    ideal_rider_age : null,
    ideal_rider_vehiculed : false,
    ideal_rider_managed_horse: false,
    owner_firstname : '',
    owner_age : null,
    owner_character : '',
    owner_communication_frequency : '',
    owner_horse_work : '',
    owner_message : '',
    user_ID : null,

}

export const HorseContext = createContext(null)
