import React, { useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import Header from '../Header_footer/Header'
import './calculate.css'
import Menu from '../Menu'

const CalculateMarechal = () => {

    const history = useHistory();
    const location = useLocation();
    const state = location.state;

    const initRiderPrices = () => {
        var result = [];
        for (var rider of state.riders) {
            var value = rider.marechal;
            if (value === undefined) {
                if (rider.id === 0) {
                    value = "100";
                } else {
                    value = "0";
                }
            } else {
                value = String(value);
            }
            result.push({ id: rider.id, name: rider.name, value });
        }
        return result;
    };

    const [number, setNumber] = useState(state.marechal === undefined ? "" : String(state.marechal.number));
    const [price, setPrice] = useState(state.marechal === undefined ? "" : String(state.marechal.price));
    const [riderPrices, setRiderPrices] = useState(initRiderPrices());

    const changeInputValue = (id, value) => {
        var newRiderPrices = [...riderPrices];
        newRiderPrices[id] = { ...riderPrices[id], value };
        setRiderPrices(newRiderPrices);
    };

    const nextPage = (evt) => {
        if (summationIs100()) {
            var newRiders = [];
            for (var rider of state.riders) {
                newRiders.push({ ...rider, marechal: Number(riderPrices[rider.id].value) });
            }
            const newState = { ...state, marechal: { number: Number(number), price: Number(price) }, riders: newRiders };
            history.push("/calculate/veterinaire", newState);
        }
    };

    const lastPage = (evt) => {
        var newRiders = [];
        for (var rider of state.riders) {
            newRiders.push({ ...rider, marechal: Number(riderPrices[rider.id].value) });
        }
        const newState = { ...state, marechal: { number: Number(number), price: Number(price) }, riders: newRiders };
        history.push("/calculate/pension", newState);
    };

    const summationIs100 = () => {
        var summation = 0;
        for (var riderPrice of riderPrices) {
            summation += Number(riderPrice.value);
        }
        return summation === 100;
    }


    return (
        <>
            <Header className="header" title="PRIX ET PARTICIPATIONS" path='home' />
            <div className="calculate_page">
                <h5>Maréchal ferrant</h5>

                <p>Nombre de visites :</p>
                <div className="visitNbrField">
                    <input
                        type="number"
                        id="visitNbrInput"
                        value={number}
                        onChange={evt => setNumber(evt.currentTarget.value)}
                    />
                    <div className="bar"></div>
                    <label className="visitNbrLabel" htmlFor="visitNbrInput">fois par an</label>
                </div>

                <p>Prix par visite :</p>
                <div className="eurosField">
                    <label htmlFor="eurosInput">€</label>
                    <input
                        type="number"
                        id="eurosInput"
                        value={price}
                        onChange={evt => setPrice(evt.currentTarget.value)}
                    />
                </div>
                <hr />

                <h5>Participation des cavaliers :</h5>
                {riderPrices.map(({ id, name, value }) => (
                    <div className="percentField" key={id}>
                        <label className="percentFieldLabel" htmlFor={"input_" + id}>{name}</label>
                        <div className="bar"></div>
                        <label className="percentFieldLabel2" htmlFor={"input_" + id}>%</label>
                        <input
                            type="number"
                            id={"input_" + id}
                            placeholder="%"
                            value={value}
                            onChange={evt => changeInputValue(id, evt.currentTarget.value)}
                        />
                    </div>
                ))}

                {!summationIs100() && <p className='error'>Attention : la somme des chiffres ne vaut pas 100 !</p>}

                <div className="finalButtons">
                    <div className="PrevArrowButton">
                        <svg width="1em" height="1em" viewBox="0 0 16 16" className="bi bi-chevron-left" fillRule="#5d5aa4" xmlns="http://www.w3.org/2000/svg">
                            <path fillRule="evenodd" d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z" />
                        </svg>
                        <button onClick={lastPage}>
                            Précédent
                        </button>
                    </div>
                    <div className="NextArrowButton">
                        <svg width="1em" height="1em" viewBox="0 0 16 16" className="bi bi-chevron-right" fillRule="#5d5aa4" xmlns="http://www.w3.org/2000/svg">
                            <path fillRule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z" />
                        </svg>
                        <button onClick={nextPage}>
                            Suivant
                        </button>
                    </div>
                </div>
            </div>
            <Menu />
        </>
    )
}

export default CalculateMarechal;