import React, { useState, useEffect } from "react";
import "./profile.css";
import Header from "../Header_footer/Header";
import Axios from "axios";
import { Link } from "react-router-dom";
import HorseResultCard from "../Horse_Results/HorseResultCard";
import ResultCard from "../Rider_Results/ResultCard";
import Menu from "../Menu"

const { REACT_APP_API_URL } = process.env;

const MyProfile = () => {

  const defaultAvatar = 'https://static.wixstatic.com/media/8fa7e6_09cf11c3e4584b259145ecc0b2633997.jpg/v1/fill/w_224,h_224,al_c,lg_1,q_80/8fa7e6_09cf11c3e4584b259145ecc0b2633997.webp'


  // User Data storage:
  const [dataUser, setDataUser] = useState({
    user_lastname: "",
    user_firstname: "",
    user_email: "",
    user_password: "",
    user_avatar: null,
    user_phone: "",
    user_ID: ''
  })
  const token = localStorage.token
  const [login, setLogin] = useState(false);
  // Get user profil
  const getMyProfile = () => {
    Axios.get(`${REACT_APP_API_URL}/api/users/profile`, {
      headers: { 'Authorization': 'Bearer ' + token }
    })
        .then((res) => setDataUser(res.data))
        .catch((error) => console.log(error))
  }
  // Update user data information
  const updateMyProfile = () => {
    Axios
        .put(`${REACT_APP_API_URL}/api/users/profile`, dataUser,
            {
              headers: { 'Authorization': 'Bearer ' + token }
            })
        .catch(err => console.error(err))
  }

  //annonces horseriders or horses in favorite section :
  const getRiderPosts = () => {
    Axios
        .get(`${REACT_APP_API_URL}/api/users/mypost/rider`, {
          headers: { 'Authorization': 'Bearer ' + token }
        })
        .then((res) => setRiderAnnonce(res.data))
        .catch((err) => console.error(err));
    console.log(riderAnnonce);
  };

  const getHorsePosts = () => {
    Axios
        .get(`${REACT_APP_API_URL}/api/users/mypost/horse`, {
          headers: { 'Authorization': 'Bearer ' + token }
        })
        .then((res) => setHorseAnnonce(res.data))
        .catch((err) => console.error(err));
    console.log(horseAnnonce);
  };

  const [riderAnnonce, setRiderAnnonce] = useState([]);
  const [horseAnnonce, setHorseAnnonce] = useState([]);


  // Management of image upload :
  const [image, setImage] = useState(null);
  const handleChange = (e) => {
    if (e.target.files[0]) {
      setImage(e.target.files[0]);
    }
  };
  // User want to modify profile or not, display page will change :
  const [modif, setModif] = useState(false);
  const modifprof = () => {
    setModif(!modif);
  };
  const validprof = () => {
    setModif(!modif);
  };

  // Check password confirmation :
  const [validPW, setValidPW] = useState(null);

  // Start to catch user info and then, save it in the user context
  useEffect(() => {
    getMyProfile()
    getRiderPosts()
    getHorsePosts()
  }, []);

  const handleUpload = () => {
    var datas = new FormData();
    datas.append('image', image, image.name);
    Axios
        .post(`${REACT_APP_API_URL}/api/users/change-avatar`, datas, { headers: { 'content-type': 'multipart/form-data', 'Authorization': 'Bearer ' + token } })
        .then(res => setDataUser({ ...dataUser, user_avatar: res.data.url }))
        .catch(err => console.log(err));
  };

  const logout = () => {
    localStorage.removeItem("token");
    setLogin(true);
  };

  return (
      <>
        <Header className="header" title="Mon Profil" path='home' />

        {token === undefined ?
            <div className="Profil-Page">
              <div className="login_page">
                <p style={{ 'text-align': 'center' }}>Vous devez être connecté(e) pour accéder à votre profil.</p>
                <div >
                  <Link to='/login' style={{ textDecoration: "none" }}>
                    <button type='button' id='loginBtn' > Se connecter </button>
                  </Link>
                </div>
                <p style={{ 'text-align': 'center' }}>Pas encore de compte ? Créer un compte gratuitement</p>
                <div >
                  <Link to='/register' style={{ textDecoration: "none" }}>
                    <button type='button' id='createBtn' > Créer un compte </button>
                  </Link>
                </div>
              </div>
            </div>
            :

            <div className="Profile_global">
              <div className="Profile-Page">
                <div className="Profile-row">
                  {modif ? (
                      <div className="Profile-image">
                        <div className="buttonValidate">
                          <input id='chooseFile' type="file" onChange={handleChange} />
                          <button id="upload-button" onClick={async () => {
                            await handleUpload()
                            await updateMyProfile()
                          }} >
                            Valider la photo</button>
                        </div>
                        <img
                            src={dataUser.user_avatar !== null ? dataUser.user_avatar : defaultAvatar}
                            className="Profile-photo"
                            alt="Ajouter"
                        />
                      </div>
                  ) : (
                      <div className="Profile-image">
                        <img
                            src={dataUser.user_avatar !== null ? dataUser.user_avatar : defaultAvatar}
                            className="Profile-photo"
                            alt="Ajouter"
                        />
                      </div>
                  )}
                  <p className="Profile-infos">
                    {dataUser.user_firstname} {dataUser.user_lastname}
                  </p>
                </div>
                <hr />
                <h4>Email :</h4>
                <p>{dataUser.user_email}</p>

                <hr />
                <h4>Téléphone :</h4>
                {modif ? (
                    <input
                        id="input"
                        type="tel"
                        value={dataUser.user_phone}
                        onChange={(e) =>
                            setDataUser({ ...dataUser, user_phone: e.target.value })
                        }
                    />
                ) : (
                    <p>{dataUser.user_phone}</p>
                )}
                <hr />
                {/*<h4>Mot de passe :</h4>*/}
                {modif ? (
                    <>
                      <p>Nouveau mot de passe </p>
                      <input
                          id="input"
                          type="password"
                          className={validPW ? "valid" : "invalid"}
                          value={dataUser.user_password}
                          onChange={(e) =>
                              setDataUser({ ...dataUser, user_password: e.target.value })
                          }
                      />

                    </>
                ) : (
                    ""
                )}
                <div className='buttonValidate'>
                  {modif ? (
                      <button
                          className="Profile-button-2" onClick={() => {
                        validprof()
                        updateMyProfile()
                      }}>
                        Valider mon profil
                      </button>
                  ) : (
                      <button className="Profile-button-2" onClick={(e) => modifprof(e)}>
                        Modifier mon profil
                      </button>
                  )}
                </div>
                <div className="buttonValidate">
                  <button className="Profile-button"  onClick={logout} >
                    Déconnexion
                  </button>
                </div>
                <hr />
              </div>

              <div className="Profile-annonces">
                <h4>Mes annonces </h4>

                {riderAnnonce.length !== 0 ? (
                    riderAnnonce.map((e,) => (
                        <ResultCard
                            key={e.rider_ID}
                            fullResult={e}
                            firstname={e.rider_firstname}
                            rider_ID={e.rider_ID}
                            photo={e.rider_photos}
                            statusFavorite={false}
                        />
                    ))
                ) : (
                    <p>Vous n'avez pas encore publié d'annonce Cavalier</p>
                )}

                <hr />

                {horseAnnonce.length !== 0 ? (
                    horseAnnonce.map((e) => (
                        <HorseResultCard
                            key={e.horse_ID}
                            fullResult={e}
                            horse_name={e.horse_name}
                            horse_ID={e.horse_ID}
                            photo={e.horse_photos}
                            statusFavorite={false}
                        />
                    ))
                ) : (
                    <p>Vous n'avez pas encore publié d'annonce Equidé</p>
                )}

              </div>
            </div>
        }
        <Menu />
      </>
  );
};

export default MyProfile;
