import React, { useState, useContext } from 'react';
import { ContractContext } from "../context/ContractContext";
import { useLocation, useHistory } from 'react-router-dom';
import "./Contract.css";
import Axios from "axios";
import Header from "../Header_footer/Header";
import SignatureCanvas from 'react-signature-canvas'
import ModalError from "../common/ModalError";

const { REACT_APP_API_URL } = process.env;

const ContractSignature = () => {

    const location = useLocation();
    const history = useHistory();
    const { mails } = location.state;
    const { contractState } = useContext(ContractContext);
    const token = localStorage.token;

    const [date, setDate] = useState("");
    const [city, setCity] = useState("");
    const [signature, setSignature] = useState(undefined);

    const [modalMessage, setModalMessage] = useState("");

    const send = () => {
        var datas = new FormData();
        datas.append('contract', JSON.stringify(contractState));
        datas.append('mails', mails);
        datas.append('date', date);
        datas.append('city', city);

        var signatureCanvas = signature.getCanvas();
        signatureCanvas.toBlob((blob) => {
            datas.append('signature', blob, 'signature.png');
            Axios
            .post(`${REACT_APP_API_URL}/api/contract/sendMails`, datas, {headers : { 'Authorization' : token, 'content-type': 'multipart/form-data' }})
            .then(res => {
                setModalMessage(res.data.message);
                setTimeout(() => history.push('/home'), 4000);
            })
            .catch(res => {
                setModalMessage(res.response.data.error);
                setTimeout(() => setModalMessage(""), 4000);
            });
        })
    };


    return(
        <>
            <Header className="header" title="VOTRE CONTRAT" link='/contract-list' />
            <ModalError show={modalMessage ? true : false} onHide={e => setModalMessage("")} message={modalMessage} />
            <div className='contract_page'>
                <p></p>
                <p>Fait à :</p>
                <input
                    className='classicInput2'
                    value={city}
                    onChange={evt => setCity(evt.currentTarget.value)}
                />

                <p>Le :</p>
                <div className="dateField">
                    <input
                        type="date"
                        value={date}
                        onChange={evt => setDate(evt.currentTarget.value)}
                    />
                </div>
                <p></p>

                <p>Signez avec le doigt :</p>
                <SignatureCanvas
                    canvasProps={{width: 500, height: 200, className: 'sigCanvas'}}
                    ref={ref => setSignature(ref)}
                />
                <button onClick={evt => signature.clear()}>Reset</button>

                <button className="greenButton" onClick={send}>Envoyer</button>

            </div>
        </>
    )
}

export default ContractSignature;