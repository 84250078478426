import React, { useState, useMemo } from "react";
import { Switch, Route } from "react-router-dom";
import Landing from "./components/Landing/Landing";
import Home from "./components/Home";
import Login from "./components/Landing/Login";
import CGV_page from "./components/CGV_page"
import Register from "./components/Landing/Register";
import ForgetPassword from "./components/Landing/ForgetPassword";
import SearchRider from "./components/searchRider/SearchRider";
import SearchHorse from "./components/searchHorse/SearchHorse";

import ContractPresentation from "./components/contract/ContractPresentation";
import ContractEngagement from "./components/contract/ContractEngagement";
import Contract from "./components/contract/Contract";
import ContractOwner from "./components/contract/ContractOwner";
import ContractRider from "./components/contract/ContractRider";
import ContractHorseInfo from "./components/contract/ContractHorseInfo";
import ContractDisc from "./components/contract/ContractDisc";
import ContractInstitution from "./components/contract/ContractInstitution";
import ContractVisitFrequency from "./components/contract/ContractVisitFrequency";
import ContractVisitDays from "./components/contract/ContractVisitDays"
import ContractCommunicationDay from "./components/contract/ContractCommunicationDay"
import ContractInsurance from "./components/contract/ContractInsurance"
import ContractWarrantyExtension from "./components/contract/ContractWarrantyExtension"
import ContractDisuse from "./components/contract/ContractDisuse"
import ContractPeriod from "./components/contract/ContractPeriod"
import ContractPayment from "./components/contract/ContractPayment"
import ContractMail from "./components/contract/ContractMail"
import ListOfContracts from "./components/contract/ListOfContracts"
import ContractSignature from "./components/contract/ContractSignature"
import ContractGoodFather from "./components/contract/ContractGoodFather"

import Organise from "./components/Organise/Organise"
import CalendarPage from "./components/Organise/CalendarPage"
import CalendarDate from "./components/Organise/CalendarDate/CalendarDate"

import PostHorse from "./components/postHorse/PostHorse";
import PostRider from "./components/postRider/PostRider";
import PostRiderPresentation from "./components/postRider/PostRiderPresentation";
import OwnerPres from "./components/postHorse/OwnerPres";
import ResultPage from "./components/Rider_Results/ResultPage";
import ResultAnnonce from "./components/Rider_Results/ResultAnnonce";
import MyProfile from "./components/MyProfile/MyProfile";
import HorseResultAnnonce from "./components/Horse_Results/HorseResultAnnonce";
import HorseResultPage from "./components/Horse_Results/HorseResultPage";
import Favorites from "./components/MyProfile/Favorites";
import { RiderContext } from "./components/context/RiderContext";
import { riderProfileContext } from "./components/context/RiderContext";
import { HorseContext } from "./components/context/HorseContext";
import { horseProfileContext } from "./components/context/HorseContext";
import { SearchRiderContext } from "./components/context/SearchRiderContext";
import { searchRiderProfile } from "./components/context/SearchRiderContext"
import { SearchHorseContext } from './components/context/SearchHorseContext';
import { searchHorseProfile } from './components/context/SearchHorseContext';
import { Results_Rider_Context } from "./components/context/Results_Rider_Context";
import { Results_Horse_Context } from "./components/context/Results_Horse_Context";
import { UserContext } from "./components/context/UserContext";
import { userPositionContext } from "./components/context/UserContext";
import { contractStateContext } from "./components/context/ContractContext";
import { ContractContext } from "./components/context/ContractContext";
import Conversation from "./components/Messages/Conversation";
import ListConversations from "./components/Messages/ListConversations";
import UserProfile from "./components/MyProfile/UserProfile"

import CalculatePresentation from "./components/calculate/CalculatePresentation"
import CalculateRiderNbr from "./components/calculate/CalculateRiderNbr"
import CalculateRiderNames from "./components/calculate/CalculateRiderNames"
import CalculatePension from "./components/calculate/CalculatePension"
import CalculateMarechal from "./components/calculate/CalculateMarechal"
import CalculateVeterinaire from "./components/calculate/CalculateVeterinaire"
import CalculateOthers from "./components/calculate/CalculateOthers"
import CalculateResult from "./components/calculate/CalculateResult"
import CalculateMail from "./components/calculate/CalculateMail"



const Router = () => {
  //Appel des différents contexts qui vont partager ensuite l'information entre les composants concernés
  const [riderProfile, setRiderProfile] = useState(riderProfileContext);
  const [resultsRiders, setResultsRiders] = useState([]);
  const [searchRiders, setSearchRiders] = useState(searchRiderProfile)

  const [horseProfile, setHorseProfile] = useState(horseProfileContext);
  const [resultsHorses, setResultsHorses] = useState([]);
  const [searchHorses, setSearchHorses ] = useState(searchHorseProfile)

  const [userPosition, setUserPosition] = useState(userPositionContext);

  const [contractState, setContractState] = useState(contractStateContext);

  //Appel des différents providers de contexts qui vont ensuite distribuer l'information à travers le routeur
  const providerRiderProfile = useMemo( () => ({ riderProfile, setRiderProfile }), [riderProfile, setRiderProfile]);
  const providerResultsRiders = useMemo( () => ({ resultsRiders, setResultsRiders }), [resultsRiders, setResultsRiders]);
  const providerSearchRiders = useMemo( () => ({ searchRiders, setSearchRiders }), [searchRiders, setSearchRiders]);

  const providerHorseProfile = useMemo( () => ({ horseProfile, setHorseProfile }), [horseProfile, setHorseProfile]);
  const providerResultsHorses = useMemo( () => ({ resultsHorses, setResultsHorses }),[resultsHorses, setResultsHorses]);
  const providerSearchHorses = useMemo( () => ({ searchHorses, setSearchHorses}), [searchHorses, setSearchHorses])

  const providerUserPosition = useMemo(() => ({ userPosition, setUserPosition }), [userPosition, setUserPosition]);

  const providerContract = useMemo(() => ({ contractState, setContractState }), [contractState, setContractState]);
  

  return (
    <>
        <Switch>
          <Route exact path="/" component={Landing} />
          <Route exact path="/login" component={Login} />
          <Route exact path="/register" component={Register} />
          <Route exact path="/forget-password" component={ForgetPassword} />
          <Route exact path="/cgv-cavalcloud" component={CGV_page} />
          <Route exact path="/home" component={Home} />

          <Route exact path="/organise" component={Organise} />
          <Route exact path="/organise/:code" component={CalendarPage} />
          <Route exact path="/organise/:code/:date" component={CalendarDate} />
          
          <Route exact path="/my-profile" component={MyProfile} />
          <Route exact path="/favorites" component={Favorites} />
          <Route exact path="/listconversations" component={ListConversations} /> 
          <Route exact path="/conversation/:recipientId" component={Conversation} /> 
          <Route exact path="/user/:userId" component={UserProfile} />
          
          <Route exact path="/calculate" component={CalculatePresentation} />
          <Route exact path="/calculate/rider-number" component={CalculateRiderNbr} />
          <Route exact path="/calculate/rider-names" component={CalculateRiderNames} />
          <Route exact path="/calculate/pension" component={CalculatePension} />
          <Route exact path="/calculate/marechal" component={CalculateMarechal} />
          <Route exact path="/calculate/veterinaire" component={CalculateVeterinaire} />
          <Route exact path="/calculate/others" component={CalculateOthers} />
          <Route exact path="/calculate/result" component={CalculateResult} />
          <Route exact path="/calculate/mail" component={CalculateMail} />

          <UserContext.Provider value={providerUserPosition}>
            <ContractContext.Provider value={providerContract}>
              <Route exact path="/write-contract" component={ContractPresentation} />
              <Route exact path="/contract-engagement" component={ContractEngagement} />
              <Route exact path="/contract" component={Contract} />
              <Route exact path="/contract-owner" component={ContractOwner} />
              <Route exact path="/contract-rider" component={ContractRider} />
              <Route exact path="/contract-horse" component={ContractHorseInfo} />
              <Route exact path="/contract-institution" component={ContractInstitution} />
              <Route exact path="/contract-disc" component={ContractDisc} />
              <Route exact path="/contract-visit-frequency" component={ContractVisitFrequency} />
              <Route exact path="/contract-visit-days" component={ContractVisitDays} />
              <Route exact path="/contract-communication-day" component={ContractCommunicationDay} />
              <Route exact path="/contract-insurance" component={ContractInsurance} />
              <Route exact path="/contract-warranty-extension" component={ContractWarrantyExtension} />
              <Route exact path="/contract-disuse" component={ContractDisuse} />
              <Route exact path="/contract-period" component={ContractPeriod} />
              <Route exact path="/contract-payment" component={ContractPayment} />
              <Route exact path="/contract-list" component={ListOfContracts} />
              <Route exact path="/contract-mail" component={ContractMail} />
              <Route exact path="/contract-signature" component={ContractSignature} />
              <Route exact path="/contract-good-father" component={ContractGoodFather} />
              <SearchRiderContext.Provider value={providerSearchRiders}>
                <Route exact path="/search-rider" component={SearchRider} />
                <SearchHorseContext.Provider value={providerSearchHorses}>
                  <Route exact path="/search-horse" component={SearchHorse} />
                  <Results_Rider_Context.Provider value={providerResultsRiders}>
                    <Route exact path="/rider/results" component={ResultPage} />
                    <Route exact path="/rider/result-annonce/:id" component={ResultAnnonce} />
                    <Results_Horse_Context.Provider value={providerResultsHorses}>
                      <Route exact path="/horse/results" component={HorseResultPage}/>
                      <Route exact path="/horse/result-annonce/:id" component={HorseResultAnnonce}/>
                      <HorseContext.Provider value={providerHorseProfile}>
                        <Route exact path="/post-horse" component={PostHorse} />
                        <Route exact path="/post-horse-owner" component={OwnerPres} />
                        <RiderContext.Provider value={providerRiderProfile}>
                          <Route exact path="/post-rider" component={PostRider} />
                          <Route exact path="/PostRiderPresentation" component={PostRiderPresentation}/>
                        </RiderContext.Provider>
                      </HorseContext.Provider>
                  </Results_Horse_Context.Provider>
                </Results_Rider_Context.Provider>
                </SearchHorseContext.Provider>
              </SearchRiderContext.Provider>
            </ContractContext.Provider>
          </UserContext.Provider>
      </Switch>
    </>
  );
};

export default Router;
