import React from "react";
import "./radioCheck.css";

const RadioCheck = (props) => {
  return (
    <>
      <div className="radioCheck-list">
  
          <input
            type="checkbox"
            className="radioCheck"
            id={props.RadioCheckText}
            name={props.RadioCheckText}
            value={props.value}
            onClick={props.onClick}
          />
          <label htmlFor={props.RadioCheckText}>{props.RadioCheckText} </label>
      </div>
    </>
  );
};

export default RadioCheck;
