import { createContext } from 'react'

export const riderProfileContext = {

    rider_firstname: '',
    rider_avatar: '',
    rider_photo1: '',
    rider_photo2: '',
    rider_photo3: '',
    rider_age: null,
    rider_postal_code: '',
    rider_localisation : '',
    rider_long : null,
    rider_lat : null,
    rider_biography: '',
    rider_selfWord1: '',
    rider_selfWord2: '',
    rider_selfWord3: '',
    rider_ridingWord1: '',
    rider_ridingWord2: '',
    rider_ridingWord3: '',
    rider_riding_frequency: '',
    rider_fixed_day: false,
    rider_budget: null,
    rider_currency_budget: '',
    rider_own_saddle: false,
    rider_disciplines: {
        Obstacle : null,
        Dressage : null,
        CCE : null,
        TREC_Equifun : null,
        Balade_Randonnee : false,
        Ethologie_Equifeel : false,
        Hunter : false,
        Horse_Ball : false,
        Pony_Games : false,
        Reining_Western : false,
        Endurance : false,
        Attelage : false,
        Voltige : false,
        Disciplines_culturelles : false
    },
    rider_agree_other_discipline: false,
    rider_years_of_practice: null,
    rider_gallop_level: null,
    rider_vehiculed: false,
    rider_managed_horse: false,
    rider_competition: '',
    rider_coaching_here: false,
    rider_external_coach: false,
    ideal_horse_size: null,
    ideal_horse_temper: '',
    ideal_horse_caracter: '',
    ideal_horse_body_type: '',
    ideal_horse_age: null,
    user_ID : null,

}

export const RiderContext = createContext(null)
