import React, { useEffect } from "react";
import "./Header.css";
import { Link } from "react-router-dom";
import { HashLink } from 'react-router-hash-link';

const header = (props) => {

  const path = () => {
    if (props.path) {
      return `/${props.path}`;
    }
    return props.link;
  }

  const svg = (
    <svg
      className="bi bi-chevron-left "
      id="chevron-header"
      width="1em"
      height="1em"
      viewBox="0 0 16 16"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z"
      />
    </svg>
  )

  return (
    <>
      <div className="main-head">
        <div className="header">
          {props.hash ? (
            <HashLink to={props.hash} style={{ textDecoration: "none" }}>
              {svg}
            </HashLink>
          ) : (
            <Link onClick={props.onClick} to={{ pathname: path(), state: props.state }}>
              {svg}
            </Link>
          )}
        </div>
        <div>
          <h3 id="header">{props.title}</h3>
        </div>
      </div>
    </>
  );
};

header.defaultProps = {
  link: '/home'
}

export default header;
