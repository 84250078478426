import React from 'react';
import SelectButton2 from '../common/SelectButton2';

const TrainingCategories = ({ categorie, setCategorie }) => (
    
    <>
        <SelectButton2
            radioSelBtnId="chevalBtn"
            radioSelBtnValue="Séance à cheval"
            radioSelBtnName="categoriesButtons"
            onChange={evt => setCategorie("Séance à cheval")}
            checked={categorie === "Séance à cheval"}
        />
        <SelectButton2
            radioSelBtnId="piedBtn"
            radioSelBtnValue="Séance à pied"
            radioSelBtnName="categoriesButtons"
            onChange={evt => setCategorie("Séance à pied")}
            checked={categorie === "Séance à pied"}
        />
        <SelectButton2
            radioSelBtnId="marechalBtn"
            radioSelBtnValue="Maréchal"
            radioSelBtnName="categoriesButtons"
            onChange={evt => setCategorie("Maréchal")}
            checked={categorie === "Maréchal"}
        />
        <SelectButton2
            radioSelBtnId="osteoBtn"
            radioSelBtnValue="Ostéo"
            radioSelBtnName="categoriesButtons"
            onChange={evt => setCategorie("Ostéo")}
            checked={categorie === "Ostéo"}
        />
        <SelectButton2
            radioSelBtnId="vetoBtn"
            radioSelBtnValue="Véto"
            radioSelBtnName="categoriesButtons"
            onChange={evt => setCategorie("Véto")}
            checked={categorie === "Véto"}
        />
        <SelectButton2
            radioSelBtnId="autreBtn"
            radioSelBtnValue="Autres"
            radioSelBtnName="categoriesButtons"
            onChange={evt => setCategorie("Autres")}
            checked={categorie === "Autres"}
        />
    </>
    
);

export default TrainingCategories;