import React, { useState, useContext, useEffect } from 'react';
import { HashLink } from 'react-router-hash-link';
import { ContractContext } from "../context/ContractContext";
import "./Contract.css";
import Header from "../Header_footer/Header";
import SlidingButton from '../common/SlidingButton'
import FloatingButton from '../common/FloatingButton'
import RadioButton from '../common/RadioButton'

const ContractPayment = () => {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const { contractState, setContractState } = useContext(ContractContext);
    const payment = contractState.payment;

    const [price, setPrice] = useState(payment.price);
    const [date, setDate] = useState(payment.date);

    const [virement, setVirement] = useState(payment.wayOfPayment === "virement");
    const [cheque, setCheque] = useState(payment.wayOfPayment === "chèque");
    const [espece, setEspece] = useState(payment.wayOfPayment === "espèce");

    const [conserveCharge, setConserveCharge] = useState(payment.conserveCharge);

    const [veterinaireCost, setVeterinaireCost] = useState(payment.veterinaireCost);
    const [marechalCost, setMarechalCost] = useState(payment.marechalCost);
    const [osteopathieCost, setOsteopathieCost] = useState(payment.osteopathieCost);
    const [dentisteCost, setDentisteCost] = useState(payment.dentisteCost);


    const updateContract = () => {
        var wayOfPayment = "virement";
        if (cheque) {
            wayOfPayment = "chèque";
        } else if (espece) {
            wayOfPayment = "espèce";
        }

        const newPayment = {
            price,
            date,
            wayOfPayment,
            conserveCharge,
            veterinaireCost,
            marechalCost,
            osteopathieCost,
            dentisteCost,
        };
        
        setContractState({ ...contractState, payment: newPayment });
    };

    const changeReglement = (evt) => {
        const buttonId = evt.currentTarget.id;

        if (buttonId === "reglement1") {
            setVirement(true);
            setCheque(false);
            setEspece(false);
        } else if (buttonId === "reglement2") {
            setVirement(false);
            setCheque(true);
            setEspece(false);
        } else if (buttonId === "reglement3") {
            setVirement(false);
            setCheque(false);
            setEspece(true);
        }
    };

    
    return(
        <>
            <Header className="header" title="BUDGET MENSUEL" hash='/contract#payment' />
            <div className='contract_page'>
                <h4>Tarif mensuel :</h4>
                <div className="eurosField2">
                    <label htmlFor="eurosInput">€</label>
                    <input 
                        type="number"
                        id="eurosInput"
                        value={price}
                        onChange={evt => setPrice(evt.currentTarget.value)}
                    />
                </div>
                <h4>Date de règlement :</h4>
                <div className="paymentDateField">
                    <label htmlFor="paymentDateInput">Avant le :</label>
                    <input 
                        type="number"
                        id="paymentDateInput"
                        value={date}
                        onChange={evt => setDate(evt.currentTarget.value)}
                    />
                    <label htmlFor="paymentDateInput">du mois</label>
                </div>
                <h4>Mode de règlement :</h4>
                <RadioButton
                    radioButtonText="Virement"
                    radioButtonName="reglementModeButtons"
                    radioButtonId="reglement1"
                    onClick={changeReglement}
                    checked={virement}
                />
                <RadioButton
                    radioButtonText="Chèque"
                    radioButtonName="reglementModeButtons"
                    radioButtonId="reglement2"
                    onClick={changeReglement}
                    checked={cheque}
                />
                <RadioButton
                    radioButtonText="Espèce"
                    radioButtonName="reglementModeButtons"
                    radioButtonId="reglement3"
                    onClick={changeReglement}
                    checked={espece}
                />

                <SlidingButton 
                    SlidingButtonText="Le Propriétaire conserve la charge des frais vétérinaires courant, des frais de maréchal-ferrant et de dentiste."
                    SlidingButtonID="conserveChargeButton"
                    checked={conserveCharge}
                    onChange={(evt) => {setConserveCharge(!conserveCharge)}}
                />

                {!conserveCharge && (
                    <>
                    <h4>Le cavalier prend à sa charge des frais de vétérinaires courant à hauteur de :</h4>
                    <div className="eurosField2">
                        <label htmlFor="veterinaire_input">%</label>
                        <input 
                            type="number"
                            id="veterinaire_input"
                            value={veterinaireCost}
                            onChange={evt => setVeterinaireCost(evt.currentTarget.value)}
                        />
                    </div>
                    <h4>Le cavalier prend à sa charge des frais de maréchal-ferrant à hauteur de :</h4>
                    <div className="eurosField2">
                        <label htmlFor="marechal_input">%</label>
                        <input 
                            type="number"
                            id="marechal_input"
                            value={marechalCost}
                            onChange={evt => setMarechalCost(evt.currentTarget.value)}
                        />
                    </div>
                    <h4>Le cavalier prend à sa charge des frais d’ostéopathie à hauteur de :</h4>
                    <div className="eurosField2">
                        <label htmlFor="osteo_input">%</label>
                        <input 
                            type="number"
                            id="osteo_input"
                            value={osteopathieCost}
                            onChange={evt => setOsteopathieCost(evt.currentTarget.value)}
                        />
                    </div>
                    <h4>Le cavalier prend à sa charge des frais de dentiste à hauteur de :</h4>
                    <div className="eurosField2">
                        <label htmlFor="dentiste_input">%</label>
                        <input 
                            type="number"
                            id="dentiste_input"
                            value={dentisteCost}
                            onChange={evt => setDentisteCost(evt.currentTarget.value)}
                        />
                    </div>
                    </>
                )}
                
                <HashLink to="/contract#payment" style={{ textDecoration: "none" }}>
                    <FloatingButton btnName='Valider' onClick={updateContract}/>
                </HashLink>
            </div>
        </>
    )
}

export default ContractPayment;