import React, { useState, useEffect, useRef } from 'react'
import { Form, Button, Container, Image, InputGroup} from 'react-bootstrap'
import Header from '../Header_footer/Header'
import Axios from "axios"
import { useHistory, Link, useLocation } from "react-router-dom";
import io from 'socket.io-client'
import './myMessages.css'
import { faPaperPlane } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

// Client socketio
let socket

const { REACT_APP_API_URL } = process.env;


const Conversation = (props) => {

    // return to list of all conversations of the current user
    let history = useHistory();
    // User authentification before get conversation
    const token = localStorage.token
    // Identification of the good recipient in order to create private chat conversation
    const recipientId = Number(props.match.params.recipientId)
    // The private message to send to the recipient
    const [message, setMessage] = useState({
        recipientId: recipientId,
        text : '',
     })

    const [msg, setMsg] = useState(false)

    const [ email, setEmail ] = useState('')
    // The historic of conversation :
    const [allMessages, setAllMessages] = useState([{}])

    // The instant messages with socketio if both two users are connected in the same time :
    const [instantMessages, setInstantMessages] = useState([])

    // The info from current user :
    const [myDataUser, setMyDataUser] = useState(
        {
        user_ID : '',
        user_avatar: '',
        user_email: '',
        user_firstname: '',
        user_lastname : '',
        user_phone: ''
        })

    // The info from recipient user :
    const [userInTouch, setUserInTouch] = useState({
        user_ID : '',
        user_firstname: '',
        user_avatar : ''})

    const [hasFinishedToGetInfo, setHasFinishedToGetInfo] = useState(false)

    // Tranform createdAt key from DB to Date format
    const formatter = new Intl.DateTimeFormat("fr-GB", {
          year: "numeric",
          month: "long",
          day: "2-digit",
          hour : "numeric",
          minute : "numeric"
        });

    const options = {
        year: "numeric",
        month: "long",
        day: "2-digit",
        hour : "numeric",
        minute : "numeric"
    };

    const [sendMessageVerif, setSendMessageVerif] = useState(false)

    // Display 'is typing' to the recipient client side when the current user is typing a message
    const [isTyping, setIsTyping] = useState(false)


    //useRefs

    const myDataUserRef = useRef();
    const sendMessageVerifRef = useRef();
    const emailRef = useRef();


    // Get historic conversation from database between the current user and the recipient user
    const getAllMessages =  () => {
        Axios.get(`${REACT_APP_API_URL}/api/users/my-messages/show/`, { params :{recipientId : recipientId},
            headers : { 'Authorization' : 'Bearer ' + token} })
        .then((res) => setAllMessages(res.data))
        .catch((err) => console.log(err))
    }

    // Get the current user profile in order to get his avatar and his firstname before starting conversation
     const getMyProfile = async () => {
        await Axios.get(`${REACT_APP_API_URL}/api/users/profile`, {
            headers : { 'Authorization' : 'Bearer ' + token} })
        .then((res) => {
           setMyDataUser(res.data)
        })
        .finally(setHasFinishedToGetInfo(true))
        .catch((error)=> console.log(error))
    }

  // Get info from the user recipient in order to load his avatar and firstname before starting conversation
    const getUserInTouch = () => {
        Axios.get(`${REACT_APP_API_URL}/api/users/${recipientId}`, {
            headers : { 'Authorization' : 'Bearer ' + token} })
        .then((res) => setUserInTouch(res.data))
        .catch((error)=> console.log(error))
    }

    // Send message from the current user to the recipient user
    const sendMessage = (e) => {
        e.preventDefault()
        if(message) {

            setSendMessageVerif(true)

            // initialisation of a private socket room in order to have a private conversation
            socket.emit('joinConversation', myDataUser.user_ID)

            // Envoi du msg au serveur socket et réinitialisation de la valeur du message côté client
            socket.emit('sendPrivateMessage', message)
            setMessage({...message, text : ''})
            Axios
                .post(`${REACT_APP_API_URL}/api/users/my-messages`, [message.text, recipientId], {
                    headers : { 'Authorization' : 'Bearer ' + token} })
                .catch(err => console.log(err))
        }
    }

    // Update unread messages status to read messages status
    const updateToMessageIsRead = () => {
        Axios
        .put(`${REACT_APP_API_URL}/api/users/my-messages`, {recipientId : recipientId},
            { headers : { 'Authorization' : 'Bearer ' + token} })
        .catch(err=> console.error(err))
    }

    // If exists, get the instant messages from socketio (server-side) and display them on client-side
    const getInstantMessage = (message) => {
        setInstantMessages(oldMessages => [...oldMessages, message])
    }

    // Display 'is typing' to the recipient client side when the current user is typing a message
    const userIsTyping = () => {
        setIsTyping(!isTyping)
    }

    useEffect( () => {
        // get data from users and get historic of conversation
        getMyProfile()

        getAllMessages()
        getUserInTouch()
        updateToMessageIsRead()
        Axios
            .get(`${REACT_APP_API_URL}/api/users/${recipientId}/`, { params :{recipientId : recipientId},
            headers : { 'Authorization' : 'Bearer ' + token} })
            .then((res) => {
                setEmail(res.data.user_email)

            })
            .catch((err) => console.log(err))
        // initialisation of connection between socket client and socket server
        socket = io(REACT_APP_API_URL);

        // // catch instant private message
        socket.on('new-message', (newMessage) => {
            getInstantMessage(newMessage)
            setIsTyping(false)
        });

        socket.on('typing', () => {
            userIsTyping()
        });

        return () => {
            if (sendMessageVerifRef.current === true )

                Axios
                    .post(`${REACT_APP_API_URL}/api/users/send-mail-message`, [ myDataUserRef.current, emailRef.current ])
                    .then(res => {
                        console.log("email envoyé", res)
                    })
                    .catch((err) => {
                        console.log(err)
                    });

            socket.emit('disconnect');
            socket.off();
        };


    }, [] );


    useEffect(() => {
        myDataUserRef.current = myDataUser;

    }, [myDataUser])

    useEffect(() => {
        sendMessageVerifRef.current = sendMessageVerif;

    }, [sendMessageVerif])

    useEffect(() => {
        emailRef.current = email;

    }, [email])



    let formIsHalfFilledOut = true

    return (
<>

<div className='firstHeader'>
    <Header className="header" title="Conversation" path='listconversations' />
        <div className="usersHeader">
                    <button onClick={()=>history.goBack()} className="goAllConvers">
                        <h5>Retour</h5>
                    </button>
                    <div className='userPres'>
                        <Link to ={{pathname : `/user/${userInTouch.user_ID}`}}>
                            <Image src={userInTouch.user_avatar} roundedCircle className='userImg' />
                            <h3> {userInTouch.user_firstname} </h3>
                        </Link>
                    </div>
                    <div className='userPres'>
                        <Image src={myDataUser.user_avatar} roundedCircle className='userImg' />
                        <h3> {myDataUser.user_firstname} </h3>
                    </div>
            </div>
            </div>

    {hasFinishedToGetInfo ?

            <div className="d-flex flex-column flex-grow-1 msgConversation">
            {/* Afficher le message qui vient d'être envoyer par le user */}
                    <Form.Group className="m-1" >

                    {allMessages.map(e => e.senderId === myDataUser.user_ID ?
                        <div key={e.id}>
                            <p id='dateMsg'>{formatter.format(Date.parse(e.createdAt))}</p>
                            <div className='myUser'>
                                <h5> Moi : </h5>
                                <p>{e.text}</p>
                            </div>
                        </div>
                        :
                        <div key={Number(e.id)}>
                            <p id='dateMsg'>{new Date(e.createdAt).toLocaleDateString("fr-FR", options)}</p>
                            <div className='otherUser'>
                                <h5> {userInTouch.user_firstname} : </h5>
                                <p>{e.text}</p>
                            </div>
                        </div>
                    )
                        }
                    </Form.Group>

            <Container fluid="lg" className='sendBtn' >
                    {/* envoyer un message par user */}
                    <Form onSubmit={(e) => {
                                    sendMessage(e)
                                    setMessage({...message, text :''})}} >
                        <Form.Group className="m-6">
                            <InputGroup>
                                <Form.Control
                                as="textarea"
                                value={message.text}
                                onChange={(e) => {
                                    setMessage({...message, text : e.target.value})
                                    socket.emit('typing', message)
                                }}
                                style={{height: '50px', resize: 'none'}}
                                autoFocus
                                />
                                <InputGroup.Append >
                                    <Button type="submit" variant="dark" >
                                    <FontAwesomeIcon icon={faPaperPlane}/> </Button>
                                </InputGroup.Append>
                            </InputGroup>
                        </Form.Group>
                    </Form>
                </Container>
                {/* Afficher le message qui vient d'être envoyer par le user */}
                <Form.Group className="m-3">

                    {instantMessages ? instantMessages.map(message => (
                        <div>
                            {message.recipientId !== myDataUser.user_ID ?
                            <div >
                                <div className='myUser'>
                                    <h5>Moi : </h5>
                                    <p>{message.text}</p>
                                </div>
                            </div>
                            :
                            <div >
                                <div className='otherUser'>
                                    <h5>{userInTouch.user_firstname} :</h5>
                                    <p>{message.text}</p>
                                </div>
                            </div>
                                }


                        </div>
                        ))

                        : null}

               </Form.Group >
            {isTyping ?
                <p id='writingMsg'>{userInTouch.user_firstname} est en train d'écrire...</p>
            : null }


        </div>

    : <h3>Chargement en cours de la conversation...</h3> }


</>
    )
}

export default Conversation;
