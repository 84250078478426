import React from "react";
import SelectButton from "../common/SelectButton";
import './common_section.css'

const HebergementHorse = (props) => {
  return (
      <div className="hebergement">
        <h5>Type d'hébergement </h5>
        <div className="hebergtList">
          <SelectButton
              radioSelBtnId="Boxe"
              radioSelBtnValue="Boxe"
              radioSelBtnName="boxeType"
              onClick={props.onClick}
              checked={props.checked==='Boxe'}
          />
          <SelectButton
              radioSelBtnId="Paddock"
              radioSelBtnValue="Paddock"
              radioSelBtnName="boxeType"
              onClick={props.onClick}
              checked={props.checked==='Paddock'}
          />
          <SelectButton
              radioSelBtnId="Boxe + Paddock"
              radioSelBtnValue="Boxe paddock"
              radioSelBtnName="boxeType"
              onClick={props.onClick}
              checked={props.checked==='Boxe paddock'}
          />
          <SelectButton
              radioSelBtnId="Stabulation"
              radioSelBtnValue="Stabulation"
              radioSelBtnName="boxeType"
              onClick={props.onClick}
              checked={props.checked==='Stabulation'}
          />
          <SelectButton
              radioSelBtnId="Pâture"
              radioSelBtnValue="Pature"
              radioSelBtnName="boxeType"
              onClick={props.onClick}
              checked={props.checked==='Pature'}
          />
          <SelectButton
              radioSelBtnId="Autre "
              radioSelBtnValue="Autre"
              radioSelBtnName="boxeType"
              onClick={props.onClick}
              checked={props.checked==='Autre'}
          />

        </div>
      </div>
  );
};

export default HebergementHorse;
