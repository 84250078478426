import React from "react";
import { Modal } from "react-bootstrap";

function ModalError(props) {
  return (
    <Modal
      show={props.show}
      onHide={props.onHide}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          CavalCloud
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>
          {props.message}
        </p>
      </Modal.Body>
    </Modal>
  );
}

export default ModalError;
