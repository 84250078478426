import { createContext } from 'react'

export const searchHorseProfile = {
    horse_budget : null,
    horse_currency_budget : '',
    horse_disciplines : {
        Obstacle : false,
        Dressage : false,
        CCE : false,
        TREC_Equifun : false,
        Balade_Randonnee : false,
        Ethologie_Equifeel : false,
        Hunter : false,
        Horse_Ball : false,
        Pony_Games : false,
        Reining_Western : false,
        Endurance : false,
        Attelage : false,
        Voltige : false,
        Disciplines_culturelles : false
    },
    horse_practice_structure : {
        Carrière : false,
        Manège_couvert : false, 
        Rond_de_longe : false, 
        Piste_de_trotting : false, 
        Cross : false, 
        Chemins_de_balade: false, 
        Champs : false, 
    },
    horse_riding_frequency : '',
    horse_fixed_day : 0,
    horse_stroll_along : 0,
    horse_height : null,
    horse_age : null,
    horse_temper : {
        Calme : false, 
        Dynamique : false, 
        Speed : false, 
        A_canaliser : false,
    },
    horse_character : {
        Affectueux : false,
        Froid : false, 
        Joueur : false, 
        Sensible : false, 
    },
    horse_body_type : {
        Fin : false, 
        Classique : false, 
        Porteur : false, 
        Lourd : false, 
    },
    horse_location_type : {
        Poney_club: false, 
        Centre_équestre : false, 
        Ecurie_du_propriétaire : false, 
        Chez_un_particulier : false, 
        Elevage : false, 
        Autre : false, 
    },
    horse_accomodation : {
        Boxe : false, 
        Paddock : false, 
        Boxe_paddock : false, 
        Stabulation : false, 
        Pature : false, 
        Autre : false, 
    },
    horse_coaching_here : 0,
    horse_external_coach : 0,
    horse_material : '',
    horse_competition_preferences : '',
    owner_age : null,
    owner_character : {
        Introverti : false,
        Sociable: false,
        Extraverti: false,
        Solitaire: false
    },
    owner_communication_frequency : '',
    owner_horse_work : '',
}

export const SearchHorseContext = createContext(null)