import React, { useEffect, useState } from "react";
import Axios from 'axios';
import { Link } from 'react-router-dom';
import './Home.css'
import horse from './SVG-icons/horse-icon.svg'
import helmet from './SVG-icons/bombe-icon.svg' 
import boot from './SVG-icons/botte-icon.svg'
import horseFrame from './SVG-icons/horse-in-cadre-icon.svg'
import calculate from './SVG-icons/calculate-icon.svg'
import calendar from './SVG-icons/calendar.svg'
import contract from './SVG-icons/contract.svg'
import cavalCloudLogo from './SVG-icons/cavalcloud-logoTest.jpg'
import Menu from './Menu'

const { REACT_APP_API_URL } = process.env;

const Home = () => {

  const token = localStorage.token
  
  // Number of unread messages :
  const [unreadMsg, setUnreadMsg] = useState([])

  const checkUnreadMessages = () => {
    Axios
    .get(`${REACT_APP_API_URL}/api/users/my-messages/unread/count`, { 
        headers : { 'Authorization' : 'Bearer ' + token}
        })
    .then(res => setUnreadMsg(res.data))
    .catch(err => console.log(err))    
  }

  useEffect(() => {
    checkUnreadMessages()
  }, [])

      return (
        <>
          <div className="headerHome">
            <h3 id="homeTitle">Accueil</h3>{" "}
          </div>
          <div className="HomePage">
            <h3 className="welcome"> Bienvenue sur Cavalcloud ! </h3>

            <Link to="/search-horse" style={{ textDecoration: "none" }}>
              <div className="homeBtn">
                <img className="logoHorse" src={horse} alt="horse icon" />
                <p className="textBtn"> Chercher un équidé </p>
              </div>
            </Link>

            <Link to="/search-rider" style={{ textDecoration: "none" }}>
              <div className="homeBtn">
                <img className="logoHelmet" src={helmet} alt="helmet icon" />
                <p className="textBtn"> Chercher un cavalier </p>
              </div>
            </Link>

            <Link to="/post-rider" style={{ textDecoration: "none" }}>
              <div className="homeBtn">
                <img className="logoboot" src={boot} alt="boot icon" />
                <p className="textBtn"> Poster une annonce cavalier </p>
              </div>
            </Link>

            <Link to="/post-horse" style={{ textDecoration: "none" }}>
              <div className="homeBtn">
                <img
                  className="logoHorseframe"
                  src={horseFrame}
                  alt="horse frame icon"
                />
                <p className="textBtn"> Poster une annonce cheval </p>
              </div>
            </Link>

            <Link to="/write-contract" style={{ textDecoration: "none" }}>
              <div className="homeBtn">
                <img
                  className="logoHorseframe"
                  src={contract}
                  alt="contract icon"
                />
                <p className="textBtn"> Rédiger un contrat </p>
              </div>
            </Link>

            <Link to="/organise" style={{ textDecoration: "none" }}>
              <div className="homeBtn">
                <img
                  className="logoHorseframe"
                  src={calendar}
                  alt="calendar icon"
                />
                <p className="textBtn"> Organiser </p>
              </div>
            </Link>
            
            <Link to="/calculate" style={{ textDecoration: "none" }}>
              <div className="homeBtn">
                <img
                  className="logoCalculateframe"
                  src={calculate}
                  alt="calculate frame icon"
                />
                <p className="textBtn"> Mieux partager vos frais </p>
              </div>
            </Link>
              
            <Link to="/cgv-cavalcloud" className="AboutUSBtn" style={{ textDecoration: "none" }}>
              <img
                className="logoCaval"
                src={cavalCloudLogo}
                alt="caval cloud logo"
              />
              <p className="textUs"> Plus d’infos sur Cavalcloud - CGU – CGV </p>
            </Link>
            

            <Menu countMessage={unreadMsg.length} />
          </div>
        </>
      );
}

export default Home ;
