import React, { useState, useContext } from 'react';
import { HashLink } from 'react-router-hash-link';
import { ContractContext } from "../context/ContractContext";
import "./Contract.css";
import Header from "../Header_footer/Header";
import CustomInput from '../common/CustomInput'
import FloatingButton from '../common/FloatingButton'

const ContractHorseInfo = () => {

    const { contractState, setContractState } = useContext(ContractContext);
    const horse = contractState.horse;

    const [name, setName] = useState(horse.name);
    const [SIRE, setSIRE] = useState(horse.SIRE);

    const updateContract = () => {
        const newHorse = {
            name,
            SIRE,
        };
        setContractState({ ...contractState, horse: newHorse });
    };

    return(
        <>
            <Header className="header" title="INFORMATIONS SUR LE CHEVAL" hash='/contract#horse' />
            <div className='contract_page'>
                <h4>Nom du cheval :</h4>
                <CustomInput value={name} onChange={(evt) => {setName(evt.currentTarget.value)}}/>
                <h4>Numéro SIRE :</h4>
                <CustomInput value={SIRE} onChange={(evt) => {setSIRE(evt.currentTarget.value)}}/>
                <HashLink to="/contract#horse" style={{ textDecoration: "none" }}>
                    <FloatingButton btnName='Valider' onClick={updateContract}/>
                </HashLink>
            </div>
        </>
    )
}

export default ContractHorseInfo;