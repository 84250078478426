import React, { useState } from 'react';
import "./organise.css";
import CreateCalendar from "./CreateCalendar";
import CalendarCreated from "./CalendarCreated";

const NewCalendar = () => {


    const [codeOfCalendarCreated, setCodeOfCalendarCreated] = useState("");


    return(
      <>

        {codeOfCalendarCreated ? <CalendarCreated code={codeOfCalendarCreated}/> : <CreateCalendar setCodeOfCalendar={setCodeOfCalendarCreated}/>}
        
      </>
    );
};


export default NewCalendar;