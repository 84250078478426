import React from 'react';
import { useHistory } from 'react-router-dom';
import "./Contract.css";
import Header from "../Header_footer/Header";
import cheval from '../SVG-icons/cheval-content.png'

const ListOfContracts = () => {

    const history = useHistory();

    const sendMail = (evt) => {
        history.push({
            pathname: '/contract-mail',
            state: { signature: false }
        });
    };

    const signMail = (evt) => {
        history.push({
            pathname: '/contract-mail',
            state: { signature: true }
        });
    };


    return(
        <>
            <Header className="header" title="VOTRE CONTRAT" link='/contract' />

            <div className='contract_page'>
                <img id="horseImg" src={cheval} alt="cheval-content"/>
                <h4>Votre contrat est rédigé !</h4>
                <hr/>
                <p>CONTRAT DE PRÊT A USAGE D'UN EQUIDE</p>

                <button className="greenButton" id="button1" onClick={signMail}> 
                    Générer et e-signer
                </button>
                <p></p>
                <button className="whiteButton" id="button2" onClick={sendMail}> 
                    Envoyer par mail
                </button>
            </div>

        </>
    );
};


export default ListOfContracts;