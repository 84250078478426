import React from 'react';
import "./organise.css";
import { useHistory } from 'react-router-dom';


const CalendarCreated = ({ code }) => {

    const history = useHistory();

    return(
        <>
            <h5>Le calendrier a été créé !</h5>
            <div className="greenFrame">{code}</div>
            <p>Tapez pour copier ce code</p>
            <p>Donnez ce code à vos co-cavaliers pour commencer votre collaboration sur CavalCloud.</p>
            <p>Ce code restera le même tant que vous ne changez pas de monture. Donnez-le chaque fois qu'un nouveau cavalier vous rejoint.</p>
            <button className="greenButton" onClick={evt => history.push("/organise/"+code)}>
                Démarrer le calendrier
            </button>
        </>
    );
};


export default CalendarCreated;