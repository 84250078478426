import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
import "./Contract.css";
import Header from "../Header_footer/Header";
import Checkbox from "../common/Checkbox";
import FloatingButton from "../common/FloatingButton";

const ContractEngagement = () => {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const history = useHistory();

    const [button1, setButton1] = useState(false);
    const [button2, setButton2] = useState(false);

    return(
        <>
            <Header className="header" title="Rédiger un contrat" />
            <div className='contract_page'>
                <h4 className='center'>Rédiger, partager et signer un contrat</h4>
                <p>Le terme exact est le "contrat de prêt à usage d'un équidé" pour une utilisation partielle.<br/>
                    Dans chacun des sous-menus, vous trouverez des champs à remplir et des clauses pérédigées.<br/>
                    Remplissez ou complétez uniquement les clauses que vous désirez voir apparaître sur votre contrat.
                    Si vous ne souhaitez pas qu'une clause figure dans le contrat, vous êtes libres de ne pas la compléter. Votre contrat aura tout autant de valeur.<br/>
                    Les signataires s'engagent sur les clauses qu'ils veulent et ne s'engagent pas sur les clauses non-remplies (parfois une clause ne s'applique pas ou ne semble pas utile).<br/>
                    La base du contrat que CavalCloud génère pour vous constitue une pièce importante en cas de conflit entre les parties. Il s'agit avant tout d'un moyen simple :<br/>
                    - D'instaurer un dialogue entre les parties quant au bien-être et au travail de l'équidé<br/>
                    - De mettre sur papier les accords trouvés entre les parties<br/>
                    - De résoudre un litige à l'amiable sur simple relecture en cas de désaccord<br/>
                    Faire un contrat de demi-pension, que vous soyez propriétaire ou non, n'est pas une obligation au regard de la loi. C'est fortement conseillé.<br/>
                    Liens des articles concernés : https://equipedia.ifce.fr/economie-et-filiere/reglementation/contrats/contrat-de-pret-a-usage-d-un-equide.html<br/>
                    La société CavalCloud se dégage de toute responsabilité quant aux désaccords qui pourraient interférer entre les différentes parties.
                </p>
                <Checkbox 
                    CheckboxText="En cochant cette cas je reconnais qu'aucune charge ne pourra être retenu contre CavalCloud en cas de litige entre les différents signataires d'un contrat."
                    onChange={evt => {setButton1(!button1)}}
                    checked={button1}/>
                <Checkbox 
                    CheckboxText="J'ai pris connaissance des informations mises par CavalCloud à la disposition de ses utilisateurs"
                    onChange={evt => {setButton2(!button2)}}
                    checked={button2}/>
                
                {!(button1 && button2) ? (
                    <FloatingButton btnName='Valider' inactive disabled />
                ) : (
                    <HashLink to="/contract#owner" style={{ textDecoration: "none" }}>
                        <FloatingButton btnName='Valider' />
                    </HashLink>
                )}
            </div>
        </>
    )
}


export default ContractEngagement;