import React from "react";
import "./dateTitle.css";
import calendar_icon from '../SVG-icons/calendar-icon.svg';


const DateTitle = ({ date }) => {

    const months = [
        "Janvier",
        "Février",
        "Mars",
        "Avril",
        "Mai",
        "Juin",
        "Juillet",
        "Août",
        "Septembre",
        "Octobre",
        "Novembre",
        "Décembre",
    ];

    const days = [
        "Dimanche",
        "Lundi",
        "Mardi",
        "Mercredi",
        "Jeudi",
        "Vendredi",
        "Samedi",
    ];

    const dateToString = (d) => {
        return days[d.getDay()] + " " + d.getDate() + " " + months[d.getMonth()] + " " + d.getFullYear();
    };

  return (
    <div className="date">
        <h4>{dateToString(date)}</h4>
        <img className="calendar_icon" src={calendar_icon} alt="calendar icon" />
    </div>
  );
}

export default DateTitle;
